import CosPrimaryNumberField from '../components/formikForms/ui/numberField/CosPrimaryNumberField';
import CosSecondaryNumberField from '../components/formikForms/ui/numberField/CosSecondaryNumberField';
import AddHoursField from '../components/time/AddHoursField';
import StandardLayout from './StandardLayout';

const StandardNumberField = () => {
  return (
    <StandardLayout>
      <div className="mt-2">
        <p>Popup number field (max: 24, min: 0)</p>
        <div className="ml-2">
          <CosPrimaryNumberField onChange={v => console.log('test', v)} />
        </div>

        <p>page number field</p>
        <div className="ml-2">
          <CosSecondaryNumberField onChange={v => console.log('test2', v)} />
        </div>

        <p>Hr(s)/Min(s)</p>
        <div className="ml-2">
          <AddHoursField />
        </div>
      </div>
    </StandardLayout>
  );
};

export default StandardNumberField;
