import React, {
  HTMLProps,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  InputBase,
  InputBaseProps,
  SxProps,
  Theme,
  alpha,
  styled,
} from '@mui/material';
import CosLabel, { CosLabelProps } from './CosLabel';
import clsx from 'clsx';
import { constant } from '../../../libs/constant';
import { useDebounceValue } from '../../../libs/hooks';
import { cn } from '../../../libs/utils';

export interface CosInputProps extends InputBaseProps {
  label?: string | ReactNode;
  name?: string;
  type?: 'text' | 'password' | 'number';
  error?: boolean;
  startIcon?: ReactNode | undefined;
  isRequired?: boolean;
  labelProps?: Omit<CosLabelProps, 'children'>;
  children?: ReactNode;
  startIconClass?: HTMLProps<HTMLElement>['className'];
  inputWrapperClass?: HTMLProps<HTMLElement>['className'];
  wrapperClassName?: HTMLProps<HTMLElement>['className'];
  inputSx?: SxProps<Theme>;
  tooltipTitle?: string;
  hideKeeperSuggestion?: boolean;
  debounceCallback?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  debounceTime?: number;
  withElevation?: boolean;
  disableFocusElevation?: boolean;
  isPopupInput?: boolean; // for popup input
}

const CustomInput = styled(InputBase, {
  shouldForwardProp: prop =>
    prop !== 'startIcon' &&
    prop !== 'inputSx' &&
    prop !== 'isFocused' &&
    prop !== 'withElevation' &&
    prop !== 'isPopupInput',
})<CosInputProps & { isFocused?: boolean }>(
  ({
    theme,
    inputSx,
    startIcon,
    withElevation = true,
    disableFocusElevation = true,
    isFocused,
    isPopupInput = false,
  }) => ({
    // *Small
    '& .MuiInputBase-input.MuiInputBase-inputSizeSmall': {
      height: constant.smallSizeInputHeight,
    },

    '& .MuiInputBase-input.Mui-disabled': {
      // opacity: '0.25',
      background: alpha('#ffffff', 0.25),
      WebkitTextFillColor: alpha('#001F38', 1) + ' !important',
    },
    // *Medium
    '& .MuiInputBase-input': {
      boxSizing: 'border-box',
      ...(withElevation
        ? {
            '&': {
              borderRadius: `3px`,
              boxShadow: '0 0 3px #A8B1B8',
              '&:focus': {
                boxShadow:
                  isFocused && !disableFocusElevation
                    ? '0px 0px 3px #07B9EC'
                    : '0 0 3px #A8B1B8',
              },
            },
          }
        : {}),
      minWidth: constant.smallSizeInputMinWidth,
      height: constant.mediumSizeInputHeight,
      position: 'relative',
      color: '#001F38',
      fontWeight: isPopupInput ? '600' : '400',
      borderRadius: '3px',
      backgroundColor: '#ffffff',
      fontSize: '14px',
      padding: startIcon
        ? `0 ${constant.inputXSpacing}px 0 43px`
        : `0 ${constant.inputXSpacing}px`,
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:focus': {
        '&::placeholder': {
          opacity: 0.75,
        },
      },
      '&:hover': {
        '&::placeholder': {
          opacity: 0.75,
        },
      },
      '&::placeholder': {
        color: '#001f38',
        fontWeight: '400',
        opacity: 1,
        transition: 'opacity 0.3s ease',
      },
      ...(inputSx && inputSx),
    },
  }),
);

const CosInput: React.FC<CosInputProps> = ({
  name,
  label,
  placeholder,
  error,
  startIcon,
  type = 'text',
  className,
  isRequired = true,
  labelProps,
  inputSx,
  children, //* For UI purposes
  inputWrapperClass,
  startIconClass,
  wrapperClassName,
  tooltipTitle,
  inputProps,
  hideKeeperSuggestion = true,
  onChange,
  debounceCallback,
  debounceTime = 500,
  fullWidth,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(true); // * Prevent focus on mouse click

  // * Start : Manage Debounce function
  const [event, setEvent] = useState<
    React.ChangeEvent<HTMLInputElement> | undefined
  >();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEvent(event);
    onChange && onChange(event);
  };

  const debouncedValue = !!debounceCallback
    ? useDebounceValue(event?.target.value, debounceTime)
    : '';

  const callBack = useCallback(async () => {
    if (event?.target.value) {
      debounceCallback && debounceCallback(event);
    }
  }, [debouncedValue]);

  useEffect(() => {
    callBack();
  }, [debouncedValue, callBack]); //* End : Manage Debounce function

  return (
    <div
      className={clsx(
        'mb-4 inline-block',
        {
          'w-full': fullWidth,
        },
        className,
      )}
    >
      {label && (
        <CosLabel
          isRequired={isRequired}
          {...labelProps}
          warperClassName={cn('mb-[5px]', wrapperClassName)}
          tooltipTitle={tooltipTitle}
        >
          {label}
        </CosLabel>
      )}
      <div className={clsx('relative', inputWrapperClass)}>
        {startIcon && (
          <div
            className={clsx(
              'absolute z-10 inline-flex h-full w-11 items-center justify-center',
              startIconClass,
            )}
          >
            {startIcon}
          </div>
        )}
        <CustomInput
          fullWidth
          inputSx={inputSx}
          startIcon={startIcon}
          type={type}
          placeholder={placeholder}
          error={error}
          id={name + ' user_field'}
          onChange={handleChange}
          name={name}
          className={clsx({
            inputWithIcon: startIcon,
          })}
          onMouseDownCapture={() => setIsFocused(false)}
          onBlurCapture={() => setIsFocused(true)}
          isFocused={isFocused}
          autoComplete="new-password" //* remove autofillF
          inputProps={{
            className: 'keeper-ignore',
            spellCheck: 'false',
            ...inputProps,
          }}
          {...rest}
        />
        {children && children}
      </div>
    </div>
  );
};

export default CosInput;
