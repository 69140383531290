import * as Yup from 'yup';

const useCreateSchedule = () => {
  const initialValues = {
    scheduleName: '',
    workingDay: [],
    startDay: '',
    startTime: '',
    workHours: '',
    breakTime: '',
    noOfDays: '',
    deployments: [],
  };

  const validationSchema = Yup.object().shape({
    scheduleName: Yup.string().required(''),
    workingDay: Yup.array().required(''),
    startDay: Yup.object().required(''),
    startTime: Yup.object().required(''),
    workHours: Yup.object().required(''),
    breakTime: Yup.object().required(''),
    noOfDays: Yup.string().required(''),
    deployments: Yup.array().required(''),
  });

  return {
    initialValues,
    validationSchema,
  };
};
export default useCreateSchedule;
