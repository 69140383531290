import { SxProps, TextField, Theme, alpha, styled } from '@mui/material';
import { constant } from '../../../../libs/constant';
import { memo } from 'react';

const CosAutoCompleteInput = styled(TextField, {
  shouldForwardProp: prop => prop !== 'inputSx',
})<{
  inputSx?: SxProps<Theme>;
}>(({ inputSx, size }) => {
  return {
    width: '100%',
    '& .COS-MultiValue': {
      color: alpha('#001f38', 1),
    },
    '&:hover': {
      '.MuiSvgIcon-root': {
        fill: '#07B9EC',
      },
      '.MuiInputBase-input, & .COS-MultiValue': {
        color: alpha('#001f38', 0.75),
      },
      '.cos-expand': {
        transition: 'transform 0.3s ease',
        transform: 'scale(1.15)',
      },
    },
    '& .MuiAutocomplete-input': {
      padding: '0 !important',
      backgroundColor: 'transparent',
      color: '#001F38',
      // fontWeight: '600',
      fontSize: '14px',
      lineHeight: '18px',
      cursor: 'pointer',
      pointerEvents: 'none', // * For open ans stay drop-down (we mange own open close)
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'unset !important',
      outline: 'unset !important',
    },

    '& .MuiInputBase-input:focus': {
      '&::placeholder': {
        opacity: 0.75,
      },
    },
    '& .MuiInputBase-input:hover': {
      '&::placeholder': {
        opacity: 0.75,
      },
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#001f38',
      fontWeight: '400',
      opacity: 1,
      transition: 'opacity 0.3s ease',
    },
    '& .MuiButtonBase-root': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiSvgIcon-root': {
      fill: '#001F38',
    },

    '& .MuiInputBase-root': {
      minWidth: constant.smallSizeInputMinWidth,
      paddingLeft: '9px !important',
      paddingRight: '30px !important',
      backgroundColor: '#ffffff',
      height: constant.mediumSizeInputHeight,
      cursor: 'pointer',
      borderRadius: 3,
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
      ...(inputSx && inputSx),
    },

    '& .MuiAutocomplete-endAdornment': {
      right: '2px !important',
    },

    //* small Size input
    '& .MuiInputBase-root.MuiInputBase-sizeSmall': {
      height: constant.smallSizeInputHeight,
    },
  };
});

export default memo(CosAutoCompleteInput);
