import { useState } from 'react';
import { Form, Formik } from 'formik';
import { ModalHeading } from '../../../libs/constant';
import { CosDataView, ModalActionButtons } from '../../../components/dialogBox';
import useCreateSchedule from '../hooks/useCreateSchedule';
import moment from 'moment';
import PreivewScheduleForm from './PreivewScheduleForm';
import CreateEditScheduleForm from './CreateEditScheduleForm';
import { MiddleButtonConfig } from '../../../libs/interface';

interface Props {
  onClose?: () => void;
  isOpen: boolean;
}
const CreateNewSchedule: React.FC<Props> = ({ isOpen, onClose }) => {
  const [isPreview, setPreview] = useState(false);
  const { initialValues, validationSchema } = useCreateSchedule();

  const updateDynamicScheduleName = (values: any, setFieldValue: any) => {
    let data: any[] = [];
    if (Array.isArray(values.workingDay)) {
      data.push(values.workingDay.map((item: any) => item.value.slice(0, 3)));
    }
    if (
      values.workingDay.length > 0 &&
      values.startTime.value &&
      values.workHours.value
    ) {
      let scheduleName = '';
      const startTime = moment(values.startTime.value, 'hh:mm A');
      const workHours = parseInt(values.workHours.value.split(' ')[0]);
      const endTime = startTime
        .clone()
        .add(workHours, 'hours')
        .format('hh:mm A');

      scheduleName =
        `${values.workingDay.length} Day Workweek, ` +
        `${data[0][0]} - ${data[0][data[0].length - 1]}, ` +
        `${values.startTime.value} to ${endTime}`;

      setFieldValue('noOfDays', `${values.workingDay.length}`);
      setFieldValue('scheduleName', scheduleName);
    }
  };
  const handlePreview = () => {
    setPreview(true);
  };

  const handleSubmit = () => {
    onClose?.();
    // Handle form submission logic here
  };

  const handleClose = () => {
    (dirty: boolean, resetForm: () => void) => {
      if (dirty) {
        resetForm();
      }
    };
    onClose?.();
  };

  const handleCancel = (resetForm: () => void) => {
    resetForm();
  };

  const handleClosePreview = () => {
    setPreview(false);
  };

  const middleButtons: MiddleButtonConfig[] = [
    { text: 'Preview', onAction: () => handlePreview() },
  ];
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {formik => {
          const { values, dirty, isValid, setFieldValue, resetForm } = formik;
          return (
            <Form>
              <CosDataView
                open={isOpen}
                heading={ModalHeading.createNewSchedule}
                isPagination={false}
                dialogAction={
                  isPreview ? (
                    <ModalActionButtons
                      onClose={() => handleClosePreview()}
                      onApply={handleClose}
                      applyText={'Create'}
                      closeText={'Close Preview'}
                    />
                  ) : (
                    <ModalActionButtons
                      onClose={handleClose}
                      applyText={'Create'}
                      middleButtons={middleButtons}
                      showCancel={dirty}
                      onCancel={() => handleCancel(resetForm)}
                      disableApply={!(dirty && isValid)}
                      onApply={() => handleSubmit()}
                    />
                  )
                }
              >
                <PreivewScheduleForm
                  previewData={values}
                  sx={{
                    opacity: isPreview ? 1 : 0,
                    pointerEvents:
                      (isPreview ? 'auto' : 'none') + ' !important',
                    position:
                      (isPreview ? 'relative' : 'absolute') + ' !important',
                    top: 0,
                  }}
                />

                <CreateEditScheduleForm
                  formikConfig={formik}
                  updateDynamicScheduleName={updateDynamicScheduleName}
                  // sx={{
                  //   opacity: !isPreview ? 1 : 0,
                  //   pointerEvents:
                  //     (!isPreview ? 'auto' : 'none') + ' !important',
                  //   position:
                  //     (!isPreview ? 'relative' : 'absolute') + ' !important',
                  //   top: 0,
                  // }}
                />
              </CosDataView>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateNewSchedule;
