import { CosCalendar } from '../components';
import StandardLayout from './StandardLayout';

const StandardCalendar = () => {
  return (
    <StandardLayout>
      <p>Calender</p>
      <CosCalendar />
    </StandardLayout>
  );
};

export default StandardCalendar;
