import React, { memo, useMemo } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { cn } from '../libs/utils';
import Sidebar, {
  PerformanceChip,
  SidebarNavigationList,
} from '../layout/masterlayout/components/Sidebar';
import Navbar from '../layout/masterlayout/components/Navbar';
import TempIconShow from '../layout/masterlayout/TempIconShow';
import DrawerHeader from '../layout/masterlayout/components/ui/AppBarLeftSideBarHeader';
import {
  Company,
  Dashboard,
  Projects,
  Resources,
  Schedules,
  UsersGroup,
} from '../assets/svgs';
import clsx from 'clsx';
import settings from '../assets/svgs/settings.svg';
import { RouteObject } from 'react-router';

export interface StandardDashboardProps {
  children: React.ReactNode;
  navigatorList?: RouteObject;
  layoutColor?: 'primary' | 'secondary';
  layoutType?: 'table' | 'normal';
}

const standardSidebarList: SidebarNavigationList[] = [
  {
    label: 'My Company',
    link: '/coming_soon/my_company',
    icon: () => <Company />,
    isDisable: false,
    leftSideComponent: ({ className }) => (
      <div
        className={clsx(
          'flex size-7 items-center justify-center rounded-full border border-solid border-error-900',
          className,
        )}
      >
        <span className="text-10/22 text-error-900">20%</span>
      </div>
    ),
  },
  {
    label: 'Users',
    link: '/coming_soon/users',
    icon: () => <UsersGroup />,
    isDisable: false,
    badgeValue: 1,
  },
  {
    label: 'Settings',
    link: '/coming_soon/settings',
    icon: ({ isActive }) => (
      <img
        src={settings}
        style={{ width: 16, height: 16 }}
        className={cn('invert-70', {
          '!invert': isActive,
        })}
      />
    ),
    isDisable: false,
    // badgeValue: 1,
  },
  // {
  //   label: 'Standards',
  //   link: '/standard',
  //   icon: () => <Dashboard />,
  // },
  {
    label: 'Dashboard',
    link: '/coming_soon/dashboard',
    icon: () => <Dashboard />,
    isDisable: true,
    leftSideComponent: () => <PerformanceChip>Performance</PerformanceChip>,
    // badgeValue: 1,
  },
  {
    label: 'Projects',
    link: '/coming_soon/projects',
    icon: () => <Projects />,
    isDisable: true,
    // badgeValue: 1,
  },
  {
    label: 'Resources',
    link: '/coming_soon/resources',
    icon: () => <Resources />,
    isDisable: true,
    // badgeValue: 1,
  },
  {
    label: 'Schedules',
    link: '/coming_soon/schedules',
    icon: () => <Schedules />,
    isDisable: true,
    // badgeValue: 1,
  },
];

/**
 * @description create same as master layout
 * */
const StandardDashboard: React.FC<StandardDashboardProps> = ({
  children,
  layoutColor = 'primary',
  layoutType = 'normal',
  navigatorList,
}) => {
  const [open, setOpen] = React.useState(true);

  const toggleSideBar = () => {
    setOpen(preValue => !preValue);
  };

  const isTableLayout = useMemo(() => layoutType === 'table', [layoutType]);
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar
          open={open}
          navigatorList={navigatorList || { path: '/standard' }}
        />
        <Sidebar
          open={open}
          toggleSideBar={toggleSideBar}
          list={standardSidebarList}
        />
        <Box
          component="main"
          className={cn('flex-grow overflow-x-auto bg-white-whale-500 p-2', {
            'cos-secondary-scrollbar': !isTableLayout,
            'cos-primary-scrollbar pr-[2px]': isTableLayout,
          })}
        >
          <DrawerHeader />
          <Box
            sx={{
              background: isTableLayout ? '#EDEEEF' : '#ffffff',
              borderRadius: isTableLayout ? 0 : '4px',
              boxShadow: '0px 0px 4px #00000017',
              overflow: 'auto',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: isTableLayout
                  ? 'calc(100vh - 116px)'
                  : 'calc(100vh - 132px)',
                margin: isTableLayout ? 0 : '8px 0',
                marginRight: '2px',
                marginLeft: isTableLayout ? 0 : '8px',
                paddingRight: isTableLayout ? 0 : '2px',
                scrollbarGutter: isTableLayout ? 'auto' : 'stable',
                overflow: 'auto',
                position: 'relative',
              }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
      <TempIconShow />
    </>
  );
};

export default memo(StandardDashboard);
