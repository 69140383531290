import React from 'react';
import StandardLayout from './StandardLayout';
import Header from '../layout/auth/components/Header';

const StandardMainHeader = () => {
  return (
    <StandardLayout>
      <p className="text-center">Sign In/ Sign Up</p>
      <Header isCosLogo={true} />
    </StandardLayout>
  );
};

export default StandardMainHeader;
