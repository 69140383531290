import React, { useRef } from 'react';
import { CosCheckbox } from '../components/formikForms/ui';
import StandardLayout from './StandardLayout';
import useShiftMultiCheckBox from '../libs/hooks/useShiftMultiCheckBox';

const generateItems = (numberOfItems: number) => {
  const generatedItems = [];
  for (let i = 1; i <= numberOfItems; i++) {
    generatedItems.push({ label: `Item ${i}`, id: `value-${i}` });
  }
  return generatedItems;
};

const StandardPageCheckbox = () => {
  const items = generateItems(20);

  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  const ref3 = useRef<HTMLDivElement>(null);
  const ref4 = useRef<HTMLDivElement>(null);

  const { handleSelectItem: handleSelectItem1, selectedItems: selectedItems1 } =
    useShiftMultiCheckBox({
      ref: ref1,
      list: items,
      valueKey: 'id',
    });

  const { handleSelectItem: handleSelectItem2, selectedItems: selectedItems2 } =
    useShiftMultiCheckBox({
      ref: ref2,
      list: items,
      valueKey: 'id',
    });

  const { handleSelectItem: handleSelectItem3, selectedItems: selectedItems3 } =
    useShiftMultiCheckBox({
      ref: ref3,
      list: items,
      valueKey: 'id',
    });

  const { handleSelectItem: handleSelectItem4, selectedItems: selectedItems4 } =
    useShiftMultiCheckBox({
      ref: ref4,
      list: items,
      valueKey: 'id',
    });

  return (
    <StandardLayout>
      <div className="mt-5 flex gap-3">
        <div style={{ borderRight: '1px solid #000000', paddingRight: '10px' }}>
          <p className="mt-0">Horizontal</p>
          <div
            className="flex flex-col items-center justify-center gap-3"
            ref={ref4}
          >
            {items.map(item => (
              <CosCheckbox
                onChange={handleSelectItem4}
                checked={selectedItems4.includes(item.id)} //* same as valueKey or provided value
                value={item.id}
                id={`item-${item.id}`}
                key={`${item.id}-item4`}
              />
            ))}
          </div>
        </div>
        <div>
          <p className="mt-0">Normal</p>
          <div
            className="flex h-[50px] items-center justify-center gap-4"
            ref={ref1}
          >
            {items.map(item => (
              <CosCheckbox
                onChange={handleSelectItem1}
                checked={selectedItems1.includes(item.id)} //* same as valueKey or provided value
                value={item.id}
                id={`item-${item.id}`}
                key={`${item.id}-item1`}
              />
            ))}
          </div>

          <p>Gray Background</p>
          <div
            className="flex h-[50px] items-center justify-center gap-4 bg-white-whale-500"
            ref={ref2}
          >
            {items.map(item => (
              <CosCheckbox
                onChange={handleSelectItem2}
                withGrayBg
                checked={selectedItems2.includes(item.id)} //* same as valueKey or provided value
                value={item.id}
                id={`item-${item.id}`}
                key={`${item.id}-item2`}
              />
            ))}
          </div>

          <p>Blue Background</p>
          <div
            className="flex h-[50px] items-center justify-center gap-4 bg-artist-blue-900"
            ref={ref3}
          >
            {items.map(item => (
              <CosCheckbox
                onChange={handleSelectItem3}
                withGrayBg
                checked={selectedItems3.includes(item.id)} //* same as valueKey or provided value
                value={item.id}
                id={`item-${item.id}`}
                key={`${item.id}-item3`}
                version="dark"
              />
            ))}
          </div>

          <h2 className="text-center">Disable section </h2>
          <div className="flex gap-3">
            <div>
              <p>Normal Background</p>
              <div className="flex h-[50px] w-[230px] items-center justify-center gap-4">
                <CosCheckbox disabled defaultChecked={true} />
                <CosCheckbox disabled />
              </div>
            </div>

            <div>
              <p>Gray Background</p>
              <div className="flex h-[50px] w-[230px] items-center justify-center gap-4 bg-white-whale-500">
                <CosCheckbox withGrayBg defaultChecked={true} disabled />
                <CosCheckbox withGrayBg disabled />
              </div>
            </div>

            <div>
              <p>Blue Background</p>
              <div className="flex h-[50px] w-[230px] items-center justify-center gap-4 bg-artist-blue-900">
                <CosCheckbox version="dark" defaultChecked={true} disabled />
                <CosCheckbox version="dark" disabled />
              </div>
            </div>
          </div>
        </div>
      </div>
    </StandardLayout>
  );
};

export default StandardPageCheckbox;
