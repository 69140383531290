import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import CosInput, { CosInputProps } from '../formikForms/ui/CosInput';
import { CloseHover, CloseUnfill, SearchYellowIcon } from '../../assets/svgs';
import { Chip, styled, Box } from '@mui/material';
import CosIcon from '../CosIcon';
import { KEYBOARD_KEY } from '../../libs/constant';

const BpBox = styled(Box)(({}) => ({
  background: '#FFFFFF',
  boxShadow: '0px 1px 3px 0px #A8B1B8',
  borderRadius: '3px',
  borderTopLeftRadius: '0px',
  borderTopRightRadius: '0px',
  padding: '15px 0 15px 13px',
  width: '506px',
  position: 'absolute',
  top: '26px',

  '& .MuiButtonBase-root': {
    marginRight: '8px',
    width: '115px',
    height: '25px',
    marginBottom: '10px',
  },
}));
interface SearchData {
  label: string;
  active: boolean;
}
const initialSearchList: SearchData[] = [
  { label: 'Resources', active: true },
  { label: 'Database', active: false },
  { label: 'TBD', active: false },
  { label: 'TBD', active: false },
];
const CosGlobalSearchBar: React.FC<CosInputProps> = ({
  className,
  inputSx,
  placeholder,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [searchList, setSearchList] = useState<SearchData[]>(initialSearchList);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearchOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setInputValue('');
    setOpen(false);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = event => {
    if (event.key === KEYBOARD_KEY.enter) {
      handleSearchOpen();
    } else if (event.key === KEYBOARD_KEY.esc) {
      handleClose();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSearchListClick = (index: number) => {
    const newSearchList = searchList.map((item, idx) =>
      idx === index ? { ...item, active: !item.active } : item,
    );
    setSearchList(newSearchList);
  };

  return (
    <>
      <div
        ref={containerRef}
        className={clsx('relative inline-block w-[200px]')}
      >
        <CosInput
          placeholder={placeholder}
          name={'search-input'}
          type={'text'}
          isPopupInput={true}
          value={inputValue}
          startIconClass={'!w-[15px] pl-[10px]'}
          startIcon={
            open && inputValue != '' ? (
              <CosIcon
                className="!w-[15px] cursor-pointer pt-[2px]"
                onHoverIcon={<CloseHover />}
                onClick={handleClose}
              >
                <CloseUnfill />
              </CosIcon>
            ) : (
              ''
            )
          }
          inputSx={{
            height: '25px',
            border: 0,
            fontSize: '12px',
            lineHeight: '16px',
            paddingRight: '33px',
            paddingLeft: open && inputValue != '' ? '28px' : '13px',
            minWidth: 'unset',
            borderBottomLeftRadius: open ? '0px !important' : '3px',
            borderBottomRightRadius: open ? '0px !important' : '3px',
            ...inputSx,
          }}
          fullWidth
          className={clsx(
            '!mb-0',
            open && '!w-[506px]' + ' !mb-0 min-w-[200px]',
            className,
          )}
          onKeyDown={handleKeyDown}
          onChange={handleInputChange}
          children={
            <span className="absolute right-0 top-1/2 h-full translate-y-[-37%] px-2">
              <SearchYellowIcon
                className="color-animation cursor-pointer text-dark-blue-900 hover:text-buddha-love-800"
                onClick={handleSearchOpen}
              />
            </span>
          }
        />
        {open && (
          <BpBox>
            <div>
              <p className="mt-0 text-12/17 text-dark-blue-900">Search By</p>
              {searchList.map((item: SearchData, index) => {
                return (
                  <Chip
                    key={index}
                    label={item.label}
                    className={clsx(
                      item.active
                        ? 'bg-artist-blue-900 text-white-whale-100'
                        : 'bg-slate-100 text-dark-blue-900',
                      'font-semibold ',
                    )}
                    clickable
                    onClick={() => handleSearchListClick(index)}
                  />
                );
              })}
            </div>
            <div className="recent-search">
              <p className="mt-0 text-12/17 text-dark-blue-900">
                Recent Searches
              </p>
            </div>
          </BpBox>
        )}
      </div>
    </>
  );
};

export default CosGlobalSearchBar;
