import React, { useRef, useState } from 'react';
import { Box, Tab, Tabs, alpha, styled, TabScrollButton } from '@mui/material';
import { handleScrollButtonClick, commonTabsSX } from '../Tabs/tab.util';
import useTabScroll from '../../libs/hooks/useTabScroll';

const BpBox = styled(Box)(({}) => ({
  height: 'calc(100vh - 0px)',
}));
const BpTab = styled(Tabs, {
  shouldForwardProp: prop => prop !== 'isProjectList',
})<PageVerticalTabProps>(({ isProjectList }) => ({
  minWidth: 'calc(100vh - 0px)',
  // maxWidth: '100%',
  width: 'calc(100vh - 0px)',
  height: '36px !important',
  transform: 'rotate(90deg) translateY(-50%)',
  transformOrigin: 'left',
  backgroundColor: '#EDEEEF',
  position: 'absolute',
  paddingRight: '25px !important',
  top: '-18px',
  ...commonTabsSX,
  '& .snapScrollClass': {
    gap: '5px',
    scrollSnapType: 'x mandatory !important',
  },
  // '& .mainSnapScrollClass': {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   scrollSnapType: 'x mandatory',
  //   scrollBehavior: 'smooth',
  //   scrollSnapAlign: 'start',
  // },
  '& .MuiTabs-scrollButtons': {
    color: '#07B9EC',
  },
  '& .MuiTabs-scroller': {
    width: 'calc(100% - 25px)',
  },
  '& .MuiTab-root': {
    flexGrow: '1',
    scrollSnapAlign: 'start',
    height: '23px',
    minHeight: '21px',
    minWidth: '92px',
    width: 'calc((100% / 6) - 5px)',
    backgroundColor: '#001F38',
    border: `1px solid ${alpha('#07B9EC', 0.25)}`,
    color: alpha('#FFFFFF', 0.7),
    borderRadius: '0px 0px 5px 5px',
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '21px',
    textTransform: 'capitalize',
    padding: 0,

    '&:first-of-type': {
      border: 0,
      backgroundColor: '#07B9EC',
      color: alpha('#FFFFFF', 1),
      fontWeight: 600,
    },
    '&:hover': {
      border: 0,
      backgroundColor: '#03688e',
      color: alpha('#07B9EC', 0.7),
    },
  },
  '& .MuiTab-root[aria-selected="true"]': {
    height: isProjectList ? '28px' : '23px',
    color: alpha('#FFFFFF', 0.7),
  },
}));

export interface PageVerticalTabProps extends CosTabs {
  hanldeTabToggle?: () => void;
  onTabClick?: () => void;
  onTabDoubleClick?: () => void;
  isTabOpen: boolean;
  isProjectList: boolean;
}
const CosPageVerticalTab: React.FC<PageVerticalTabProps> = ({
  tabs,
  hanldeTabToggle,
  onTabClick,
  onTabDoubleClick,
  isTabOpen,
  isProjectList,
  ...rest
}) => {
  const [value, setValue] = useState();

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue && newValue);
  };

  const tabsRef = useRef<HTMLDivElement | null>(null);
  const tabType = 'vertical';
  useTabScroll(tabsRef, tabType);
  return (
    <BpBox>
      {isTabOpen && (
        <BpTab
          isTabOpen={false}
          isProjectList={isProjectList}
          tabs={[]}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          selectionFollowsFocus={true}
          {...rest}
          ScrollButtonComponent={({ ...rest }) => {
            return (
              <TabScrollButton
                {...rest}
                onClick={() =>
                  handleScrollButtonClick(
                    tabsRef.current,
                    rest.direction,
                    tabType,
                  )
                }
              />
            );
          }}
          ref={tabsRef}
        >
          {tabs.map((item: CosTab, index: number) => (
            <Tab
              label={item.label}
              key={`${index}-${item.label}`}
              className="snap-start"
              onClick={() => onTabClick?.()}
              onDoubleClick={() => onTabDoubleClick?.()}
            />
          ))}
        </BpTab>
      )}
    </BpBox>
  );
};

export default CosPageVerticalTab;
