import { Box, BoxProps, Divider, styled } from '@mui/material';
import { cn } from '../../../libs/utils';

const BpPreview = styled(Box)(({}) => ({
  '& label': {
    marginBottom: '16px',
    marginRight: '20px',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#001F38',
    opacity: '0.6',
  },
  '& span': {
    fontWeight: '600',
    fontSize: '14px',
    color: '#001F38',
    opacity: '1',
  },
}));
interface Props extends BoxProps {
  previewData?: any;
}

const PreivewScheduleForm: React.FC<Props> = ({
  previewData,
  className,
  ...rest
}) => {
  return (
    <BpPreview {...rest} className={cn('relative', className)}>
      <div className="flex px-[15px]">
        <section className="grid w-[120px] text-right">
          <label className="flex h-7 items-center justify-end">
            Work Schedule
          </label>
          <label className="flex h-7 items-center justify-end">Starts on</label>
          <label className="flex h-7 items-center justify-end">Works</label>
          <label className="flex h-7 items-center justify-end">For</label>
          <label className="flex h-7 items-center justify-end">With</label>
        </section>
        <section className="grid text-left">
          <div className="h-7 pt-[2px]">
            <span>{previewData.scheduleName}</span>
          </div>
          <div className="h-7 pt-[2px]">
            <span>{previewData.startDay?.value}</span>
            <label className="mx-1 mb-0"> at </label>
            <span>{previewData.startTime?.value}</span>
          </div>
          <div className="h-7 pt-[2px]">
            <span>
              {previewData.workingDay
                .map((item: any) => item?.value.slice(0, 3))
                .join(', ')}
            </span>
          </div>
          <div className="h-7 pt-[2px]">
            <span>{previewData.workHours?.value}</span>
            <label className="mx-1 mb-0"> Per Day</label>
          </div>
          <div className="h-7 pt-[2px]">
            <span>{previewData.breakTime?.value}</span>
            <label className="mx-1 mb-0"> Break </label>
          </div>
        </section>
      </div>
      <Divider className="main-divider mb-4 mt-[76px]"></Divider>
      <div className="mb-[35px] flex px-[15px]">
        <section className="grid w-[120px] text-right">
          <label>Deployments</label>
        </section>
        <section className="grid text-left">
          <div className="leading-[18px]">
            <span>
              {previewData.deployments
                .map((item: any) => item?.value)
                .join(', ')}
            </span>
          </div>
        </section>
      </div>
    </BpPreview>
  );
};

export default PreivewScheduleForm;
