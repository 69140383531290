import React from 'react';
import { Box, TextField, alpha, styled } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import {
  DateRangePicker,
  DateRange,
} from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs, { Dayjs } from 'dayjs';
import { Popper } from '@mui/material';

const StyledDateRangePicker = styled(DateRangePicker)({
  '& .MuiInputBase-root': {
    width: '270px',
    height: '28px',
    color: '#001F38',
    fontSize: '14px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: 0,
    boxShadow: '0px 0px 3px #A8B1B8',
    borderRadius: '3px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '& .MuiPickersArrowSwitcher-root': {
    background: '#FFB92C',
    marginTop: 0,
    padding: '10px',
    height: '33px',
    width: '211px',
    '& .MuiTypography-root': {
      color: '#FFFFFF',
      padding: '0px',
    },
    '& .MuiButtonBase-root': {
      width: '9px',
      height: '9px',
      color: '#FFFFFF',
      borderWidth: '0 2px 2px 0',
      '&:hover': {
        background: 'transparent',
        boxShadow: 'none',
        transition: 'transform duration-[0.3s] ease-[ease]',
        scale: '1.15',
      },
    },
  },
  '& .MuiDayPicker-header': {
    padding: '0px',
    height: '30px',
    width: '211px',
    '& .MuiDayPicker-weekDayLabel': {
      color: '#000000',
      background: '#EDEEEF',
      width: '30px',
      height: '30px',
      margin: '0px',
    },
  },
  '& .PrivatePickersSlideTransition-root': {
    background: alpha('#EDEEEF', 0.75) + '!important',
    boxShadow: 'unset !important',
    color: '#000000',
    minWidth: '211px',
    minHeight: '149px',
    margin: '0px',
    '& .MuiDayPicker-weekContainer': {
      margin: 0,
    },
  },
  '& .MuiDateRangePickerDay-root': {
    background: '#FFFFFF',
    border: 0,
    borderRadius: 0,
    '& .MuiDateRangePickerDay-rangeIntervalPreview': {
      width: '29px',
      height: '29px',
      margin: 0,
      border: `1px solid ${alpha('#FFB92C', 0.25)}`,
    },
    '& .MuiPickersDay-root': {
      width: '27px',
      height: '27px',
      fontSize: '12px',
    },
    '& .MuiPickersDay-today': {
      border: 0,
      borderRadius: 0,
      background: '#FFB92C !important',
      color: '#FFFFFF',
    },
  },
});

const CosCalendar = () => {
  const [value, setValue] = React.useState<DateRange<Dayjs>>([null, null]);

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledDateRangePicker
          calendars={1}
          value={value}
          onChange={newValue => {
            // setValue(newValue);
          }}
          renderInput={(startProps: any, endProps: any) => (
            <React.Fragment>
              <TextField
                {...startProps}
                label=""
                placeholder="Select Date"
                className="startDate"
              />
              <Box sx={{ mx: 2 }}> </Box>
              <TextField
                {...endProps}
                label=""
                placeholder="Select Date"
                className="endDate"
              />
            </React.Fragment>
          )}
          PopperProps={{
            modifiers: [
              {
                name: 'flip',
                enabled: false,
              },
              {
                name: 'offset',
                options: {
                  offset: [0, 2], // Adjust offset as needed
                },
              },
            ],
            placement: 'bottom-start',
          }}
        />
      </LocalizationProvider>
    </Box>
    // <BpBox className={clsx('relative inline-block w-[200px]')}>
    //   <DatePicker
    //     value={values}
    //     onChange={setValues}
    //     dateSeparator=" to "
    //     placeholder={'Select Date'}
    //     range={true}
    //     showOtherDays={true}
    //     monthYearSeparator={' '}
    //     weekDays={weekDays}
    //     arrow={false}
    //     render={<InputIcon />}
    //     format="MM/DD/YYYY"
    //     className="custom-calendar"
    //   />
    // </BpBox>
  );
};

export default CosCalendar;
