import { Box } from '@mui/material';
import React from 'react';
import StandardLayout from './StandardLayout';

const StandardScrollBar = () => {
  return (
    <StandardLayout>
      <Box className="mt-5 flex gap-10">
        <Box className="cos-secondary-scrollbar w-5 bg-white">
          <Box className=" mx-[2px] my-1 h-80 overflow-auto">
            <Box className="h-[500px]"></Box>
          </Box>
        </Box>

        <Box className="cos-primary-scrollbar w-5 bg-white-whale-500">
          <Box className=" mx-[2px] my-1 h-80 overflow-auto">
            <Box className="h-[500px]"></Box>
          </Box>
        </Box>

        <Box className="cos-dark-scrollbar w-5 bg-dark-blue-900 ">
          <Box className="mx-[2px] my-1 h-80 overflow-auto">
            <Box className="h-[500px]"></Box>
          </Box>
        </Box>
      </Box>
    </StandardLayout>
  );
};

export default StandardScrollBar;
