import Masterlayout from '../layout/masterlayout/Masterlayout';
import {
  CosTable,
  CosTableContainer,
  CosTableRow,
  CosTableCell,
  CosTableHead,
  CosTableSpacer,
  CosTableBody,
  CosTableActionButton,
} from '../components/Tables';
import CosFocusTrap from '../components/CosFocusTrap';
import StandardDashboard from './StandardDashboard';
import StandardLayout from './StandardLayout';
import { FloatingAddButton } from '../components';

function createData(
  scheduleLibrary: string,
  workingDays: string,
  noOdDays: string,
  weekStart: string,
  timeDatStarts: string,
  hoursWorking: string,
  userBreak: string,
  deployment: string,
) {
  return {
    scheduleLibrary,
    workingDays,
    noOdDays,
    weekStart,
    timeDatStarts,
    hoursWorking,
    userBreak,
    deployment,
  };
}

const rows = Array(100)
  .fill(1)
  .map((_item, index) => {
    return createData(
      '05 Day Workweek, Mon - Fri, 07:00 AM to 05:00 PM',
      'Mon,Tue,Wed,Thu,Fri',
      '05',
      'Monday',
      '07:00 AM',
      '10 Hours',
      '00 Hrs : 45 Mins',
      'CommrcialOps',
    );
  });

export default function StandardDataTable() {
  return (
    <StandardLayout>
      <CosTableContainer>
        <CosFocusTrap>
          <CosTable stickyHeader>
            <CosTableHead>
              <CosTableRow>
                <CosTableCell className="CosAction-cell">Actions</CosTableCell>
                <CosTableCell>WORK SCHEDULE LIBRARY</CosTableCell>
                <CosTableCell>WORKING DAYS</CosTableCell>
                <CosTableCell className="w-[62px] text-center">
                  NO. OF DAYS
                </CosTableCell>
                <CosTableCell>DAY WEEK STARTS</CosTableCell>
                <CosTableCell>TIME DAY STARTS</CosTableCell>
                <CosTableCell>HOURS WORKING</CosTableCell>
                <CosTableCell>BREAK</CosTableCell>
                <CosTableCell className="CosDeployment-cell">
                  DEPLOYMENTS
                </CosTableCell>
              </CosTableRow>
            </CosTableHead>
            <CosTableBody>
              {rows.map((row, index) => (
                <CosTableRow key={`${index}-schedule-table`}>
                  <CosTableCell className="CosAction-cell">
                    <CosTableActionButton
                      index={index}
                      onDeleteClick={() => {}}
                      onViewClick={() => {}}
                      onEditClick={() => {}}
                    />
                  </CosTableCell>
                  <CosTableCell>{row.scheduleLibrary}</CosTableCell>
                  <CosTableCell>{row.workingDays}</CosTableCell>
                  <CosTableCell className="w-[62px] text-center">
                    {row.noOdDays}
                  </CosTableCell>
                  <CosTableCell>{row.weekStart}</CosTableCell>
                  <CosTableCell>{row.timeDatStarts}</CosTableCell>
                  <CosTableCell>{row.hoursWorking}</CosTableCell>
                  <CosTableCell>{row.userBreak}</CosTableCell>
                  <CosTableCell className="CosDeployment-cell">
                    {row.deployment}
                  </CosTableCell>
                </CosTableRow>
              ))}
            </CosTableBody>
          </CosTable>
        </CosFocusTrap>
      </CosTableContainer>

      {/* for remain border-radius with below extra space */}
      {/* <CosTableSpacer /> */}
      <section className="absolute bottom-0 right-3 z-10">
        <FloatingAddButton />
      </section>
    </StandardLayout>
  );
}
