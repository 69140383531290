import React from 'react';
import { CosButton, CosTooltip } from '../components';
import { AddIcon, Bin, EditIcon } from '../assets/svgs';
import deleteIcon from '../assets/svgs/staticColorSvg/Delete-icon.svg';
import StandardLayout from './StandardLayout';

const StandardPageButton = () => {
  return (
    <StandardLayout>
      <section className="mt-5 space-x-4">
        <p className="mt-0">15px Button (Extra Small)</p>
        <CosButton customSize="extraSmall" variant="outlined">
          save
        </CosButton>

        <CosButton customSize="extraSmall" variant="contained">
          save
        </CosButton>

        <CosButton customSize="extraSmall" variant="outlined" disabled>
          save
        </CosButton>

        <CosButton customSize="extraSmall" variant="contained" disabled>
          save
        </CosButton>
      </section>

      <section className="space-x-4 space-y-4">
        <p>20px Button (Small)</p>

        <div className="space-x-4">
          <CosButton size="small">Open Main Project List</CosButton>

          <CosButton size="small" color="secondary">
            Resend Email
          </CosButton>

          <CosButton size="small" color="secondary" disabled>
            Resend Email
          </CosButton>

          <CosButton size="small" variant="text" color="secondary">
            Cancel
          </CosButton>

          <CosButton size="small" variant="text">
            Save & Close
          </CosButton>
        </div>

        <CosButton size="small" variant="contained">
          View All (4)
        </CosButton>

        <CosButton size="small" variant="outlined" color="primary">
          Complete Import (4)
        </CosButton>

        <CosButton size="small" variant="outlined" startIcon={<EditIcon />}>
          Edit
        </CosButton>

        <CosButton
          size="small"
          variant="outlined"
          color="error"
          startIcon={<Bin />}
        >
          Delete
        </CosButton>

        <CosButton size="small" variant="outlined" startIcon={<AddIcon />}>
          Add Bank
        </CosButton>

        <CosButton
          size="small"
          variant="outlined"
          color="error"
          className="delete_icon__hover_withing"
          startIcon={<img src={deleteIcon} className="delete_icon" />}
          disabled
        >
          Delete
        </CosButton>

        <div>
          <p className="mb-0">With tooltip</p>
          <CosTooltip title="Save As New Template" placement="left-start">
            <CosButton size="small" variant="outlined">
              Save As
            </CosButton>
          </CosTooltip>
        </div>
      </section>

      <section className="space-x-4">
        <p>25px Button (Medium)</p>
        <CosButton size="medium" component="label">
          {/* //* Pass component="label" if you want to upload file */}
          Select File
        </CosButton>

        <CosButton size="medium" variant="contained" disableElevation={false}>
          Save
        </CosButton>

        <CosButton size="medium" variant="contained" disableElevation={false}>
          Save & Next
        </CosButton>

        <CosButton size="medium" variant="contained" disableElevation={false}>
          Save & Close
        </CosButton>

        <CosButton size="medium" variant="contained" disabled>
          Save & Close
        </CosButton>

        <CosButton size="medium" variant="text" color="secondary">
          Cancel
        </CosButton>

        <CosButton size="medium" variant="text">
          Save & Close
        </CosButton>
      </section>

      <section className="space-x-4">
        <p>35px Button (Large)</p>
        <CosButton size="large" variant="outlined" component="label">
          Close
        </CosButton>

        <CosButton size="large" variant="outlined" color="error">
          Confirm
        </CosButton>

        <CosButton size="large" variant="outlined" disabled>
          Confirm
        </CosButton>
      </section>

      <section className="space-x-4">
        <p>45px Button (Extra Large)</p>
        <CosButton customSize="extraLarge" variant="outlined" color="secondary">
          Start Optimizing
        </CosButton>

        <CosButton
          customSize="extraLarge"
          variant="outlined"
          color="secondary"
          disabled
        >
          Start Optimizing
        </CosButton>
      </section>
    </StandardLayout>
  );
};

export default StandardPageButton;
