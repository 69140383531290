import { useEffect, useRef, useState } from 'react';
import { moveTabToNextField } from '../../../../libs/helper';
import { KEYBOARD_KEY } from '../../../../libs/constant';
import { cloneDeep, findIndex } from 'lodash';

interface Props {
  options: any;
  multiple: boolean;
  // onChange?: (
  //   event: React.SyntheticEvent,
  //   value: AutocompleteValue<any, any, any, any>,
  //   reason: AutocompleteChangeReason,
  //   details?: AutocompleteChangeDetails<any>,
  // ) => void;
  onChange: any; //TODO : manage type
  name?: string;
  defaultValue?: any;
}

// TODO : manage Generic Options or Values Type
const useCosAutoComplete = (props: Props) => {
  const { onChange, options, name, multiple, defaultValue } = props;
  const initialValue = defaultValue ? defaultValue : multiple ? [] : null;
  const [isOpen, setIsOpen] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [isInitialState, setIsInitialState] = useState(true);
  const [value, setValue] = useState<any>(initialValue); //* Value type is same as option
  const [cloneValue, setCloneValue] = useState<any>(initialValue);

  useEffect(() => {
    const initialValue = defaultValue ? defaultValue : multiple ? [] : null;
    setValue(initialValue);
    setCloneValue(initialValue);
  }, [defaultValue]);

  // useEffect(() => {
  //   if (defaultValue == null || defaultValue.length <= 0) {
  //     // setCloneValue([]);
  //     setValue(multiple ? [] : null);
  //     setCloneValue(multiple ? [] : null);
  //   }
  // }, [defaultValue]);

  // useEffect(() => {
  //   if (multiple) {
  //     let data = [];
  //     data = options.map((item: any, index: any) => ({
  //       ...item,
  //       key: index,
  //     }));
  //     setMofidyOptions(data);
  //   } else {
  //     setMofidyOptions(options);
  //   }
  // }, [options]);

  const inputRef = useRef<HTMLInputElement>(null);
  const handleOpen = () => setIsOpen(true);
  const handleClose = (event?: any) => {
    const key = event?.key;
    const type = event?.type;
    if (type == 'blur') {
      setCloneValue(value);
      if (options.length !== value?.length && multiple) {
        setIsSelectAll(false);
      }
      return;
    }
    console.log('key', key);

    const elem = inputRef.current;
    //* Manage (Esc) keyboard event
    if (key === KEYBOARD_KEY.esc) {
      event.preventDefault();
      // isInitialState ? (setIsOpen(false), elem?.blur()) : handleCancel();
      isInitialState ? setIsOpen(false) : handleCancel();
    } else {
      setIsInitialState(true);
      setIsOpen(false);
      // elem?.blur();
    }
  };

  const handleCancel = () => {
    setIsInitialState(() => true);
    setCloneValue(() => value);
    // setIsSelectAll(mofidyOptions.length === value?.length);
    setIsSelectAll(options.length === value?.length);
  };

  // * multiple Apply button
  const handleApply = (event: any) => {
    onChange?.(event, cloneValue);
    setValue(() => cloneDeep(cloneValue));
    handleClose();
  };

  const handleToggleSelectAll = () => {
    setIsInitialState(() => false);
    setIsSelectAll(prev => {
      // if (!prev) setCloneValue([...mofidyOptions]);
      if (!prev) setCloneValue([...options]);
      else setCloneValue([]);
      return !prev;
    });
  };

  const handleMultiSelect = (selectedOption: any) => {
    setIsInitialState(() => false);
    const mock = cloneDeep(cloneValue);
    const index = findIndex(mock, selectedOption);
    if (index !== -1) {
      mock.splice(index, 1);
    } else {
      mock.push(selectedOption);
    }
    setCloneValue(() => mock);

    // setIsSelectAll(mofidyOptions.length === mock?.length);
    setIsSelectAll(options.length === mock?.length);
  };

  // TODO :  mange type
  // * when type is multiple than we manage our own onClick and onChange so selectedOption not getting when type is multiple
  const handleChange = (
    event: any,
    selectedOption: any,
    reason: any,
    details: any,
  ) => {
    const element: any = event.target;
    name && element?.setAttribute('name', name);
    element?.setAttribute('value', selectedOption?.value);

    if (multiple) {
      // * manage list in it own list
      // handleMultiSelect(selectedOption[0]);
    } else {
      setValue(() => selectedOption);
      onChange && onChange(event, selectedOption);
    }
  };

  // TODO : manage type
  const handleFilter = (options: any, state: any) => {
    const { inputValue } = state;
    if (inputValue) {
      return options.filter((option: any) =>
        option.label.toLowerCase().startsWith(inputValue.trim().toLowerCase()),
      );
    } else {
      return options;
    }
  };

  return {
    handleChange,
    handleFilter,
    isOpen,
    setIsOpen,
    handleOpen,
    handleClose,
    handleToggleSelectAll,
    value,
    isSelectAll,
    handleApply,
    isInitialState,
    handleCancel,
    inputRef,
    handleMultiSelect,
    cloneValue,
  };
};

export default useCosAutoComplete;
