import { CosVerticalTab } from '../components/Tabs/index';
import StandardLayout from './StandardLayout';
import { Box } from '@mui/material';

const contextTab = [
  { label: 'Task List', value: 'Task List' },
  { label: 'Project List', value: 'Project List' },
  { label: 'User List', value: 'User List' },
  { label: 'Customer List', value: 'Customer List' },
  { label: 'Assign1', value: 'Assign1' },
  { label: 'Assign2', value: 'Assign2' },
];
const moreThanSixContentTab = [
  { label: 'Task List', value: 'Task List' },
  { label: 'Project List', value: 'Project List' },
  { label: 'User List', value: 'User List' },
  { label: 'Customer List', value: 'Customer List' },
  { label: 'Assign1', value: 'Assign1' },
  { label: 'Assign2', value: 'Assign2' },
  { label: 'Assign3', value: 'Assign3' },
  { label: 'Assign4', value: 'Assign4' },
  { label: 'Assign5', value: 'Assign5' },
  { label: 'Assign6', value: 'Assign6' },
];
const StandardVerticalTab = () => {
  return (
    <StandardLayout>
      {/* <h5 className="mt-0 text-center">Page Content Tabs</h5> */}
      <div className="flex justify-center">
        <p className="pr-[40px]">With 6 Tabs</p>
        <p>With More than 6 Tabs</p>
      </div>
      <Box className="relative flex h-full items-center justify-center">
        <div className="mr-16 flex flex-col">
          <CosVerticalTab tabs={contextTab} />
        </div>
        <div className="ml-20 flex flex-col">
          <CosVerticalTab tabs={moreThanSixContentTab} />
        </div>
      </Box>
    </StandardLayout>
  );
};
export default StandardVerticalTab;
