import { Badge, Box, BoxProps, Divider, Typography } from '@mui/material';
import React from 'react';
import { constant } from '../../../../libs/constant';
import clsx from 'clsx';
import AdminProfile from './AdminProfile';
import CascadingHoverMenus from './NavigatorDropDownItem';
import { MessageIcon, NotificationIcon } from '../../../../assets/svgs';
import { RouteObject } from 'react-router';
import { NavLink } from 'react-router-dom';
import { cn, convertRouterToTitle } from '../../../../libs/utils';
import { CosGlobalSearchBar } from '../../../../components/dialogBox';
import CosSearchInput from '../../../../components/formikForms/ui/CosSearchInput';

const largeIconSx = {
  height: 23,
  width: 30,
};

const renderOption = (
  subNode: RouteObject['children'],
  isLeftMenu: boolean,
) => {
  return subNode?.map(
    (list, index) =>
      list.path && (
        <CascadingHoverMenus
          list={list}
          key={`${index}-${list.path}`}
          isLeftMenu={isLeftMenu}
        />
      ),
  );
};
interface Props extends BoxProps {
  isRightTopMenu?: boolean;
  isSearchbar?: boolean;
}
const Navigator: React.FC<Props & { navigatorList: RouteObject }> = ({
  className,
  navigatorList,
  isRightTopMenu = true,
  isSearchbar = false,
  ...rest
}) => {
  return (
    <Box className={clsx(className)} component="main" {...rest}>
      <Box
        component="section"
        className="flex items-center justify-between pl-5 pr-2"
        sx={{
          height: constant.navbarTopHeight,
        }}
      >
        <div className="m-0">
          {isSearchbar && (
            <CosGlobalSearchBar placeholder="Search %Company%"></CosGlobalSearchBar>
          )}
        </div>
        {isRightTopMenu && (
          <Box
            component="div"
            className="flex items-center gap-[15px]"
            sx={{
              height: constant.navbarTopHeight,
            }}
          >
            <div className="align-item-center flex h-full justify-center">
              <Divider
                className="my-[6px] mr-[5px] bg-white opacity-30"
                flexItem
                orientation="vertical"
              />
              <div className="flex w-[54px] items-center justify-center hover:bg-white/25">
                <Badge badgeContent={4} color="success">
                  <MessageIcon className="" style={largeIconSx} />
                </Badge>
              </div>

              <div className="flex w-[54px] items-center justify-center hover:bg-white/25">
                <Badge badgeContent={2} color="error">
                  <NotificationIcon className="" style={largeIconSx} />
                </Badge>
              </div>
            </div>
            <AdminProfile />
          </Box>
        )}
      </Box>
      <Divider className="h-[1px] bg-white opacity-30" />
      {navigatorList && (
        <>
          <Box
            component="section"
            className="flex items-center justify-between pl-5 pr-2"
            sx={{
              height: constant.navbarBottomHeight,
            }}
          >
            <Box
              component="div"
              className="flex-gow flex h-[40px] items-center"
            >
              {navigatorList.path && (
                <NavLink
                  to={navigatorList.path}
                  style={{ all: 'unset' }}
                  className={cn('!text-25/34', {
                    '!self-baseline': navigatorList?.children,
                  })}
                >
                  {({ isActive }) => (
                    <Typography
                      component="h5"
                      className={cn(
                        'mr-5 cursor-pointer !text-25/34 font-semibold -tracking-0.45 text-white',
                        {
                          'text-artist-blue-900':
                            location.pathname === navigatorList.path,
                        },
                      )}
                    >
                      {convertRouterToTitle(navigatorList.path as string)}
                    </Typography>
                  )}
                </NavLink>
              )}
              <Box
                className="flex items-center"
                sx={{
                  gap: constant.navBarItemGap + 'px',
                }}
              >
                {navigatorList?.children &&
                  renderOption(navigatorList?.children, false)}
              </Box>
            </Box>
            {isSearchbar && (
              <div className="-z-10 text-right">
                <CosSearchInput
                  placeholder="Search %Company%"
                  disableFocusElevation
                />
              </div>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Navigator;
