export const weekOptions = [
  {
    label: 'Sunday',
    value: 'Sunday',
    sortId: 1,
  },
  {
    label: 'Monday',
    value: 'Monday',
    sortId: 2,
  },
  {
    label: 'Tuesday',
    value: 'Tuesday',
    sortId: 3,
  },
  {
    label: 'Wednesday',
    value: 'Wednesday',
    sortId: 4,
  },
  {
    label: 'Thursday',
    value: 'Thursday',
    sortId: 5,
  },
  {
    label: 'Friday',
    value: 'Friday',
    sortId: 6,
  },
  {
    label: 'Saturday',
    value: 'Saturday',
    sortId: 7,
  },
];

export const deployments = [
  {
    label: 'BuilderOps',
    value: 'BuilderOps',
  },
  {
    label: 'CommrcialOPs',
    value: 'CommrcialOPs',
  },
  {
    label: 'DisastrOps',
    value: 'DisastrOps',
  },
];

export const TimeOptions = [
  {
    label: '06:00 AM',
    value: '06:00 AM',
  },
  {
    label: '07:00 AM',
    value: '07:00 AM',
  },
  {
    label: '08:00 AM',
    value: '08:00 AM',
  },
  {
    label: '09:00 AM',
    value: '09:00 AM',
  },
  {
    label: '10:00 AM',
    value: '10:00 AM',
  },
  {
    label: '11:00 AM',
    value: '11:00 AM',
  },
];

export const HourOptions = [
  {
    label: '08 Hours',
    value: '08 Hours',
  },
  {
    label: '09 Hours',
    value: '09 Hours',
  },
  {
    label: '10 Hours',
    value: '10 Hours',
  },
];

export const BreakOptions = [
  {
    label: 'N/A',
    value: 'N/A',
  },
  {
    label: '00 Hours : 45 Minutes',
    value: '00 Hours : 45 Minutes',
  },
  {
    label: '01 Hours : 00 Minutes',
    value: '01 Hours : 00 Minutes',
  },
  {
    label: '01 Hours : 30 Minutes',
    value: '01 Hours : 30 Minutes',
  },
  {
    label: '01 Hours : 45 Minutes',
    value: '01 Hours : 45 Minutes',
  },
  {
    label: '02 Hours : 00 Minutes',
    value: '02 Hours : 00 Minutes',
  },
];

export const TempScheduleData = [
  {
    scheduleName: '2 Day Workweek, Mon - Tue, 07:00 AM to 03:00 PM',
    workingDay: [
      {
        label: 'Monday',
        value: 'Monday',
        sortId: 2,
      },
      {
        label: 'Tuesday',
        value: 'Tuesday',
        sortId: 3,
      },
    ],
    startDay: {
      label: 'Tuesday',
      value: 'Tuesday',
    },
    startTime: {
      label: '07:00 AM',
      value: '07:00 AM',
    },
    workHours: {
      label: '08 Hours',
      value: '08 Hours',
    },
    breakTime: {
      label: '01 Hours : 00 Minutes',
      value: '01 Hours : 00 Minutes',
    },
    noOfDays: '2',
    deployments: [
      {
        label: 'CommrcialOPs',
        value: 'CommrcialOPs',
        sortId: 2,
      },
      {
        label: 'BuilderOps',
        value: 'BuilderOps',
        sortId: 1,
      },
    ],
  },
  {
    scheduleName: '3 Day Workweek, Mon - Tue, 08:00 AM to 04:00 PM',
    workingDay: [
      {
        label: 'Sunday',
        value: 'Sunday',
        sortId: 1,
      },
      {
        label: 'Monday',
        value: 'Monday',
        sortId: 2,
      },
      {
        label: 'Tuesday',
        value: 'Tuesday',
        sortId: 3,
      },
    ],
    startDay: {
      label: 'Monday',
      value: 'Monday',
    },
    startTime: {
      label: '08:00 AM',
      value: '08:00 AM',
    },
    workHours: {
      label: '09 Hours',
      value: '09 Hours',
    },
    breakTime: {
      label: '01 Hours : 00 Minutes',
      value: '01 Hours : 00 Minutes',
    },
    noOfDays: '3',
    deployments: [
      {
        label: 'CommrcialOPs',
        value: 'CommrcialOPs',
        sortId: 2,
      },
      {
        label: 'BuilderOps',
        value: 'BuilderOps',
        sortId: 1,
      },
    ],
  },
  {
    scheduleName: '5 Day Workweek, Mon - Fri, 08:00 AM to 04:00 PM',
    workingDay: [
      {
        label: 'Monday',
        value: 'Monday',
        sortId: 2,
      },
      {
        label: 'Tuesday',
        value: 'Tuesday',
        sortId: 3,
      },
      {
        label: 'Wednesday',
        value: 'Wednesday',
        sortId: 4,
      },
      {
        label: 'Thursday',
        value: 'Thursday',
        sortId: 5,
      },
      {
        label: 'Friday',
        value: 'Friday',
        sortId: 6,
      },
    ],
    startDay: {
      label: 'Saturday',
      value: 'Saturday',
    },
    startTime: {
      label: '08:00 AM',
      value: '08:00 AM',
    },
    workHours: {
      label: '08 Hours',
      value: '08 Hours',
    },
    breakTime: {
      label: '01 Hours : 30 Minutes',
      value: '01 Hours : 30 Minutes',
    },
    noOfDays: '2',
    deployments: [
      {
        label: 'BuilderOps',
        value: 'BuilderOps',
        sortId: 1,
      },
    ],
  },
  {
    scheduleName: '6 Day Workweek, Sun - Fri, 08:00 AM to 04:00 PM',
    workingDay: [
      {
        label: 'Sunday',
        value: 'Sunday',
        sortId: 1,
      },
      {
        label: 'Monday',
        value: 'Monday',
        sortId: 2,
      },
      {
        label: 'Tuesday',
        value: 'Tuesday',
        sortId: 3,
      },
      {
        label: 'Wednesday',
        value: 'Wednesday',
        sortId: 4,
      },
      {
        label: 'Thursday',
        value: 'Thursday',
        sortId: 5,
      },
      {
        label: 'Friday',
        value: 'Friday',
        sortId: 6,
      },
    ],
    startDay: {
      label: 'Sunday',
      value: 'Sunday',
    },
    startTime: {
      label: '08:00 AM',
      value: '08:00 AM',
    },
    workHours: {
      label: '09 Hours',
      value: '09 Hours',
    },
    breakTime: {
      label: '02 Hours : 30 Minutes',
      value: '02 Hours : 30 Minutes',
    },
    noOfDays: '6',
    deployments: [
      {
        label: 'CommrcialOPs',
        value: 'CommrcialOPs',
        sortId: 2,
      },
      {
        label: 'BuilderOps',
        value: 'BuilderOps',
        sortId: 1,
      },
    ],
  },
  {
    scheduleName: '2 Day Workweek, Mon - Tue, 07:00 AM to 03:00 PM',
    workingDay: [
      {
        label: 'Monday',
        value: 'Monday',
        sortId: 2,
      },
      {
        label: 'Tuesday',
        value: 'Tuesday',
        sortId: 3,
      },
    ],
    startDay: {
      label: 'Tuesday',
      value: 'Tuesday',
    },
    startTime: {
      label: '07:00 AM',
      value: '07:00 AM',
    },
    workHours: {
      label: '08 Hours',
      value: '08 Hours',
    },
    breakTime: {
      label: '01 Hours : 00 Minutes',
      value: '01 Hours : 00 Minutes',
    },
    noOfDays: '2',
    deployments: [
      {
        label: 'BuilderOps',
        value: 'BuilderOps',
        sortId: 1,
      },
    ],
  },
  {
    scheduleName: '3 Day Workweek, Mon - Tue, 08:00 AM to 04:00 PM',
    workingDay: [
      {
        label: 'Sunday',
        value: 'Sunday',
        sortId: 1,
      },
      {
        label: 'Monday',
        value: 'Monday',
        sortId: 2,
      },
      {
        label: 'Tuesday',
        value: 'Tuesday',
        sortId: 3,
      },
    ],
    startDay: {
      label: 'Monday',
      value: 'Monday',
    },
    startTime: {
      label: '08:00 AM',
      value: '08:00 AM',
    },
    workHours: {
      label: '09 Hours',
      value: '09 Hours',
    },
    breakTime: {
      label: '01 Hours : 30 Minutes',
      value: '01 Hours : 30 Minutes',
    },
    noOfDays: '3',
    deployments: [
      {
        label: 'CommrcialOPs',
        value: 'CommrcialOPs',
        sortId: 2,
      },
    ],
  },
  {
    scheduleName: '2 Day Workweek, Mon - Tue, 07:00 AM to 03:00 PM',
    workingDay: [
      {
        label: 'Monday',
        value: 'Monday',
        sortId: 2,
      },
      {
        label: 'Tuesday',
        value: 'Tuesday',
        sortId: 3,
      },
    ],
    startDay: {
      label: 'Saturday',
      value: 'Saturday',
    },
    startTime: {
      label: '07:00 AM',
      value: '07:00 AM',
    },
    workHours: {
      label: '08 Hours',
      value: '08 Hours',
    },
    breakTime: {
      label: '00 Hours : 45 Minutes',
      value: '00 Hours : 45 Minutes',
    },
    noOfDays: '2',
    deployments: [
      {
        label: 'CommrcialOPs',
        value: 'CommrcialOPs',
        sortId: 2,
      },
      {
        label: 'BuilderOps',
        value: 'BuilderOps',
        sortId: 1,
      },
    ],
  },
  {
    scheduleName: '3 Day Workweek, Mon - Tue, 08:00 AM to 04:00 PM',
    workingDay: [
      {
        label: 'Sunday',
        value: 'Sunday',
        sortId: 1,
      },
      {
        label: 'Monday',
        value: 'Monday',
        sortId: 2,
      },
      {
        label: 'Tuesday',
        value: 'Tuesday',
        sortId: 3,
      },
    ],
    startDay: {
      label: 'Monday',
      value: 'Monday',
    },
    startTime: {
      label: '08:00 AM',
      value: '08:00 AM',
    },
    workHours: {
      label: '09 Hours',
      value: '09 Hours',
    },
    breakTime: {
      label: '01 Hours : 00 Minutes',
      value: '01 Hours : 00 Minutes',
    },
    noOfDays: '3',
    deployments: [
      {
        label: 'CommrcialOPs',
        value: 'CommrcialOPs',
        sortId: 2,
      },
      {
        label: 'BuilderOps',
        value: 'BuilderOps',
        sortId: 1,
      },
    ],
  },
  {
    scheduleName: '2 Day Workweek, Mon - Tue, 07:00 AM to 03:00 PM',
    workingDay: [
      {
        label: 'Monday',
        value: 'Monday',
        sortId: 2,
      },
      {
        label: 'Tuesday',
        value: 'Tuesday',
        sortId: 3,
      },
    ],
    startDay: {
      label: 'Sunday',
      value: 'Sunday',
    },
    startTime: {
      label: '07:00 AM',
      value: '07:00 AM',
    },
    workHours: {
      label: '08 Hours',
      value: '08 Hours',
    },
    breakTime: {
      label: '01 Hours : 30 Minutes',
      value: '01 Hours : 30 Minutes',
    },
    noOfDays: '2',
    deployments: [
      {
        label: 'CommrcialOPs',
        value: 'CommrcialOPs',
        sortId: 2,
      },
      {
        label: 'BuilderOps',
        value: 'BuilderOps',
        sortId: 1,
      },
    ],
  },
  {
    scheduleName: '3 Day Workweek, Mon - Tue, 08:00 AM to 04:00 PM',
    workingDay: [
      {
        label: 'Sunday',
        value: 'Sunday',
        sortId: 1,
      },
      {
        label: 'Monday',
        value: 'Monday',
        sortId: 2,
      },
      {
        label: 'Tuesday',
        value: 'Tuesday',
        sortId: 3,
      },
    ],
    startDay: {
      label: 'Friday',
      value: 'Friday',
    },
    startTime: {
      label: '08:00 AM',
      value: '08:00 AM',
    },
    workHours: {
      label: '09 Hours',
      value: '09 Hours',
    },
    breakTime: {
      label: '02 Hours : 00 Minutes',
      value: '02 Hours : 00 Minutes',
    },
    noOfDays: '3',
    deployments: [
      {
        label: 'CommrcialOPs',
        value: 'CommrcialOPs',
        sortId: 2,
      },
      {
        label: 'BuilderOps',
        value: 'BuilderOps',
        sortId: 1,
      },
    ],
  },
];
