import { CosBasDialog, ModalPagination } from './index';
import clsx from 'clsx';
import { CosBaseDialogProps } from './CosBasDialog';
import { ModalActionClass } from './ModalActionButtons';
import { constant } from '../../libs/constant';
import { Box } from '@mui/material';
import { PaginationProps } from './ModalPagination';

interface Props extends CosBaseDialogProps, PaginationProps {
  contentBody?: React.ReactNode;
  dialogAction?: React.ReactNode;
  children?: React.ReactNode;
  actionButton?: string[];
  heading?: string;
  isPagination?: Boolean;
  disableApply?: boolean;
  handlePagination?: (page: number) => void;
}

const CosDataView: React.FC<Props> = ({
  open,
  headingClass,
  bodyClass,
  children,
  heading,
  dialogAction,
  actionButton,
  disableApply = false,
  currentPage,
  totalPage,
  isPagination = false,
  handlePagination,
  ...rest
}) => {
  return open ? (
    <CosBasDialog
      open={open}
      headingClass={clsx(
        'flex justify-center text-center font-semibold text-dark-blue-900 text-18/18 pt-[9px] pb-[17px] px-[15px]',
        headingClass,
      )}
      headerWrapperClass={'w-full'}
      heading={
        !isPagination ? (
          heading
        ) : (
          <ModalPagination
            disableApply={disableApply}
            heading={heading}
            currentPage={currentPage}
            totalPage={totalPage}
            handlePagination={handlePagination}
          />
        )
      }
      sx={{
        '.MuiPaper-root': {
          borderRadius: constant.WorkScheduleModalRadius + 'px',
          background: '#EDEEEF',
          boxShadow: '0px 0px 12px #A8B1B8',
          minWidth: '580px',
          maxWidth: '875px',
          minHeight: '394px',
          maxHeight: '650px',
        },
        '& .MuiDialogContent-root': {
          padding: 0,
        },
        '& .MuiDialogContent-root .form-group': {
          paddingLeft: '15px',
          paddingRight: '15px',
        },
      }}
      className="rounded-messageModal"
      bodyClass={clsx('pb-[10px] pt-[2px] mr-0', bodyClass)}
      // actionClass="flex h-[42px] border-0 border-t-[0.5px] border-solid border-artist-blue-900 p-0 bg-white overflow-x-hidden w-full justify-between"
      actionClass={ModalActionClass}
      dialogActions={dialogAction}
      {...rest}
    >
      <Box>{children}</Box>
    </CosBasDialog>
  ) : (
    <></>
  );
};

export default CosDataView;
