import moment from 'moment';
import { cosPadStart } from '../../libs/utils';
import { MeridiemType } from './MeridiemInput';

export interface TimeInputChangeRecord {
  hour12: string;
  hour24: string;
  minute: string;
  meridiem: MeridiemType;
  time12: string;
  time24: string;
  is24h: boolean;
  isValid: boolean;
}

export function getFilledArray<
  T extends (v: number, k: number) => unknown,
  Y extends ReturnType<T>,
>(range: number, mapfn: T): Y[] {
  return range <= 0 ? [] : (Array.from({ length: range }, mapfn) as Y[]);
}

export function updateIndex<T extends unknown[]>(
  array: T,
  indexItem: number,
  item: T[keyof T],
): T {
  return array.map((chipItem, index) => {
    return indexItem === index ? item : chipItem;
  }) as T;
}

export function joinArrayStrings(array: readonly string[]): string {
  return array.join('');
}

export function append<T extends unknown[]>(array: T, item: T[keyof T]): T {
  return [...array, item] as T;
}

export function set12hrFormate(
  hr: string,
  min: string,
  meridiem: MeridiemType,
): string {
  return `${cosPadStart(hr)}:${cosPadStart(min)} ${meridiem?.toUpperCase()}`.trim();
}

// export time formate

export const getTimeDetails = (
  time: string,
): Omit<TimeInputChangeRecord, 'isValid'> => {
  const formats = ['h:mm A', 'HH:mm', 'hh:mm A', 'h:mm a', 'hh:mm a'];
  const momentTime = moment(time, formats, true);
  if (momentTime.isValid()) {
    const hour12 = momentTime.format('hh');
    const hour24 = momentTime.format('HH');
    const minute = momentTime.format('mm');
    const meridiem = momentTime.format('A').toUpperCase() as MeridiemType;
    const is24h = moment(time, 'HH:mm', true).isValid();
    const time12 = momentTime.format('hh:mm A');
    const time24 = momentTime.format('HH:mm');

    return { hour12, hour24, minute, meridiem, is24h, time12, time24 };
  } else {
    throw new Error(
      `Time is not valid it should be in [h:mm A', 'HH:mm', 'hh:mm A', 'h:mm a', 'hh:mm a]`,
    );
  }
};
