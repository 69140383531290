import styled from '@emotion/styled';
import { SxProps, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import React, { CSSProperties, useState, useCallback, useRef } from 'react';
import { CosTheme } from '../libs/interface';
import { cn } from '../libs/utils';
import { log } from 'console';

interface CustomTooltipProps extends TooltipProps {
  color?: string;
  background?: string;
  textDecoration?: CSSProperties['textDecoration'];
  version?: CosTheme;
  yOffset?: number;
  xOffset?: number;
  onHoverIcon?: React.ReactNode;
  tooltipsx?: SxProps;
  wrapperClass?: string;
  tabIndex?: number;
  disabled?: boolean;
  onTriggerClick?: () => void;
  isInformative?: boolean;
  id?: string;
}

const BaseTooltip = styled(({ className, ...props }: CustomTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ color, version, background, textDecoration, tooltipsx }: any) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color:
      version === 'dark'
        ? '#001F38'
        : version === 'light'
          ? '#ffffff'
          : background,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    background:
      version === 'dark'
        ? '#001F38'
        : version === 'light'
          ? '#ffffff'
          : background,
    marginLeft: '0',
    fontSize: '11px',
    boxShadow:
      version === 'dark' ? '0px 0px 3px #07B9EC' : '0px 0px 3px #A8B1B8',
    lineHeight: '17px',
    color:
      version === 'dark' ? '#ffffff' : version === 'light' ? '#001F38' : color,
    '&:hover': {
      textDecoration: textDecoration,
    },
    height: '21px',
    minWidth: '55px',
    textAlign: 'center',
    borderRadius: '3px',
    padding: '0 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...(!!tooltipsx ? tooltipsx : {}),
  },

  //* TOP
  '&.MuiTooltip-popper[data-popper-placement*="top"]': {
    '& .MuiTooltip-tooltip': {
      marginBottom: '0px !important',
    },
    '& .MuiTooltip-arrow': {
      marginBottom: '-0.5em',
    },
  },

  //* right
  '&.MuiTooltip-popper[data-popper-placement*="right"]': {
    '& .MuiTooltip-tooltip': {
      marginLeft: '0px !important',
    },
    '& .MuiTooltip-arrow': {
      marginLeft: '-0.5em',
    },
  },

  //* left
  '&.MuiTooltip-popper[data-popper-placement*="left"]': {
    // '& .MuiTooltip-tooltip': {
    //   marginLeft: '-0.5px',
    // },
    // todo
    '& .MuiTooltip-tooltip': {
      marginRight: '0px !important',
    },
    '& .MuiTooltip-arrow': {
      marginRight: '-0.5em',
    },
  },

  //* Bottom
  '&.MuiTooltip-popper[data-popper-placement*="bottom"]': {
    // '& .MuiTooltip-tooltip': {
    //   marginBottom: '-0.5px',
    // },
    '& .MuiTooltip-arrow': {
      marginTop: '-0.5em',
    },
  },
}));

const Wrapper = React.forwardRef(function MyComponent(
  { children, className, placement, ...rest }: any,
  ref: any,
) {
  return (
    <span
      {...rest}
      ref={ref}
      className={cn('inline-flex select-none items-center', className)}
    >
      {children}
    </span>
  );
});

const CosTooltip: React.FC<CustomTooltipProps> = ({
  color,
  background,
  textDecoration,
  version = 'dark',
  children,
  xOffset = 0,
  yOffset,
  open,
  onHoverIcon,
  tooltipsx,
  wrapperClass,
  placement,
  tabIndex = 1,
  disabled,
  onTriggerClick,
  isInformative,
  enterDelay = 100,
  leaveDelay = 100,
  id,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(open || false);

  const openTimeoutRef = useRef<any>(null);
  const closeTimeoutRef = useRef<any>(null);

  const handleOpen = useCallback(() => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
    openTimeoutRef.current = setTimeout(() => {
      setIsOpen(true);
    }, enterDelay);
  }, []);

  const handleClose = useCallback(() => {
    if (openTimeoutRef.current) {
      clearTimeout(openTimeoutRef.current);
    }
    closeTimeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, leaveDelay);
  }, []);

  React.useEffect(() => {
    return () => {
      if (openTimeoutRef.current) {
        clearTimeout(openTimeoutRef.current);
      }
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
    };
  }, []);

  const sanitizeYOffset =
    placement === 'top'
      ? yOffset || 3.5
      : // : placement === 'right' || placement === 'left'
        placement === 'right'
        ? yOffset || 0
        : yOffset || -11;

  const getSanitizeYOffset = () => {
    if (yOffset) {
      return yOffset;
    } else {
      switch (placement) {
        case 'top':
        case 'right':
          return isInformative ? 5 : 3.5;
        case 'left':
          return 4;
        default:
          return -11;
      }
    }
  };

  return (
    <BaseTooltip
      arrow={!isInformative}
      open={isOpen}
      placement={placement}
      onClose={handleClose}
      onOpen={handleOpen}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [xOffset, getSanitizeYOffset()],
              },
            },
          ],
        },
      }}
      color={color}
      background={background}
      textDecoration={textDecoration}
      version={version}
      tooltipsx={tooltipsx}
      disableTouchListener={true}
      onMouseLeave={handleClose}
      onBlur={handleClose}
      {...rest}
    >
      <Wrapper
        className={cn(
          'outline-0',
          {
            'pointer-events-none  opacity-25': disabled,
          },
          wrapperClass,
        )}
        id={id}
        onBlur={handleClose}
        tabIndex={tabIndex}
        onClick={onTriggerClick}
        onMouseLeave={handleClose}
      >
        {isOpen && onHoverIcon ? onHoverIcon : children}
      </Wrapper>
    </BaseTooltip>
  );
};
export default CosTooltip;
