import React, { useState } from 'react';
import { CosBasDialog, PrimaryActionButtons } from '../components/dialogBox';
import { primaryActionClass } from '../components/dialogBox/PrimaryActionButtons';
import { CosInput } from '../components/formikForms/ui';
import StandardLayout from './StandardLayout';
import { CosButton } from '../components';

const StandardModalActionBtn = ({}) => {
  const [open, setIsOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleOpen = () => {
    setIsOpen(() => true);

    // reset the form
    handleCancel();
  };

  const handleSubmit = () => {
    setTimeout(() => {
      handleClose();
    });
  };

  const handleClose = () => {
    setIsOpen(() => false);
  };

  const handleCancel = () => {
    setIsDirty(() => false);
    setInputValue('');
  };

  return (
    <StandardLayout>
      <p className="mt-5"></p>
      <CosButton variant="contained" onClick={handleOpen}>
        Open Modal
      </CosButton>

      {open && (
        <CosBasDialog
          open={open}
          sx={{
            '.MuiPaper-root': {
              background: '#EDEEEF',
            },
          }}
          onEsc={() => (isDirty ? handleCancel() : handleClose())}
          onEnter={() => handleSubmit()}
          height="357px"
          width="385px"
          heading="Common Modal with keyboard"
          headingClass="text-left text-18/18 text-blue-gray-900 bg-white-whale-500 px-[15px] py-[10px]"
          bodyClass="bg-white-whale-500"
          actionClass={primaryActionClass}
          dialogActions={
            <PrimaryActionButtons
              showCancel={isDirty}
              onCancel={() => handleCancel()}
              onClose={() => handleClose()}
              disableApply={!isDirty}
              onApply={() => handleSubmit()}
            />
          }
        >
          <CosInput
            fullWidth
            placeholder="Enter a value"
            size="small"
            value={inputValue}
            onChange={event => {
              setIsDirty(true);
              setInputValue(event.target.value);
            }}
          />
        </CosBasDialog>
      )}
    </StandardLayout>
  );
};

export default StandardModalActionBtn;
