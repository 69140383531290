import React, { useEffect, useRef, useState } from 'react';
import { CosBasDialog } from './index';
import clsx from 'clsx';
import { CosBaseDialogProps } from './CosBasDialog';
import CosButton from '../CosButton';
import { KEYBOARD_KEY, constant } from '../../libs/constant';
import { Box, Divider, alpha, styled } from '@mui/material';
import {
  CosTable,
  CosTableContainer,
  CosTableRow,
  CosTableCell,
  CosTableHead,
  CosTableBody,
} from '../../components/Tables';
import CosTooltip from '../CosTooltip';
import { CosCheckbox } from '../formikForms/ui';
import {
  getFocusableFelid,
  moveTabToNextField,
  moveTabToPrevField,
} from '../../libs/helper';

interface Props extends CosBaseDialogProps {
  onClose?: () => void;
  contentBody?: React.ReactNode;
  children?: React.ReactNode;
}

const BaseTable = styled(CosTableContainer)(() => ({
  maxHeight: 'calc(100vh - 128px)',
  borderRadius: '0px',
  // paddingRight: '0px',
  // scrollbarGutter: 'unset',
  // '&::before': {
  //   width: 0,
  // },
  '&::after': {
    width: 0,
  },
  '& .MuiTableRow-root': {
    '& .MuiTableCell-head': {
      minHeight: '34px',
      height: '34px',
      position: 'sticky !important',
    },
    '& .MuiTableCell-head:not(:first-of-type)': {
      borderLeft: '0px !important',
    },
    '& .MuiTableCell-body': {
      borderLeft: '0px !important',
    },
    '& .MuiTableCell-body:last-of-type': {
      borderRight: '0px !important',
    },
    '& .MuiTableCell-root:last-of-type': {
      textAlign: 'right',
    },
  },
  '& .MuiTableRow-root:nth-of-type(even)': {
    background: '#EDEEEF !important',
    '& .MuiTableCell-body': {
      color: '#001F38 !important',
    },
  },
}));

function createData(id: number, holidayName: string, observationDay: string) {
  return {
    id,
    holidayName,
    observationDay,
  };
}

const rows = Array(19)
  .fill(1)
  .map((_item, index) => {
    return createData(index + 1, 'New Years', 'Always January 1st');
  });

const CosAddNew: React.FC<Props> = ({ open, onClose, children, ...rest }) => {
  const [checkboxTooltipText, setCheckboxTooltipText] = useState('Select All');
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const handleRowSelection = (id: number) => {
    const isSelected = selectedRows.includes(id);
    if (isSelected) {
      setSelectedRows(prev => prev.filter(rowId => rowId !== id));
    } else {
      setSelectedRows(prev => [...prev, id]);
    }
  };

  const handleSelectAll = () => {
    if (selectedRows.length === rows.length) {
      handleClear();
    } else {
      const allRowIds = rows.map(row => row.id);
      setSelectedRows(allRowIds);
      setCheckboxTooltipText('Unselect All');
    }
  };

  const handleClear = () => {
    setSelectedRows([]);
    setCheckboxTooltipText('Select All');
  };

  const handleClose = () => {
    onClose?.();
  };

  const isSelectAllChecked = selectedRows.length === rows.length;

  const handleKeyDown: React.KeyboardEventHandler<
    HTMLButtonElement | HTMLDivElement
  > = event => {
    const key = event.key;
    // const target = event.target as HTMLButtonElement | HTMLDivElement;
    if (KEYBOARD_KEY.tab === key) {
      // event.preventDefault();
      // event.stopPropagation();
      // const test = getFocusableFelid(tableRef.current as HTMLElement);
      // console.log('test', test);
      // moveTabToNextField(tableRef.current as HTMLElement);
    }

    if (KEYBOARD_KEY.esc === key) {
      if (selectedRows.length > 0) {
        handleClear();
      } else {
        handleClose();
      }
    }
  };

  const tableRef = useRef<HTMLTableElement>(null);

  return open ? (
    <CosBasDialog
      open={open}
      onKeyDown={handleKeyDown}
      height="393px"
      width="695px"
      sx={{
        '.MuiPaper-root': {
          borderRadius: constant.WorkScheduleModalRadius + 'px',
          background: '#EDEEEF',
          boxShadow: '0px 0px 12px #A8B1B8',
        },
      }}
      className="rounded"
      bodyClass={clsx('p-[10px] pr-0 bg-white mr-0 overflow-y-hidden')}
      actionClass="flex h-[44px] border-0 border-t-[0.5px] border-solid !border-artist-blue-900/50 p-0 bg-white !bg-opacity-50 overflow-x-hidden"
      dialogActions={
        <>
          <CosButton
            size="medium"
            variant="text"
            onClick={selectedRows.length == 0 ? handleClose : handleClear}
            color={selectedRows.length == 0 ? 'info' : 'secondary'}
            className="min-h-auto w-1/2"
            tabIndex={0}
          >
            {selectedRows.length == 0 ? 'Close' : 'Cancel'}
          </CosButton>
          <Divider
            className="m-0 bg-artist-blue-900 opacity-50"
            flexItem
            orientation="vertical"
          ></Divider>
          <CosButton
            size="medium"
            variant="text"
            onClick={handleClose}
            className="min-h-auto m-0 w-1/2"
            disabled={selectedRows.length == 0 ? true : false}
            tabIndex={0}
          >
            {selectedRows.length == 0
              ? 'Add & Close'
              : `Add (${selectedRows.length}) & Close`}
          </CosButton>
        </>
      }
      {...rest}
    >
      <Box ref={tableRef}>
        <BaseTable>
          <CosTable stickyHeader>
            <CosTableHead>
              <CosTableRow>
                <CosTableCell className="items-center">
                  <CosTooltip
                    title={checkboxTooltipText}
                    placement="top"
                    version="light"
                  >
                    <CosCheckbox
                      tabIndex={0}
                      checked={isSelectAllChecked}
                      onChange={handleSelectAll}
                      version="dark"
                      checkedColor="#001F38"
                      sx={{
                        'input:hover ~ .COS-Unchecked-box': {
                          backgroundColor: alpha('#ffffff', 1),
                        },

                        'input:hover ~.COS-checked-box': {
                          backgroundColor: alpha('#ffffff', 0.75),
                          borderColor: alpha('#ffffff', 0.75),
                        },

                        '.COS-checked-box': {
                          backgroundColor: alpha('#ffffff', 1),
                          borderColor: alpha('#ffffff', 1),
                        },
                      }}
                    />
                  </CosTooltip>
                  <span className="pl-2">Holiday Name</span>
                </CosTableCell>
                <CosTableCell>Observation Day</CosTableCell>
                <CosTableCell>
                  <CosTooltip
                    title={'Create New Holiday'}
                    placement="left"
                    className="text-dark-blue-900"
                  >
                    <CosButton
                      size="medium"
                      variant="contained"
                      onKeyDown={handleKeyDown}
                    >
                      Create New
                    </CosButton>
                  </CosTooltip>
                </CosTableCell>
              </CosTableRow>
            </CosTableHead>
            <CosTableBody>
              {rows.map(row => (
                <CosTableRow key={row.id}>
                  <CosTableCell className="flex items-center">
                    <CosCheckbox
                      withGrayBg
                      className="pr-2"
                      checked={selectedRows.includes(row.id)}
                      onChange={() => handleRowSelection(row.id)}
                      tabIndex={0}
                    />
                    <span className="pl-2">{row.holidayName}</span>
                  </CosTableCell>
                  <CosTableCell>{row.observationDay}</CosTableCell>
                  <CosTableCell>
                    <CosButton
                      size="small"
                      variant="outlined"
                      disabled={selectedRows.includes(row.id) ? false : true}
                      tabIndex={0}
                    >
                      Add
                    </CosButton>
                  </CosTableCell>
                </CosTableRow>
              ))}
            </CosTableBody>
          </CosTable>
        </BaseTable>
      </Box>
    </CosBasDialog>
  ) : (
    <></>
  );
};

export default CosAddNew;
