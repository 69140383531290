import { CosIcon } from '../components';
import StandardLayout from './StandardLayout';
import Done from '../assets/svgs/staticColorSvg/done-icon.svg';
import DoneFill from '../assets/svgs/staticColorSvg/done-fill-icon.svg';
import Export from '../assets/svgs/staticColorSvg/export-icon.svg';
import Import from '../assets/svgs/staticColorSvg/import-icon.svg';
import Edit from '../assets/svgs/staticColorSvg/edit-icon.svg';
import Delete from '../assets/svgs/staticColorSvg/Delete-icon.svg';
import OpenFile from '../assets/svgs/staticColorSvg/Open-Icon.svg';
import {
  Eye,
  EyeHide,
  HideEye,
  HideEyeHover,
  OutlineQuestion,
  Print,
  Question,
  QuestionDark,
  Plus,
  Minus,
  CloseUnfill,
  CloseHover,
  EditBlue,
  EditWhiteHover,
  EditWhite,
  ViewWhite,
  EditBlueHover,
  FilledQuestion,
  Calender,
  Clock,
  ExpandDouble,
  SaveFile,
  saveGrayHover,
  saveGray,
  saveDarkHover,
  saveDark,
} from '../assets/svgs';
import { alpha, styled } from '@mui/material';

const MainScreen = styled('div')(() => ({
  width: '336px',
  height: '40px',

  '& div': {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  '& div:hover': {
    background: alpha('#ffffff', 0.75),
  },
}));
const Divider = styled('hr')(({ theme }) => ({
  border: '1px solid #9da8af',
}));

const StandardActionBtn = () => (
  <StandardLayout>
    <main className="mt-5 space-y-6">
      <section className="flex space-x-8">
        <div className="flex space-x-3">
          <div>
            <p className="m-0 mb-2 text-12/17">Delete</p>
            <CosIcon onHoverIcon={<CloseHover />}>
              <CloseUnfill />
            </CosIcon>
          </div>

          <div>
            <p className="m-0 mb-2 text-12/17">Edit</p>
            <CosIcon onHoverIcon={<EditWhiteHover />}>
              <EditBlue />
            </CosIcon>
          </div>

          <div>
            <p className="m-0 mb-2 text-12/17">Done</p>
            <CosIcon onHoverIcon={<img src={DoneFill} />}>
              <img src={Done} />
            </CosIcon>
          </div>
        </div>

        <div className="ml-6">
          <p className="m-0 mb-2 text-12/17">View</p>
          <div className="flex">
            <CosIcon onHoverIcon={<HideEye className="mr-3 mt-2" />}>
              <Eye className="mr-3 mt-2" />
            </CosIcon>
            <div className="h-7 w-8 bg-artist-blue-900 text-center">
              <CosIcon onHoverIcon={<HideEye className="text-dark-blue-900" />}>
                <ViewWhite />
              </CosIcon>
            </div>
          </div>
        </div>

        <div>
          <p className="m-0 mb-2 text-12/17">Hide</p>
          <CosIcon
            onHoverIcon={<HideEyeHover className="text-dark-blue-900" />}
          >
            <EyeHide />
          </CosIcon>
        </div>

        <div>
          <p className="m-0 mb-2 text-12/17">Show</p>
          <CosIcon onHoverIcon={<HideEye className="text-dark-blue-900" />}>
            <Eye />
          </CosIcon>
        </div>

        <div>
          <p className="m-0 mb-2 text-12/17">What's Icon ?</p>
          <div className="flex items-center">
            <CosIcon
              onHoverIcon={<FilledQuestion className="text-dark-blue-900" />}
            >
              <OutlineQuestion />
            </CosIcon>
            <div className="ml-3 inline-block h-9 bg-dark-blue-900 p-2">
              <CosIcon onHoverIcon={<Question />}>
                <QuestionDark />
              </CosIcon>
            </div>
          </div>
        </div>

        <div>
          <p className="m-0 mb-2 text-12/17">Minus</p>
          <Minus className="zoom-icon" />
        </div>

        <div>
          <p className="m-0 mb-2 text-12/17">Plus</p>
          <Plus className="zoom-icon" />
        </div>

        <div>
          <p className="m-0 mb-2 text-12/17">Calender</p>
          <CosIcon onHoverIcon={<Calender className="text-buddha-love-800" />}>
            <Calender />
          </CosIcon>
        </div>

        <div>
          <p className="m-0 mb-2 text-12/17">Clock</p>
          <CosIcon onHoverIcon={<Clock className="text-buddha-love-800" />}>
            <Clock />
          </CosIcon>
        </div>

        <div>
          <p className="m-0 mb-2 text-12/17">Expand</p>
          <CosIcon
            onHoverIcon={<ExpandDouble className="text-buddha-love-800" />}
          >
            <ExpandDouble />
          </CosIcon>
        </div>
      </section>

      <section className="flex space-x-4">
        <div>
          <p className="m-0 mb-2 text-12/17">On Grey Row Hover</p>
          <div className="flex h-[25px] w-[132px] gap-3 bg-white-whale-500 pl-4 text-center">
            <CosIcon onHoverIcon={<HideEye className="text-artist-blue-900" />}>
              <Eye />
            </CosIcon>
            <CosIcon
              onHoverIcon={
                <EditBlueHover
                  className="pt-[2px]"
                  style={{ width: '15.667', height: '15.667' }}
                />
              }
            >
              <EditBlue className="pt-[2px]" />
            </CosIcon>
            <CosIcon onHoverIcon={<img src={saveGrayHover} />}>
              <img src={saveGray} />
            </CosIcon>
            <CosIcon onHoverIcon={<CloseHover className="pt-[1px]" />}>
              <CloseUnfill className="pt-[1px]" />
            </CosIcon>
          </div>
        </div>
        <div>
          <p className="m-0 mb-2 text-12/17">On Dark Row Hover</p>
          <div className="flex h-[25px] w-[132px] gap-3 bg-artist-blue-900 pl-4 text-center">
            <CosIcon onHoverIcon={<HideEye className="text-dark-blue-900" />}>
              <ViewWhite style={{ width: '19' }} />
            </CosIcon>
            <CosIcon
              onHoverIcon={
                <EditWhiteHover
                  className="pt-[2px]"
                  style={{ width: '15.667', height: '15.667' }}
                />
              }
            >
              <EditWhite className="pt-[2px]" />
            </CosIcon>
            <CosIcon onHoverIcon={<img src={saveDarkHover} />}>
              <img src={saveDark} />
            </CosIcon>
            <CosIcon onHoverIcon={<CloseHover className="pt-[1px]" />}>
              <CloseUnfill className="pt-[1px]" />
            </CosIcon>
          </div>
        </div>
      </section>

      <section>
        <p className="m-0 mb-2">Main Screen</p>
        <MainScreen className="main-screen flex bg-white-whale-500">
          <div className="ml-6 px-1">
            <CosIcon wrapperClass={'inline-flex'}>
              <img src={OpenFile} />
            </CosIcon>
            <p className="m-0 text-12/17">Open</p>
          </div>
          <div className="px-1">
            <CosIcon>
              <img src={Export} />
            </CosIcon>
            <p className="m-0 text-12/17">Export</p>
          </div>
          <div className="px-1">
            <CosIcon>
              <img src={Import} />
            </CosIcon>
            <p className="m-0 text-12/17">Import</p>
          </div>
          <div className="px-1">
            <CosIcon>
              <Print />
            </CosIcon>
            <p className="m-0 text-12/17">Print</p>
          </div>
          <Divider className="mx-1"></Divider>
          <div className="px-1">
            <CosIcon>
              <img src={Edit} />
            </CosIcon>
            <p className="m-0 text-12/17">Edit</p>
          </div>
          <div className="px-1">
            <EyeHide />
            <p className="m-0 text-12/17">Inactivate</p>
          </div>
          <div className="mr-2 px-1">
            <CosIcon>
              <img src={Delete} />
            </CosIcon>
            <p className="m-0 text-12/17 text-error-900">Delete</p>
          </div>
        </MainScreen>
      </section>
    </main>
  </StandardLayout>
);

export default StandardActionBtn;
