import { Box, Divider, alpha, styled } from '@mui/material';
import { CosButton, CosTooltip } from '..';
import { CosAutoComplete } from '../formikForms/ui';
import { convertNumberToWords } from './numberToWords.util';
import { cn } from '../../libs/utils';

const BpBox = styled(Box)(({}) => ({
  width: '253px',
  height: 'calc(100vh - 0px)',
  '& .project-list': {
    height: 'calc(100vh - 114px)',
  },
}));
interface SortingTabs {
  label: string;
  value: string;
}
interface Props {
  listingToggle?: () => void;
  contextTab: SortingTabs[];
  TabOptions: SortingTabs[];
}
const CosAssignTab: React.FC<Props> = ({
  contextTab,
  listingToggle,
  TabOptions,
}) => {
  const labelProps = {
    className: '!text-artist-blue-900 font-semibold text-12/18',
  };

  return (
    <Box className="transition-all">
      <BpBox className="bg-dark-blue-900">
        <Box className="flex justify-between px-[11px] pb-[20px] pt-[11px]">
          <span className="text-16/18 font-semibold text-white">
            Tab Assignments
          </span>
          <CosTooltip
            title={'Add New Tab'}
            placement="right"
            className="text-dark-blue-900"
          >
            <CosButton size="small">Add New</CosButton>
          </CosTooltip>
        </Box>
        <Box>
          <Box className="project-list overflow-y-auto pl-[11px]">
            {contextTab.map((x, i) => (
              <CosAutoComplete
                placeholder="Unassigned"
                options={TabOptions}
                size="small"
                isRequired={false}
                key={i}
                label={`Tab ${convertNumberToWords(i + 1)}`}
                labelProps={labelProps}
                value={
                  TabOptions.find((item: any) => item.label == x.label) && x
                }
                withElevation={false}
                arrowType="expand"
                inputSx={{
                  minWidth: '234px',
                  width: '234px',
                  height: '28px',
                  boxShadow: '0px 0px 3px #0000000F',
                  border: `1px solid ${alpha('#E5E5E5', 1)}`,
                }}
              />
            ))}
          </Box>
          <Box className="absolute bottom-0 flex h-[44px] w-[253px] overflow-hidden border-0 border-t-[0.5px] border-solid border-artist-blue-900 p-0">
            <CosButton
              size="medium"
              variant="text"
              className="min-h-auto w-1/2 text-12/17"
              tabIndex={0}
              onClick={listingToggle}
            >
              Close
            </CosButton>
            <Divider
              className="opacity-1 m-0 bg-artist-blue-900"
              flexItem
              orientation="vertical"
            ></Divider>
            <CosButton
              size="medium"
              variant="text"
              className="min-h-auto m-0 w-1/2 text-12/17"
              tabIndex={0}
            >
              Apply & Close
            </CosButton>
          </Box>
        </Box>
      </BpBox>
    </Box>
  );
};

export default CosAssignTab;
