import { CustomError } from './interface';
import { FocusableElement, tabbable } from 'tabbable';
import { camelCaseToTitleCase, cosPadStart } from './utils';
import {
  ObjectSchema,
  AnyObjectSchema,
  SchemaFieldDescription,
  SchemaDescription,
} from 'yup';

export const validateUserPassword = (value: string): string => {
  const errors: CustomError[] = [
    {
      message: 'Enter between 8-16 characters.',
      isValid: 8 <= value.length && 16 >= value.length,
    },

    {
      message: 'Include at least one uppercase letter.',
      isValid: /(?=.*[A-Z])/.test(value),
    },
    {
      message: 'Include at least one special character.',
      isValid: /(?=.*[!@#$%^&*])/.test(value),
    },
    {
      message: 'Include at least one number.',
      isValid: /(?=.*\d)/.test(value),
    },
  ];
  return JSON.stringify(errors);
};

export const validateUserEmail = (value: string): string => {
  const errors: CustomError[] = [
    {
      message: 'Email Required.',
      isValid: /\S+@\S+\.\S+/.test(value), // Basic email format check
    },
  ];
  return JSON.stringify(errors);
};

export const validatePassword = (value: string): string => {
  const errors: CustomError[] = [
    {
      message: 'Password Required.',
      isValid: [
        value.length >= 8,
        /[A-Z]/.test(value),
        /[!@#$%^&*]/.test(value),
      ].every(condition => condition),
    },
  ];

  return JSON.stringify(errors);
};
const validateUserName = (username: string): boolean => {
  // Define your username pattern using a regular expression
  const usernamePattern = /^[a-zA-Z0-9_]{3,20}$/;

  // Test if the provided username matches the pattern
  return usernamePattern.test(username);
};

export const validateEmailOrUsername = (value: string): string => {
  let isEmailValid = false;
  let isUsernameValid = false;

  if (/\S+@\S+\.\S+/.test(value)) {
    isEmailValid = true;
  } else {
    isUsernameValid = validateUserName(value);
  }

  const errors: CustomError[] = [
    {
      message: isEmailValid
        ? 'Email is Valid'
        : isUsernameValid
          ? 'Username is Valid'
          : 'Username is Required',
      // Invalid Email or Username
      isValid: isEmailValid || isUsernameValid,
    },
  ];

  return JSON.stringify(errors);
};

export const getFocusableFelid = (
  ref?: HTMLElement,
): { focusableFelid: FocusableElement[] } => {
  // * Get all focusable felid
  const collection = ref ? ref : document;
  const focusable = tabbable(collection as any);

  //* Filter out elements with data-testid equal to 'sentinelStart' or 'sentinelEnd' which use for focus-trap
  const filteredFocusable = focusable.filter(element => {
    const testId = element.getAttribute('data-testid');
    return testId !== 'sentinelStart' && testId !== 'sentinelEnd';
  });

  return { focusableFelid: filteredFocusable };
};

// * For Move cursor to next felid
export const moveTabToNextField = (ref?: HTMLElement): void => {
  const { focusableFelid } = getFocusableFelid(ref);
  const currentElement: any = document.activeElement;
  const currentElementIndex = focusableFelid.findIndex(
    element => element === currentElement,
  );

  // * Move Next focus to next focusable field
  if (focusableFelid.length - 1 === currentElementIndex) {
    focusableFelid[0]?.focus();
  } else {
    focusableFelid[currentElementIndex + 1]?.focus();
  }
};

// * For Move cursor to Prev felid
export const moveTabToPrevField = (ref?: HTMLElement): void => {
  const { focusableFelid } = getFocusableFelid(ref);
  const currentElement: any = document.activeElement;
  const currentElementIndex = focusableFelid.findIndex(
    element => element === currentElement,
  );

  // * Move Next focus to next focusable field
  if (currentElementIndex === 0) {
    focusableFelid[0]?.focus();
  } else {
    focusableFelid[currentElementIndex - 1]?.focus();
  }
};

interface ValidateNumberProps {
  value: string;
  max: number;
  min?: number;
  extraValidation?: () => boolean;
  repeatedInLoop?: boolean;
}

// * Enter number with max validation
export const validateNumberValue = ({
  value,
  max,
  min = 0,
  extraValidation,
  repeatedInLoop,
}: ValidateNumberProps): string | undefined => {
  const validation = !!extraValidation ? extraValidation() : true;
  if (+value >= min) {
    if (value === '0' || value === '') {
      //in -> '00'
      return value; //op => 0 pr ''
    } else if (+value <= max && validation) {
      // If the new value is a number and less than or equal to the maximum allowed value
      if (+value < 10) {
        // If the number is less than 10, pad it with leading zeros  in -> (1)
        return cosPadStart(value); //op -> (01)
      } else {
        // If the number is greater than or equal to 10
        if (value.startsWith('0')) {
          // If it starts with a zero, remove the leading zero in -> (012)
          return value.slice(1); //op -> (12)
        } else {
          // Otherwise, update the state with the new value
          return value;
        }
      }
    } else if (+value > max && repeatedInLoop) {
      return cosPadStart(min);
    }
  } else if (+value < min && repeatedInLoop) {
    return cosPadStart(max);
  }
};

export interface ConvertTimeFormatProps {
  value: string;
  hours: string;
  min: string;
}

// * Convert 12h to 24h  ()
export const convert12hrTo24hr = (time12hr: string): ConvertTimeFormatProps => {
  // Extracting hours, minutes, and period (AM/PM) from the input time
  const [time, period] = time12hr.split(' ');
  const [hourStr, minuteStr] = time.split(':');
  const hour = parseInt(hourStr);
  const minute = parseInt(minuteStr);

  // Adjusting the hour based on AM/PM
  let hour24 = hour;
  if (period === 'PM' && hour !== 12) {
    hour24 += 12;
  } else if (period === 'AM' && hour === 12) {
    hour24 = 0;
  }

  // Formatting hour24 and minute to ensure two digits
  const hour24Str = hour24.toString().padStart(2, '0');
  const minuteStr24 = minute.toString().padStart(2, '0');

  // Returning the time in 24-hour format
  return {
    value: `${cosPadStart(hour24Str)}:${cosPadStart(minuteStr24)}`,
    hours: cosPadStart(hour24Str),
    min: cosPadStart(minuteStr24),
  };
};

// * Convert 24h to 12h  ()
export const convert24hrTo12hr = (time24hr: string): ConvertTimeFormatProps => {
  // Extracting hours and minutes from the input time
  const [hourStr, minuteStr] = time24hr.split(':');
  let hour = parseInt(hourStr);
  const minute = parseInt(minuteStr);

  // Determining the period (AM/PM) and adjusting the hour
  const period = hour >= 12 ? 'PM' : 'AM';
  if (hour === 0) {
    hour = 12;
  } else if (hour > 12) {
    hour -= 12;
  }

  // Formatting hour and minute to ensure two digits
  const hour12Str = hour.toString().padStart(2, '0');
  const minuteStr12 = minute.toString().padStart(2, '0');

  // Returning the time in 12-hour format
  return {
    value: `${hour12Str}:${minuteStr12} ${period}`,
    hours: hour12Str,
    min: minuteStr12,
  };
};

// // * Disable outside click from target element
// function disableOutsideClick(targetDiv: HTMLElement): void {
//   function handleClick(event) {
//       // Check if the clicked element is the target div or a descendant of it
//       if (!targetDiv.contains(event.target)) {
//           // Prevent default action of the click event
//           event.preventDefault();
//           event.stopPropagation();
//       }
//   }

//   // Add event listener to the document body
//   document.body.addEventListener('click', handleClick);
// }

export type FieldObject = {
  [key: string]: {
    name: string;
    label: string;
    required: boolean;
  };
};

// Type guard to check if a field description is a SchemaDescription
const isSchemaDescription = (
  description: SchemaFieldDescription,
): description is SchemaDescription => {
  return (description as SchemaDescription).tests !== undefined;
};

// Function to check if a field is required using schema description
const isFieldRequired = (fieldDescription: SchemaDescription): boolean => {
  return fieldDescription.tests.some(test => test.name === 'required');
};

/**
 * @description to generate FieldObject from schema
 * @param yup validationSchema
 * @returns FieldObject of validationSchema
 */
export const getRequiredFieldFromSchema = (
  validationSchema: ObjectSchema<any> | AnyObjectSchema,
): FieldObject => {
  const schemaFields = validationSchema.fields;
  const fieldObject = Object.keys(schemaFields).reduce((acc, key) => {
    const field = schemaFields[key];
    const fieldDescription = field.describe();

    if (isSchemaDescription(fieldDescription)) {
      acc[key] = {
        label: camelCaseToTitleCase(key),
        name: key,
        required: isFieldRequired(fieldDescription),
      };
    } else {
      acc[key] = {
        name: key,
        label: camelCaseToTitleCase(key),
        required: false, // Default to false if the field is a reference
      };
    }
    return acc;
  }, {} as FieldObject);

  return fieldObject;
};
