import React from 'react';
import CosSearchInput from '../components/formikForms/ui/CosSearchInput';
import StandardLayout from './StandardLayout';
import { CosGlobalSearchBar } from '../components/dialogBox';

const StandardSearchBar = () => {
  return (
    <StandardLayout>
      <section className="h-[120px] w-full bg-dark-blue-900 p-2">
        <div>
          <CosGlobalSearchBar placeholder="Search %Company%"></CosGlobalSearchBar>
        </div>
        <div className="text-right">
          <CosSearchInput
            placeholder="Search %Company%"
            disableFocusElevation
          />
        </div>
      </section>
    </StandardLayout>
  );
};

export default StandardSearchBar;
