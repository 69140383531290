import React, { useState } from 'react';
import { CosButton, CosTooltip, FloatingAddButton } from '../components';
import {
  Eye,
  EyeHide,
  FilledQuestion,
  FilledStar,
  HideEye,
  HideEyeHover,
  OutlineQuestion,
  OutlineStar,
  Question,
  QuestionDark,
} from '../assets/svgs';
import StandardLayout from './StandardLayout';
import { CosCheckbox } from '../components/formikForms/ui';
import { alpha } from '@mui/material';

const StandardPageTooltip = () => {
  const [selectAll, setSelectAll] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  return (
    <StandardLayout>
      <main className="mt-5 space-y-6">
        <div className="space-x-24">
          <CosTooltip
            title={'Hide'}
            placement="top"
            onHoverIcon={<HideEye className="text-dark-blue-900" />}
          >
            <Eye />
          </CosTooltip>

          <CosTooltip
            title={'Show'}
            placement="top"
            onHoverIcon={<HideEyeHover className="text-dark-blue-900" />}
            yOffset={2}
          >
            <EyeHide />
          </CosTooltip>

          <CosTooltip
            title={'%Text Here%'}
            textDecoration="underline"
            placement="right"
            onHoverIcon={<FilledQuestion className="text-dark-blue-900" />}
            yOffset={2}
          >
            <OutlineQuestion />
          </CosTooltip>

          <CosTooltip
            title={'%Text Here%'}
            textDecoration="underline"
            placement="right"
            tabIndex={-1}
          >
            <CosButton customSize="extraSmall" tabIndex={1}>
              Save As
            </CosButton>
          </CosTooltip>

          <div className="inline-block h-9 w-32 bg-dark-blue-900 p-2">
            <CosTooltip
              title={'%Text Here%'}
              textDecoration="underline"
              placement="right"
              version="light"
              onHoverIcon={<Question />}
              yOffset={2}
            >
              <QuestionDark />
            </CosTooltip>
          </div>

          <div className="inline-block h-9 w-8 bg-artist-blue-900 p-2 text-white">
            <CosTooltip
              title={'Add to Favorites'}
              placement="top"
              onHoverIcon={<FilledStar />}
              yOffset={5}
            >
              <OutlineStar />
            </CosTooltip>
          </div>
        </div>

        <section>
          <p>Actions</p>
          <div className="space-x-2 space-y-2 bg-white-whale-500 pl-4">
            <CosTooltip
              title={'View'}
              placement="left"
              onHoverIcon={<HideEye className="text-artist-blue-900" />}
            >
              <Eye />
            </CosTooltip>
          </div>

          <div className="space-x-2 bg-artist-blue-900 pl-4">
            <CosTooltip
              title={'View'}
              placement="left"
              onHoverIcon={<HideEye className="text-dark-blue-900" />}
            >
              <Eye className="text-white" />
            </CosTooltip>
          </div>
        </section>

        <section>
          <FloatingAddButton />
        </section>

        <section>
          <p>CheckBox</p>
          <CosTooltip
            title={selectAll ? 'Unselect All' : 'Select All'}
            placement="top"
          >
            <CosCheckbox
              onChange={event => setSelectAll(event.target.checked)}
            />
          </CosTooltip>
          <div className="mt-8 h-9 w-32 items-center bg-dark-blue-900 p-2">
            <CosTooltip
              title={isChecked ? 'Unselect All' : 'Select All'}
              placement="top"
              version="light"
            >
              <CosCheckbox
                checked={isChecked}
                onChange={() => setIsChecked(prevCheck => !prevCheck)}
                checkedColor="#001F38"
                sx={{
                  'input:hover ~ .COS-Unchecked-box': {
                    backgroundColor: alpha('#ffffff', 1),
                  },

                  'input:hover ~ .COS-checked-box': {
                    backgroundColor: alpha('#ffffff', 0.75),
                    borderColor: alpha('#ffffff', 0.75),
                  },

                  '.COS-checked-box': {
                    backgroundColor: alpha('#ffffff', 1),
                    borderColor: alpha('#ffffff', 1),
                  },
                }}
              />
            </CosTooltip>
          </div>
        </section>
      </main>
    </StandardLayout>
  );
};

export default StandardPageTooltip;
