import { alpha, Box, styled } from '@mui/material';
import { SortIcon } from '../../assets/svgs';
import CosButton from '../CosButton';
import { CosHorizontalTab } from '../Tabs';
import { cn } from '../../libs/utils';

const ShortingTab = styled(CosHorizontalTab)(({}) => ({
  padding: '0px !important',
  display: 'flex',
  alignItems: 'center',

  '& .MuiTabs-root': {
    width: '200px',
  },
  '& .MuiTab-root': {
    minWidth: '52px',
    minHeight: '16px',
    height: '16px',
    borderRadius: '25px',
    fontSize: '9px',
    fontWeight: '600',
    borderColor: '#EDEEEF',
    backgroundColor: 'transparent',
    color: '#FFFFFF',
    lineHeight: '0px',

    '&:hover': {
      border: `1px solid ${alpha('#07B9EC', 1)}`,
      color: '#07B9EC',
      backgroundColor: 'transparent',
    },
  },
  '& .MuiTabScrollButton-root': {
    width: '12px',
  },
}));
const BpBox = styled(Box)(({}) => ({
  width: '253px',
  height: 'calc(100vh - 0px)',
  '& .black-list': {
    height: 'calc(100vh - 114px)',
  },
}));
const HorizontalTabs = styled(CosHorizontalTab)(({}) => ({
  padding: '0px !important',
  display: 'flex !important',
  '& .MuiTab-root': {
    backgroundColor: '#A8B1B8',
  },
}));
interface SortingTabs {
  label: string;
  value: string;
}
interface Props {
  open?: boolean;
  sortingTabs: SortingTabs[];
  threeTabs: SortingTabs[];
  isProjectList?: boolean;
}
const CosBlankList: React.FC<Props> = ({
  sortingTabs,
  threeTabs,
  open,
  isProjectList,
}) => {
  return (
    <BpBox className="bg-dark-blue-900">
      <HorizontalTabs tabs={threeTabs} />
      <Box className="Sorting flex px-1 py-2">
        <div className="flex w-[86px] items-center justify-center pb-[4px]">
          <SortIcon className="mr-[4px] font-semibold text-white" />
          <span className="text-10/10 font-semibold text-white">Sort By</span>
        </div>

        <ShortingTab tabs={sortingTabs} />
      </Box>
      <Box className="black-list mr-[3px] overflow-y-auto">
        {[...Array(10)].map((x, i) => (
          <Box
            key={i}
            className="m-auto mb-[5px] h-[55px] w-[233px] rounded-[5px] bg-white"
          ></Box>
        ))}
      </Box>
      <Box className="pt-[12px] text-center">
        <CosButton size="small">Open Main Project List</CosButton>
      </Box>
    </BpBox>
  );
};

export default CosBlankList;
