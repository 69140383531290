// import { useState } from 'react';
// import PopupState, { Props } from 'material-ui-popup-state';
// import { Box } from '@mui/material';

// const CosPopUpState: React.FC<any> = ({ children }) => {
//   const [open, setOpen] = useState(false);

//   const onOpen = () => setOpen(true);
//   const onClose = () => setOpen(false);

//   return <Box className="relative">{children({ onOpen, onClose, open })}</Box>;
// };

// export default CosPopUpState;

import React, { useState } from 'react';
import { Box } from '@mui/material';

interface CosPopUpStateProps {
  children: (props: {
    onOpen: () => void;
    onClose: () => void;
    toggle: () => void;
    isOpen: boolean;
  }) => React.ReactNode;
}

const CosPopUpState: React.FC<CosPopUpStateProps> = ({ children }) => {
  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  const toggle = () => setOpen(preValue => !preValue);

  return (
    <Box className="relative">
      {children({
        onOpen,
        onClose,
        toggle,
        isOpen: open,
      })}
    </Box>
  );
};

export default CosPopUpState;
