import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import 'react-image-crop/dist/ReactCrop.css';
import './index.css';
import './scrollbar.css';
import './svg.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './extensions/string.extensions';
import { Provider } from 'react-redux';
import { store } from './app/store';
import './override.css';
import { LicenseInfo } from '@mui/x-license-pro';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);
const muiBaseKey: string =
  process.env.REACT_APP_MUI_LICENSE_KEY ?? 'default-license-key';
LicenseInfo.setLicenseKey(muiBaseKey);
// All `Portal`-related components need to have the the main app wrapper element as a container
// so that the are in the subtree under the element used in the `important` option of the Tailwind's config.
const theme = createTheme({
  typography: {
    fontFamily: ['"Open Sans", sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#001F38', //*dark-blue
    },
    secondary: {
      main: '#FFB92C', //* buddha-love-800
    },
    info: {
      main: '#07B9EC', //* artist-blue-900
    },
    error: {
      main: '#eb5050', //* error-900
    },
    success: {
      main: '#28A745', //* eucalyptus-green-900
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#001F38',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiModal: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiMenu: {
      defaultProps: {
        // disableAutoFocus: true,
        // disableAutoFocusItem: true,
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '30px',
          right: '4px',
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          borderWidth: 0,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          borderWidth: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabScrollButton-root:hover .MuiSvgIcon-root': {
            transform: 'scale(1.15)',
          },
        },
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          // position: 'relative',
          zIndex: 1,
          '& .MuiTableCell-head': {
            background: '#001F38',
            fontWeight: 600,
            textTransform: 'uppercase',
            color: '#ffffff',
            borderColor: '#ffffff',
            padding: '2px 9px',
            maxHeight: 40,
            minHeight: 40,
            height: 40,
            fontSize: 12,
          },
          '& .MuiTableRow-root': {
            '& .MuiTableCell-head:not(:first-of-type)': {
              borderLeft: '2px solid #ffffff',
            },
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root:nth-of-type(odd)': {
            background: '#EDEEEF',
            '& .MuiTableCell-body': {
              color: '#001F38',
            },
          },
          '& .MuiTableRow-root:nth-of-type(even)': {
            background: '#07B9EC',
            '& .MuiTableCell-body': {
              color: '#FFFFFF',
            },
          },

          '& .MuiTableRow-root': {
            '& .MuiTableCell-body ': {
              borderLeft: '2px solid #ffffff',
            },
            '& .MuiTableCell-body:last-of-type': {
              borderRight: '2px solid #ffffff',
            },
          },
          '& .MuiTableCell-body': {
            borderBottom: '2px solid #ffffff',
            padding: '0px 9px',
            height: 25,
            fontSize: 12,
            fontWeight: 400,
          },

          '& .MuiTableRow-root:last-child td, .MuiTableRow-root:last-child th':
            {
              borderBottomWidth: '2px',
            },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 4px #00000017',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          overflowX: 'unset',
          paddingRight: '2px',
          scrollbarGutter: 'stable',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          lineHeight: 'normal',
          '&.CosDeployment-cell': {
            width: '137px',
            minWidth: '137px',
            maxWidth: '137px',
          },
          '&.CosAction-cell': {
            width: '70px',
            minWidth: '70px',
            maxWidth: '70px',
          },
        },
      },
    },
  },
  transitions: {
    duration: {
      enteringScreen: 300,
      leavingScreen: 300,
    },
  },
});

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
