import { Box, alpha } from '@mui/material';
import { ArrowBtnProps } from './arrow-interface';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import clsx from 'clsx';

const NextPrevRoundBtn: React.FC<ArrowBtnProps> = ({
  open,
  onClick,
  version = 'light',
  disable,
}) => {
  return (
    <Box
      sx={{
        width: '32px',
        height: '32px',
        border: `1px solid ${alpha('#07B9EC', 0.25)}`,
        borderRadius: '99px',
        color: '#07B9EC',
        opacity: disable ? 0.25 : 1,
        cursor: disable ? 'unset' : 'pointer',
        backgroundColor:
          version === 'light' ? 'transparent' : alpha('#07B9EC', 0.7),
        '&:hover': {
          background: disable ? '' : '#07B9EC',
          color: disable ? '' : '#ffffff',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={onClick}
    >
      <KeyboardBackspaceIcon className={clsx({ 'rotate-180': !open })} />
    </Box>
  );
};

export default NextPrevRoundBtn;
