import StandardLayout from './StandardLayout';
import CosClockPicker from '../components/time/picker/CosClockPicker';

const StandardClock = () => {
  return (
    <StandardLayout>
      <p className="mt-5"></p>
      <CosClockPicker
        onClose={() => {}}
        defaultValue={'4:00 am'}
        onApply={value => console.log('value', value)}
      />
      <br />
      <br />
      <CosClockPicker
        onClose={() => {}}
        // defaultValue={''}
        onApply={value => console.log('value', value)}
        is24Hr={true}
      />

      <br />
      <br />
      {/* <CosClockPicker
        onClose={() => {}}
        defaultValue={'12:00'}
        onApply={value => console.log('value', value)}
        is24Hr={true}
      /> */}
    </StandardLayout>
  );
};

export default StandardClock;
