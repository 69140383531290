import { styled } from '@mui/material';
import { NextPrevRoundBtn } from '../arrow';
import { useEffect, useState } from 'react';

export interface PaginationProps {
  heading?: string;
  currentPage?: number;
  totalPage?: number;
  disableApply?: boolean;
  handlePagination?: (page: number) => void;
}
const HoverWrapper = styled('div')<
  PaginationProps & { disableApply?: boolean }
>(({ disableApply = false }) => ({
  '& .btnMain': {
    position: 'relative',
    '& .MuiBox-root': {
      pointerEvents: disableApply ? 'none' : 'unset',
    },
    '&:hover': {
      '& ~ .hover-text': {
        display: disableApply ? 'none' : 'block',
      },
    },
    '& .Mui-disabled': {
      pointerEvents: 'none',
    },
  },
  '& .nextBtn .MuiBox-root': {
    position: 'absolute',
    right: 0,
  },
}));

const HoverText = styled('span')({
  display: 'none',
});

const ModalPagination: React.FC<PaginationProps> = ({
  heading,
  disableApply = false,
  currentPage = 1,
  totalPage = 1,
  handlePagination = () => {},
}) => {
  const onPageChange = (direction: 'increment' | 'decrement') => {
    if (direction === 'decrement' && currentPage > 1) {
      handlePagination(currentPage - 1);
    } else if (direction === 'increment' && currentPage < totalPage) {
      handlePagination(currentPage + 1);
    }
  };
  return (
    <div className="flex w-full">
      <HoverWrapper className="flex w-[90px] items-center">
        <div className="btnMain">
          {disableApply || currentPage === 1 ? (
            <NextPrevRoundBtn open={true} disable />
          ) : (
            <NextPrevRoundBtn
              open={true}
              onClick={() => onPageChange('decrement')}
            />
          )}
        </div>
        {currentPage > 1 && (
          <HoverText className="hover-text pl-1 text-12/10 font-semibold text-blue-gray-100">
            Previous
          </HoverText>
        )}
      </HoverWrapper>
      <div className="inline-grid flex-grow text-center">
        {heading}
        <span className="text-12/10 text-artist-blue-900">
          {currentPage} of {totalPage}
        </span>
      </div>
      <HoverWrapper className="nextBtn relative flex w-[65px] items-center">
        <div className="btnMain absolute right-0">
          {disableApply || currentPage === totalPage ? (
            <NextPrevRoundBtn disable />
          ) : (
            <NextPrevRoundBtn onClick={() => onPageChange('increment')} />
          )}
        </div>
        {currentPage < totalPage && (
          <HoverText className="hover-text pr-1 text-12/10 font-semibold text-blue-gray-100">
            Next
          </HoverText>
        )}
      </HoverWrapper>
    </div>
  );
};

export default ModalPagination;
