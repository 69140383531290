import React from 'react';
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { cn } from '../libs/utils';
import StandardDashboard, { StandardDashboardProps } from './StandardDashboard';

const router = [
  {
    label: 'Buttons (Action)',
    link: '/standard/button',
  },
  {
    label: 'Checkbox',
    link: '/standard/checkbox',
  },
  {
    label: 'Radio',
    link: '/standard/radio',
  },
  {
    label: 'Tooltip',
    link: '/standard/tooltip',
  },
  {
    label: 'Toggle',
    link: '/standard/switch',
  },
  {
    label: 'Arrow',
    link: '/standard/arrow',
  },
  {
    label: 'Scrollbar',
    link: '/standard/scrollbar',
  },
  {
    label: 'Page Content Scrollbar',
    link: '/standard/page_content_with_scrollbar',
  },
  {
    label: 'Horizontal Scrollbar',
    link: '/standard/horizontal_scrollbar',
  },
  {
    label: 'Text Fields',
    link: '/standard/text_fields',
  },
  {
    label: 'Number Field',
    link: '/standard/number_fields',
  },
  {
    label: 'Dropdown',
    link: '/standard/dropdown',
  },
  {
    label: 'Horizontal Tabs',
    link: '/standard/horizontal_tab',
  },
  {
    label: 'Vertical Tabs',
    link: '/standard/vertical_tab',
  },
  {
    label: 'Popup',
    link: '/standard/popup',
  },
  {
    label: 'Modal Action Button',
    link: '/standard/modalActionBtn',
  },
  {
    label: 'Clock',
    link: '/standard/clock',
  },
  // {
  //   label: 'Calendar',
  //   link: '/standard/calendar',
  // },
  {
    label: 'Time Field',
    link: '/standard/timeInput',
  },
  {
    label: 'Icon',
    link: '/standard/icon',
  },
  {
    label: 'Search Bar',
    link: '/standard/searchBar',
  },
  {
    label: 'Data Table',
    link: '/standard/data-table',
  },
  {
    label: 'Menus',
    link: '/standard/menus',
  },
  {
    label: 'Top Header',
    link: '/standard/top-header',
  },
  {
    label: 'SignIn / SignUp Header',
    link: '/standard/signup-header',
  },
  {
    label: 'Right side panel',
    link: '/standard/right-panel',
  },
  {
    label: 'Left side panel',
    link: '/standard/left-panel',
  },
  {
    label: 'Page Footer',
    link: '/standard/page-footer',
  },
  // {
  //   label: 'Pagination',
  //   link: '/standard/pagination',
  // },
];

const StandardLayout: React.FC<
  StandardDashboardProps & { hideRout?: boolean }
> = ({ children, hideRout, ...rest }) => {
  return (
    // <StandardDashboard {...rest}>
    <>
      <h3 className="absolute left-0 top-0 !z-50 m-0 w-[192px] bg-slate-100 p-[8px]">
        Standards
      </h3>

      <Box className="flex h-[inherit]">
        {!hideRout && (
          <Box
            className="fixed z-10 flex flex-col gap-3 overflow-y-auto rounded-[5px] bg-slate-100 p-2 pt-[50px]"
            sx={{
              height: 'calc(100vh - 0px)',
            }}
          >
            {router.map((item, index) => (
              <NavLink
                key={`${index}-${item}`}
                to={item.link}
                className={({ isActive }) =>
                  cn('cos-link', { underline: isActive })
                }
              >
                {item.label}
              </NavLink>
            ))}
          </Box>
        )}
        <Box className={cn('flex-grow', { 'ml-[200px]': !hideRout })}>
          {children}
        </Box>
      </Box>
    </>
    // </StandardDashboard>
  );
};

export default StandardLayout;
