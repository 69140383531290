import { useState } from 'react';
import { ArrowFill, ArrowFillUp, LanguageIconBlue } from '../../../assets/svgs';
import { ClickAwayListener } from '@mui/material';
import language from '../../../assets/svgs/staticColorSvg/normalLanguageImg.svg';
import clsx from 'clsx';

const LanguageDropdown = () => {
  const [isDropdown, setDropdown] = useState(false);
  const handleDropdownChange = () => {
    setDropdown(!isDropdown);
  };
  return (
    <>
      <div
        className={clsx(
          'language_icon__hover_withing hoverColor right-6 top-[66px] flex cursor-pointer items-center justify-end px-[25px] sm:absolute',
        )}
        onClick={handleDropdownChange}
      >
        {/* <LanguageIconBlue className=" h-[10px] w-[10px]" /> */}
        <img src={language} className="language_icon size-3" />
        <p className="mx-1 w-[54px] text-12/18 leading-none tracking-normal">
          Language
        </p>
        <p className="mx-1 w-[36px] text-11/15 leading-none tracking-normal">
          English
        </p>
        <ArrowFill />
      </div>
      {isDropdown && (
        <ClickAwayListener onClickAway={() => setDropdown(false)}>
          <div
            className="language-dropdown absolute right-[0.3rem] top-[92px]
           z-10 col-span-2 mx-4 h-[42px] w-[61px] cursor-pointer rounded bg-white-whale-100 shadow-6b sm:right-[1.9rem] sm:top-[75px]"
            onClick={() => setDropdown(false)}
          >
            <div className="flex items-center justify-center bg-white-whale-500 pb-[2px] pl-[15px] pt-[2px] transition-all duration-300 ease-in-out hover:text-artist-blue-900  sm:pl-[18px]">
              <span className="mx-1 ml-[-12px] text-11/15 tracking-normal">
                English
              </span>
              {isDropdown && (
                <ArrowFillUp className="" onClick={handleDropdownChange} />
              )}
            </div>
            <div className="mt-[3px] flex items-center justify-center transition-all duration-300 ease-in-out  hover:text-artist-blue-900">
              <span className="mx-1 ml-[0] text-11/15 tracking-normal">
                Spanish
              </span>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};

export default LanguageDropdown;
