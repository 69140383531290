import { CosBasDialog } from '../../../components/dialogBox/index';
import clsx from 'clsx';
import { CosBaseDialogProps } from '../../../components/dialogBox/CosBasDialog';
import CosButton from '../../../components/CosButton';
import { constant } from '../../../libs/constant';
import { Box, styled } from '@mui/material';
import {
  CosTable,
  CosTableContainer,
  CosTableRow,
  CosTableCell,
  CosTableHead,
  CosTableBody,
} from '../../../components/Tables';
import { TempScheduleData } from '../ScheduleOption';
import { CosCheckbox } from '../../../components/formikForms/ui';

interface Props extends CosBaseDialogProps {
  onClose?: () => void;
  contentBody?: React.ReactNode;
  children?: React.ReactNode;
}

const BaseTable = styled(CosTableContainer)(() => ({
  maxHeight: '134px',
  borderRadius: '0px',
  paddingRight: '0px',
  scrollbarGutter: 'unset',
  overflowX: 'auto',
  '&::before': {
    width: '4px',
    height: '33px',
    background: '#051439',
  },
  '&::after': {
    width: 0,
  },
  '& .MuiTableRow-root': {
    boxShadow: '0px 0px 4px #A8B1B880',
    background: '#FFFFFF !important',

    '& .MuiTableCell-head': {
      minHeight: '34px',
      height: '34px',
      position: 'sticky !important',
    },
    '& .MuiTableCell-head:not(:first-of-type)': {
      borderLeft: '0px !important',
    },
    '& .MuiTableCell-body': {
      borderLeft: '0px !important',
      height: '32px',
      textWrap: 'nowrap',
      '& span': {
        border: '1px solid #A8B1B8',
        borderRadius: '50%',
        height: '20px',
        width: '20px',
        textAlign: 'center',
        marginRight: '5px',
      },
    },
    '& .MuiTableCell-body:last-of-type': {
      borderRight: '0px !important',
      paddingRight: '15px !important',
    },
    '& .MuiTableCell-root:last-of-type': {
      textAlign: 'right',
      paddingRight: '15px !important',
    },
    '& .MuiTableCell-head:first-of-type': {
      paddingLeft: '40px !important',
      position: 'sticky',
      left: 0,
      top: 0,
      zIndex: 110,
      background: '#001F38',
    },
    '& .MuiTableCell-body:first-of-type': {
      paddingLeft: '15px !important',
      position: 'sticky',
      left: 0,
      zIndex: 100,
      background: '#FFFFFF',
      borderBottom: '2px solid rgb(237 239 241)',
    },
  },
  '& .MuiTableRow-root:nth-of-type(even), .MuiTableRow-root:nth-of-type(odd)': {
    '& .MuiTableCell-body': {
      color: '#A8B1B8',
    },
  },
}));

const DeleteSchedule: React.FC<Props> = ({
  open,
  onClose,
  children,
  ...rest
}) => {
  const handleClose = () => {
    onClose?.();
  };

  return open ? (
    <CosBasDialog
      open={open}
      width="580px"
      height="350px"
      sx={{
        '.MuiPaper-root': {
          borderRadius: constant.WorkScheduleModalRadius + 'px',
          background: '#EDEEEF',
          boxShadow: '0px 0px 12px #A8B1B8',
        },
      }}
      className="rounded"
      bodyClass={clsx('p-0 bg-white mr-0 overflow-y-hidden')}
      actionClass="flex justify-between capitalize border-0 p-[15px] bg-white overflow-x-hidden"
      dialogActions={
        <>
          <CosButton
            size="large"
            variant="outlined"
            className="min-h-auto w-[140px]"
            tabIndex={0}
            onClick={handleClose}
          >
            {'Close'}
          </CosButton>
          <CosButton
            size="large"
            variant="outlined"
            color="error"
            onClick={handleClose}
            className="min-h-auto m-0 w-[140px]"
            tabIndex={0}
          >
            {'confirm'}
          </CosButton>
        </>
      }
      {...rest}
    >
      <section>
        <Box className="p-[15px] pb-0">
          <h3 className="m-0 text-18/22 text-error-900">
            {`Confirming %Work Schedule% Deletion (${TempScheduleData.length})`}
          </h3>
          <p className="m-0 py-5 text-blue-gray-100">
            Confirm you want to delete this %Work Schedule%.
          </p>
        </Box>
      </section>
      <section className="Table-section h-[350px]">
        <Box>
          <BaseTable>
            <CosTable stickyHeader>
              <CosTableHead>
                <CosTableRow>
                  {/* <CosTableCell></CosTableCell> */}
                  <CosTableCell>Work Schedule</CosTableCell>
                  <CosTableCell>Days</CosTableCell>
                  <CosTableCell>Starts</CosTableCell>
                  <CosTableCell>Working</CosTableCell>
                </CosTableRow>
              </CosTableHead>
              <CosTableBody>
                {TempScheduleData.map((item, index) => (
                  <CosTableRow key={index + 1}>
                    {/* <CosTableCell></CosTableCell> */}
                    <CosTableCell className="flex items-center">
                      <span>{index + 1}</span>
                      <p className="m-0 text-error-900">{item.scheduleName}</p>
                    </CosTableCell>
                    <CosTableCell>
                      {item.workingDay
                        .map((item: any) => item?.value.slice(0, 3))
                        .join(', ')}
                    </CosTableCell>
                    <CosTableCell>{item.startDay.value}</CosTableCell>
                    <CosTableCell>{item.workHours.value}</CosTableCell>
                  </CosTableRow>
                ))}
              </CosTableBody>
            </CosTable>
          </BaseTable>
        </Box>
      </section>
      <section className="absolute bottom-16 right-0 flex justify-end">
        <p className="m-0 flex w-[160px] px-[15px] pt-[15px] text-12/18 text-dark-blue-900">
          <CosCheckbox className="pr-[8px]" /> Don’t ask me
        </p>
      </section>
    </CosBasDialog>
  ) : (
    <></>
  );
};

export default DeleteSchedule;
