import * as React from 'react';
import { styled } from '@mui/system';
import { alpha } from '@mui/material';
import useNumberHook from './useNumberHook';
import { CosNUmberProps } from './number';
import { Minus, Plus } from '../../../../assets/svgs';

const CosPrimaryNumberField = React.forwardRef(function CustomNumberInput(
  props: CosNUmberProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { min = 0, max = 24, defaultValue = 0, onChange } = props;
  const {
    onDecrement,
    onIncrement,
    value,
    handleChange,
    onKeyDown,
    handleMouseDown,
    handleMouseUp,
    disableDecrement,
    disableIncrement,
    numberInputRef,
  } = useNumberHook({
    min,
    max,
    defaultValue,
    onChange,
  });
  // console.log('value', value);

  return (
    <StyledInputRoot ref={ref}>
      <StyledButton
        className="decrement"
        id="numberDecrement"
        onClick={onDecrement}
        disabled={disableDecrement}
        tabIndex={-1}
        onMouseDown={() => handleMouseDown?.(false)}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <Minus />
      </StyledButton>
      <StyledInput
        type="number"
        value={value}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        ref={numberInputRef}
      />
      <StyledButton
        className="increment"
        id="numberIncrement"
        onClick={onIncrement}
        disabled={disableIncrement}
        tabIndex={-1}
        onMouseDown={() => handleMouseDown?.(true)}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <Plus />
      </StyledButton>
    </StyledInputRoot>
  );
});

export default CosPrimaryNumberField;

const StyledInputRoot = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  height: '28px',
  boxShadow: '0px 0px 3px #A8B1B8',
  borderRadius: 3,
}));

const StyledInput = styled('input')(({ theme }) => ({
  width: '40px',
  fontSize: '12px',
  fontWeight: '600',
  background: '#ffffff',
  textAlign: 'center',
  outline: 0,
  color: '#001F38',
  border: 'none',
  borderLeft: '1px solid',
  borderRight: '1px solid',
  borderColor: alpha('#A8B1B8', 0.5),
  height: '100%',

  '&:hover': {
    color: alpha('#001F38', 0.75),
  },

  '&:focus': {
    color: alpha('#001F38', 1),
  },
}));

const StyledButton = styled('button')(({ theme }) => ({
  boxSizing: 'border-box',
  border: '0px solid',
  width: '17px',
  height: '28px',
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#ffffff',
  color: '#001F38',
  padding: 0,

  ':disabled, [disabled]': {
    opacity: '0.25',
    pointerEvents: 'none',
  },

  '&:hover': {
    cursor: 'pointer',
    background: '#07B9EC',
    color: '#ffffff',
  },

  '&.decrement': {
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
  },

  '&.increment': {
    order: 1,
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
  },
}));
