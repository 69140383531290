import { useRef, useState } from 'react';
import { cosPadStart, isNumberRegex } from '../../../../libs/utils';
import lengthChecker from 'string-pixel-width';

interface NumberHookArg {
  min: number;
  max: number;
  defaultValue?: number;
  fieldType?: string;
  onChange?: (value: any) => void;
}

const useNumberHook = ({
  min,
  max,
  defaultValue,
  fieldType,
  onChange,
}: NumberHookArg) => {
  const numberInputRef = useRef<any>();
  const [numberFieldWidth, setNumberFieldWidth] = useState('75px');
  const [value, setValue] = useState(cosPadStart(defaultValue || 0));
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const focusCurrentTarget = () => {
    numberInputRef?.current?.focus();
  };

  const onIncrement = () => {
    focusCurrentTarget();
    const newValue = cosPadStart(+value + 1);
    if (+value !== +max || fieldType == 'arrow') {
      setValue(newValue);
      onChange?.(newValue);
    }
  };

  const onDecrement = () => {
    focusCurrentTarget();
    const newValue = cosPadStart(+value - 1);
    if (+value !== +min) {
      setValue(newValue);
      onChange?.(newValue);
    }
  };

  const handleChange = (event: any) => {
    const newValue = event.target.value;
    // Expand Width
    if (newValue) {
      const width = lengthChecker(newValue, { size: 14 });
      setNumberFieldWidth(`${width + 20}px`);
    } else {
      setNumberFieldWidth('75px');
    }
    // Check if the new value is either '0' or an empty string
    if (newValue === '0' || newValue === '') {
      //in -> '00'
      setValue(() => newValue); //op => 0 pr ''
      onChange?.(newValue);
    } else if (
      (isNumberRegex(newValue) && newValue <= max) ||
      fieldType == 'arrow'
    ) {
      // If the new value is a number and less than or equal to the maximum allowed value
      if (newValue < 10) {
        // If the number is less than 10, pad it with leading zeros  in -> (1)
        setValue(() => cosPadStart(newValue)); //op -> (01)
        onChange?.(cosPadStart(newValue));
      } else {
        // If the number is greater than or equal to 10
        if (newValue.startsWith('0')) {
          // If it starts with a zero, remove the leading zero in -> (012)
          setValue(() => newValue.slice(1)); //op -> (12)
          onChange?.(newValue.slice(1));
        } else {
          // Otherwise, update the state with the new value
          setValue(() => newValue);
          onChange?.(newValue);
        }
      }
    }
  };

  const onKeyDown = (event: any) => {
    if (event.key === '+' || event.key === 'ArrowUp') {
      event.preventDefault();
      onIncrement();
    }
    if (event.key === '-' || event.key === 'ArrowDown') {
      event.preventDefault();
      onDecrement();
    }
  };

  const handleMouseDown = (increment: boolean, type?: string) => {
    let getNumberBtn: any;
    if (type == 'arrow') {
      getNumberBtn = increment
        ? document.getElementById('arrowIncrement')
        : document.getElementById('arrowDecrement');
    } else {
      getNumberBtn = increment
        ? document.getElementById('numberIncrement')
        : document.getElementById('numberDecrement');
    }

    const id = setInterval(() => {
      getNumberBtn?.click();
    }, 200); // Repeat every 200 ms
    setIntervalId(id);
  };

  const handleMouseUp = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };
  return {
    value,
    handleChange,
    onKeyDown,
    handleMouseDown,
    handleMouseUp,
    onIncrement,
    onDecrement,
    numberInputRef,
    numberFieldWidth,
    fieldType,
    disableDecrement: +value === +min,
    disableIncrement: +value === +max,
  };
};

export default useNumberHook;
