import React, { useRef, useState } from 'react';
import { Box, Tab, Tabs, alpha, styled, TabScrollButton } from '@mui/material';
import { handleScrollButtonClick, commonTabsSX } from './tab.util';
import useTabScroll from '../../libs/hooks/useTabScroll';

const BpTab = styled(Tabs)(({}) => ({
  maxWidth: '616px',
  height: '36px !important',
  transform: 'rotate(90deg) translateY(50%)',
  transformOrigin: 'left',
  // backgroundColor: '#EDEEEF',
  position: 'absolute',
  padding: '0px !important',
  top: '-18px',
  ...commonTabsSX,

  '& .snapScrollClass': {
    gap: '5px',
    scrollSnapType: 'x mandatory !important',
  },
  '& .mainSnapScrollClass': {
    display: 'flex',
    flexDirection: 'row',
    scrollSnapType: 'x mandatory',
    scrollBehavior: 'smooth',
    scrollSnapAlign: 'start',
  },
  '& .MuiTabs-scrollButtons': {
    color: '#07B9EC',
  },
  '& .MuiTab-root': {
    flexGrow: '1',
    scrollSnapAlign: 'start',
    height: '23px',
    minHeight: '23px',
    minWidth: '92px',
    backgroundColor: '#001F38',
    border: `1px solid ${alpha('#07B9EC', 0.25)}`,
    color: alpha('#FFFFFF', 0.7),
    borderRadius: '0px 0px 5px 5px',
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '21px',
    textTransform: 'capitalize',
    padding: 0,

    '&:hover': {
      border: 0,
      backgroundColor: '#03688e',
      color: alpha('#07B9EC', 0.7),
    },
  },
  '& .MuiTab-root[aria-selected="true"]': {
    border: 0,
    backgroundColor: '#07B9EC',
    color: alpha('#FFFFFF', 1),
    fontWeight: 600,
  },
}));

const CosVerticalTab: React.FC<CosTabs> = ({ tabs, ...rest }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const tabsRef = useRef<HTMLDivElement | null>(null);
  const tabType = 'vertical';
  useTabScroll(tabsRef, tabType);
  return (
    <Box>
      <BpTab
        value={value}
        onChange={handleChange}
        variant="scrollable"
        selectionFollowsFocus={true}
        {...rest}
        ScrollButtonComponent={({ ...rest }) => {
          return (
            <TabScrollButton
              {...rest}
              onClick={() =>
                handleScrollButtonClick(
                  tabsRef.current,
                  rest.direction,
                  tabType,
                )
              }
            />
          );
        }}
        ref={tabsRef}
      >
        {tabs.map((item: CosTab, index: number) => (
          <Tab
            label={item.label}
            key={`${index}-${item.label}`}
            className="snap-start"
          />
        ))}
      </BpTab>
    </Box>
  );
};

export default CosVerticalTab;
