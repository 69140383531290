import { Box, BoxProps, Divider } from '@mui/material';
import { CosOptions } from '../../../libs/interface';
import { AddHoursField, CosTimeInput } from '../../../components/time';
import { FormAutoComplete, FormInput } from '../../../components/formikForms';
import CosPrimaryNumberField from '../../../components/formikForms/ui/numberField/CosPrimaryNumberField';
import {
  deployments,
  weekOptions,
  TimeOptions,
  HourOptions,
  BreakOptions,
} from '../ScheduleOption';
import { cn } from '../../../libs/utils';
import { useEffect } from 'react';

const multiOption: CosOptions[] = weekOptions;
const deploymentOption: CosOptions[] = deployments;
const TimeOption: CosOptions[] = TimeOptions;
const HourOption: CosOptions[] = HourOptions;
const BreakOption: CosOptions[] = BreakOptions;

interface Props extends BoxProps {
  formikConfig: any;
  updateDynamicScheduleName?: any;
}
const CreateEditScheduleForm: React.FC<Props> = ({
  formikConfig,
  updateDynamicScheduleName,
  className,
  ...rest
}) => {
  const { values, setFieldValue } = formikConfig;

  const labelProps = {
    className: 'mr-[10px] !mb-0 text-dark-blue-900 text-14/18 opacity-60',
  };

  return (
    <Box className={cn('relative', className)} {...rest}>
      <div className="form-group">
        <FormInput
          label="Work Schedule"
          name="scheduleName"
          placeholder="Work Schedule Name"
          isRequired={false}
          labelProps={labelProps}
          inputWrapperClass="!flex"
          wrapperClassName="!mb-0"
          isPopupInput={true}
          inputSx={{
            minWidth: '443px',
            width: '443px',
            height: '28px',
            boxShadow: '0px 0px 3px #A8B1B8',
            border: 'unset',
            textTransform: 'capitalize',
          }}
          disabled
        />
      </div>
      <div className="flex justify-between px-[15px]">
        <section className="w-[356px]">
          <div className="">
            <FormAutoComplete
              multiple
              options={multiOption}
              name="workingDay"
              placeholder="Check all that Apply"
              size="small"
              className="!mb-4 flex justify-between"
              label="Working Days"
              labelProps={labelProps}
              withElevation={false}
              isRequired={false}
              isPopupInput={true}
              inputSx={{
                boxShadow: '0px 0px 3px #A8B1B8',
                minWidth: '239px',
              }}
              isThreeChar={true}
              onChange={(event, value) => {
                setFieldValue('workingDay', value);
                updateDynamicScheduleName(
                  {
                    ...values,
                    workingDay: value,
                  },
                  setFieldValue,
                );
              }}
            />
          </div>
          <div className="">
            <FormAutoComplete
              options={multiOption}
              placeholder="Select Start Day"
              size="small"
              name="startDay"
              className="!mb-4 flex justify-between"
              label="Day Week Starts"
              labelProps={labelProps}
              withElevation={false}
              isRequired={false}
              isPopupInput={true}
              inputSx={{
                boxShadow: '0px 0px 3px #A8B1B8',
                minWidth: '239px',
              }}
            />
          </div>
          <div className="">
            <FormAutoComplete
              addNewComponent={({ activateAddButton, deactivateAddButton }) => (
                <CosTimeInput
                  className="w-full !justify-start pl-[10px]"
                  onChange={({ isValid }) =>
                    isValid ? activateAddButton() : deactivateAddButton()
                  }
                />
              )}
              options={TimeOption}
              name="startTime"
              placeholder="Select Start Time"
              size="small"
              className="!mb-4 flex justify-between"
              label="Time Day Starts"
              labelProps={labelProps}
              withElevation={false}
              isRequired={false}
              isPopupInput={true}
              inputSx={{
                boxShadow: '0px 0px 3px #A8B1B8',
                minWidth: '239px',
              }}
              onChange={(event, option) => {
                updateDynamicScheduleName(
                  {
                    ...values,
                    startTime: option ? option : {},
                  },
                  setFieldValue,
                );
              }}
            />
          </div>
          <div className="">
            <FormAutoComplete
              addNewComponent={({ activateAddButton }) => (
                <div
                  className="mb-1 flex w-full !justify-start !py-[7px] pl-[10px]"
                  style={{ boxShadow: '0px 0px 3px #A8B1B8' }}
                >
                  <CosPrimaryNumberField onChange={() => activateAddButton()} />
                  <p className="m-0 flex items-center pl-2 text-14/18 font-semibold text-dark-blue-900">
                    Hours
                  </p>
                </div>
              )}
              options={HourOption}
              name="workHours"
              placeholder="Select Work Hours"
              size="small"
              className="!mb-4 flex justify-between"
              label="Hours Working"
              labelProps={labelProps}
              withElevation={false}
              isRequired={false}
              isPopupInput={true}
              inputSx={{
                boxShadow: '0px 0px 3px #A8B1B8',
                minWidth: '239px !important',
              }}
              onChange={(event, option) => {
                updateDynamicScheduleName(
                  {
                    ...values,
                    workHours: option ? option : {},
                  },
                  setFieldValue,
                );
              }}
            />
          </div>
          <div className="">
            <FormAutoComplete
              addNewComponent={({ activateAddButton, deactivateAddButton }) => (
                <AddHoursField
                  className="mb-1 w-full !justify-start pl-[10px]"
                  onChange={({ isValid }) =>
                    isValid ? activateAddButton() : deactivateAddButton()
                  }
                />
              )}
              options={BreakOption}
              name="breakTime"
              placeholder="Select Break Time"
              size="small"
              className="!mb-4 flex justify-between"
              label="Break"
              labelProps={labelProps}
              withElevation={false}
              isRequired={false}
              isPopupInput={true}
              inputSx={{
                boxShadow: '0px 0px 3px #A8B1B8',
                minWidth: '239px',
              }}
            />
          </div>
        </section>
        <section className="w-[154px]">
          <div>
            <FormInput
              label="No. of Days"
              name="noOfDays"
              isRequired={false}
              labelProps={labelProps}
              inputWrapperClass="!flex"
              wrapperClassName="!mb-0"
              isPopupInput={true}
              inputSx={{
                minWidth: '70px',
                width: '70px',
                height: '28px',
                boxShadow: '0px 0px 3px #A8B1B8',
                border: 'unset',
                textTransform: 'capitalize',
              }}
              disabled
            />
          </div>
        </section>
      </div>
      <Divider className="main-divider mb-4 mt-[32px]"></Divider>
      <div className="mb-[25px] flex justify-between px-[15px]">
        <section className="w-[355px]">
          <div className="">
            <FormAutoComplete
              multiple
              options={deploymentOption}
              placeholder="Select"
              size="small"
              name="deployments"
              className="!mb-4 flex justify-between"
              label="Deployments"
              labelProps={labelProps}
              withElevation={false}
              isRequired={false}
              isPopupInput={true}
              inputSx={{
                boxShadow: '0px 0px 3px #A8B1B8',
                minWidth: '239px',
              }}
            />
          </div>
        </section>
        <section className="w-[154px]"></section>
      </div>
    </Box>
  );
};

export default CreateEditScheduleForm;
