import { RouteObject } from 'react-router';
import {
  StandardSwitchPage,
  StandardHorizontalTab,
  StandardDropdownPage,
  StandardTopHeader,
  StandardScrollBar,
  StandardPageScrollbar,
  StandardPageButton,
  StandardPageCheckbox,
  StandardPageRadio,
  StandardPageTooltip,
  StandardPageArrow,
  StandardHorizontalScrollBar,
  StandardTextField,
  StandardNumberField,
  StandardDataTable,
  StandardActionBtn,
  StandardSearchBar,
  StandardTimeInput,
  StandardPopup,
  StandardPagination,
  StandardVerticalTab,
  StandardCalendar,
  StandardClock,
  StandardMainHeader,
  StandardRightPanel,
  StandardLeftPanel,
  StandardPageFooter,
  StandardMenus,
} from '..';
import { isLocalEnv, isStandardEnv } from '../../libs/utils';
import comingSoonCompanyHandler from './comingSoonCompanyHandler';
import { comingSoonUsersHandler } from './comingSoonUsersHandler';
import { comingSoonSettingHandler } from './comingSoonSettingHandler';
import StandardModalActionBtn from '../StandardModalActionBtn';

const routers: RouteObject[] = [
  {
    path: '/standard',
    children: [
      {
        path: '',
        element: <StandardSwitchPage />,
      },
      {
        path: 'switch',
        element: <StandardSwitchPage />,
      },
      {
        path: 'horizontal_tab',
        element: <StandardHorizontalTab />,
      },
      {
        path: 'vertical_tab',
        element: <StandardVerticalTab />,
      },
      {
        path: 'dropdown',
        element: <StandardDropdownPage />,
      },
      {
        path: 'scrollbar',
        element: <StandardScrollBar />,
      },
      {
        path: 'horizontal_scrollbar',
        element: <StandardHorizontalScrollBar />,
      },
      {
        path: 'page_content_with_scrollbar',
        element: <StandardPageScrollbar />,
      },
      {
        path: 'button',
        element: <StandardPageButton />,
      },
      {
        path: 'checkbox',
        element: <StandardPageCheckbox />,
      },
      {
        path: 'radio',
        element: <StandardPageRadio />,
      },
      {
        path: 'tooltip',
        element: <StandardPageTooltip />,
      },
      {
        path: 'arrow',
        element: <StandardPageArrow />,
      },
      {
        path: 'text_fields',
        element: <StandardTextField />,
      },
      {
        path: 'number_fields',
        element: <StandardNumberField />,
      },
      {
        path: 'data-table',
        element: <StandardDataTable />,
      },
      {
        path: 'icon',
        element: <StandardActionBtn />,
      },
      {
        path: 'searchBar',
        element: <StandardSearchBar />,
      },
      {
        path: 'timeInput',
        element: <StandardTimeInput />,
      },
      {
        path: 'popup',
        element: <StandardPopup />,
      },
      {
        path: 'modalActionBtn',
        element: <StandardModalActionBtn />,
      },
      {
        path: 'pagination',
        element: <StandardPagination />,
      },
      {
        path: 'calendar',
        element: <StandardCalendar />,
      },
      {
        path: 'clock',
        element: <StandardClock />,
      },
      {
        path: 'menus',
        element: <StandardMenus />,
        children: [
          {
            path: 'profile',
            element: <p>profile</p>,
          },
          {
            path: 'company',
            element: <p>company</p>,
            children: [
              {
                path: 'work_schedules',
              },
              {
                path: 'holidays',
              },
            ],
          },
          {
            path: 'internal_resource',
            element: <></>,
          },
          {
            path: 'default_setting',
            element: <></>,
          },
        ],
      },
      {
        path: 'top-header',
        element: <StandardTopHeader />,
      },
      {
        path: 'signup-header',
        element: <StandardMainHeader />,
      },
      {
        path: 'right-panel',
        element: <StandardRightPanel />,
      },
      {
        path: 'left-panel',
        element: <StandardLeftPanel />,
        children: [
          {
            path: 'tab1',
            element: <p>TBD-1</p>,
          },
          {
            path: 'tab2',
            element: <p>TBD-2</p>,
            children: [
              {
                path: 'sub-tab1',
                element: <p>SUB-TAB1</p>,
                children: [
                  {
                    path: 'tab1',
                  },
                  {
                    path: 'tab2',
                  },
                ],
              },
              {
                path: 'sub-tab2',
              },
            ],
          },
          {
            path: 'tab3',
            element: <p>TBD-3</p>,
          },
          {
            path: 'tab4',
            element: <p>TBD-4</p>,
          },
        ],
      },
      {
        path: 'page-footer',
        element: <StandardPageFooter />,
      },
    ],
  },
  comingSoonCompanyHandler,
  comingSoonUsersHandler,
  comingSoonSettingHandler,
];

const standardRouterHandler: RouteObject[] =
  isStandardEnv() || isLocalEnv() ? routers : [];

export default standardRouterHandler;
