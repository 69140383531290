// Define an object that maps numbers to their word form
const numbersToWords: { [key: number]: string } = {
  0: 'Zero',
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
  6: 'Six',
  7: 'Seven',
  8: 'Eight',
  9: 'Nine',
  10: 'Ten',
  11: 'Eleven',
  12: 'Twelve',
  13: 'Thirteen',
  14: 'Fourteen',
  15: 'Fifteen',
  16: 'Sixteen',
  17: 'Seventeen',
  18: 'Eighteen',
  19: 'Nineteen',
  20: 'Twenty',
  30: 'Thirty',
  40: 'Forty',
  50: 'Fifty',
  60: 'Sixty',
  70: 'Seventy',
  80: 'Eighty',
  90: 'Ninety',
};

// Define the convertNumberToWords function
export function convertNumberToWords(number: any) {
  // if number present in object no need to go further
  if (number in numbersToWords) return numbersToWords[number];

  // Initialize the words variable to an empty string
  let words = '';

  // If the number is greater than zero, handle the remaining digits
  if (number > 0) {
    // If the words string is not empty, add "and"
    if (words !== '') words += ' and ';

    // If the number is less than 20, look up the word form in the numbersToWords object
    if (number < 20) words += numbersToWords[number];
    else {
      // Otherwise, add the word form of the tens place to the words string
      //if number = 37, Math.floor(number /10) will give you 3 and 3 * 10 will give you 30
      words += numbersToWords[Math.floor(number / 10) * 10];

      // If the ones place is not zero, add the word form of the ones place
      if (number % 10 > 0) {
        words += '-' + numbersToWords[number % 10];
      }
    }
  }

  // Return the word form of the number
  return words;
}
