import React from 'react';
import { ChevronArrowBtn } from '../../../arrow';
import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  onIncrement: Function;
  onDecrement: Function;
  isDisableDecrement?: boolean;
  isDisableIncrement?: boolean;
  handleMouseDown?: (increment: boolean, type?: string) => void;
  handleMouseUp?: () => void;
}

const IncrementDecrementBtn: React.FC<Props> = props => {
  const {
    onIncrement,
    onDecrement,
    isDisableDecrement,
    isDisableIncrement,
    handleMouseDown,
    handleMouseUp,
    ...rest
  } = props;
  return (
    <Box className="flex flex-col gap-1" {...rest}>
      <span
        onClick={() => !isDisableDecrement && onDecrement()}
        onMouseDown={() => handleMouseDown?.(false, 'arrow')}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        id="arrowDecrement"
        className="order-1 inline-flex"
      >
        <ChevronArrowBtn disable={isDisableDecrement} open={false} />
      </span>
      <span
        onClick={() => !isDisableIncrement && onIncrement()}
        onMouseDown={() => handleMouseDown?.(true, 'arrow')}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        id="arrowIncrement"
        className="inline-flex"
      >
        <ChevronArrowBtn disable={isDisableIncrement} open={true} />
      </span>
    </Box>
  );
};

export default IncrementDecrementBtn;
