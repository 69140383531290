import { useState } from 'react';
import clsx from 'clsx';
import { Box, Slide } from '@mui/material';
import { ExpandArrow } from '../assets/svgs';
import StandardLayout from './StandardLayout';
import CosBlankList from '../components/RightPanel/CosBlankList';
import CosAssignTab from '../components/RightPanel/CosAssignTab';
import CosPageVerticalTab from '../components/RightPanel/CosPageVerticalTab';
import { styled } from '@mui/system';
import { cn } from '../libs/utils';
import { CosTooltip } from '../components';

const threeTabs = [
  { label: 'All', value: 'All' },
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
];

const SortingTabs = [
  { label: 'Name', value: 'Name' },
  { label: 'Start', value: 'Start' },
  { label: 'complete', value: 'complete' },
  { label: 'Pending', value: 'Pending' },
  { label: 'On hold', value: 'On hold' },
];

const contextTab = [
  { label: 'Task List', value: 'Task List' },
  { label: 'Project List', value: 'Project List' },
  { label: 'User List', value: 'User List' },
  { label: 'Customer List', value: 'Customer List' },
  { label: 'Assign', value: 'Assign1' },
  { label: 'Assign', value: 'Assign2' },
  { label: 'Assign', value: 'Assign3' },
  { label: 'Assign', value: 'Assign4' },
];

const TabOptions = [
  { label: 'Task List', value: 'Task List' },
  { label: 'Project List', value: 'Project List' },
  { label: 'User List', value: 'User List' },
  { label: 'Customer List', value: 'Customer List' },
];
const BpBox = styled(Box)(({}) => ({
  transition: 'width 300ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
}));

const ExpandHideShow = styled(Box)(({}) => ({
  '& .expand-hideshow': {
    boxShadow: 'inset 0px 2px 5px #A8B1B880, 0px 1px 3px #A8B1B880',
    transition: 'all 0.3s ease',
    width: '18px',
    '&:hover': {
      backgroundColor: '#07B9EC !important',
      width: '35px',
      '& svg': {
        color: '#FFFFFF !important',
        pointerEvents: 'none !important',
      },
    },
    '& svg': {
      pointerEvents: 'none !important',
      zIndex: 1,
    },
  },
}));
const StandardRightPanel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTabOpen, setIsTabOpen] = useState(true);

  const [isProjectList, setIsProjectList] = useState(false);
  const hanldeTabToggle = () => {
    setIsTabOpen(!isTabOpen);
    setIsOpen(false);
    setIsProjectList(false);
  };
  const listingToggle = () => {
    setIsProjectList(false);
    isProjectList ? setIsOpen(false) : setIsOpen(!isOpen);
  };
  const handleTabSingleClick = () => {
    setIsProjectList(false);
    setIsOpen(true);
  };
  const handleProjectList = () => {
    setIsOpen(false);
    setIsProjectList(true);
  };
  return (
    <StandardLayout>
      <BpBox className={cn('fixed right-0 top-0')}>
        <div className="flex h-[100vh] items-center">
          <Box
            sx={{
              width: isTabOpen ? 15 : 0,
              transition: 'width 300ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            }}
          >
            <span
              role="button"
              onClick={listingToggle}
              className="inline-flex h-[50px] w-[15px] cursor-pointer items-center justify-center rounded-l-[5px] bg-white-whale-500"
            >
              <ExpandArrow
                className={clsx(
                  `zoom-icon cursor-pointer border-0 text-dark-blue-900 ${
                    isOpen || isProjectList ? '-rotate-90' : 'rotate-90'
                  }`,
                )}
              />
            </span>
          </Box>
          <Box className="relative h-full">
            <Box
              sx={{
                width: isTabOpen ? 36 : 0,
                transition: 'width 300ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
              }}
            >
              <CosPageVerticalTab
                tabs={contextTab}
                isTabOpen={isTabOpen}
                isProjectList={isProjectList}
                onTabClick={handleTabSingleClick}
                onTabDoubleClick={handleProjectList}
                hanldeTabToggle={hanldeTabToggle}
              />
            </Box>
            <ExpandHideShow
              sx={{ width: 36 }}
              className="absolute bottom-0 right-0 text-end"
            >
              <CosTooltip
                title={isTabOpen ? 'Hide' : 'Show'}
                placement="left"
                enterDelay={301}
                // yOffset={-12}
              >
                <span
                  role="button"
                  className={clsx(
                    'expand-hideshow inline-flex h-[28px] cursor-pointer items-center justify-center rounded-l-[15px] bg-white-whale-500',
                  )}
                  onClick={hanldeTabToggle}
                >
                  <ExpandArrow
                    className={clsx(
                      `rotate-270 !pointer-events-none border-0 text-dark-blue-900 ${isTabOpen ? '-rotate-90' : 'rotate-90'}`,
                    )}
                  />
                </span>
              </CosTooltip>
            </ExpandHideShow>
          </Box>
          <Box
            sx={{
              width: isOpen || isProjectList ? 253 : 0,
              transition:
                isOpen || isProjectList
                  ? 'width 150ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
                  : 'width 150ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            }}
            className="h-full"
          >
            {isOpen && (
              <CosBlankList sortingTabs={SortingTabs} threeTabs={threeTabs} />
            )}
            {isProjectList && (
              <CosAssignTab
                listingToggle={listingToggle}
                contextTab={contextTab}
                TabOptions={TabOptions}
              />
            )}
          </Box>
        </div>
      </BpBox>
    </StandardLayout>
  );
};
export default StandardRightPanel;
