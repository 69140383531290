import clsx from 'clsx';
import images from '../../../../assets/images';
import {
  CosLogo,
  SchedulrOpsPrimarylogoCollapsed,
} from '../../../../assets/svgs';

export interface SidebarFooterProps {
  open: boolean;
  isCosLogo?: boolean;
  classWrapper?: string;
}
const SidebarFooter: React.FC<SidebarFooterProps> = ({
  open,
  classWrapper,
  isCosLogo,
}) => {
  return (
    <div
      className={clsx(
        'h-[67px] w-full bg-white-whale-500 pb-[2px] pl-[14px] pr-[11px] pt-[12.12px]',
        {
          '!pl-1': !open,
          '!pt-0': !open,
        },
        classWrapper,
      )}
    >
      <div className="flex items-center justify-center">
        {open ? (
          isCosLogo ? (
            // <CosLogo className="h-[44px] w-[83px]" />
            <p
              className="m-0 mb-1 flex h-[37px] w-[175px] items-center justify-center bg-white text-dark-blue-900"
              style={{
                boxShadow: '0px 0px 46px #00000029',
              }}
            >
              Logo
            </p>
          ) : (
            <img
              src={images.schedulerOpsLogo}
              width={175}
              height={36}
              alt="Logo"
              className="cursor-pointer hover:opacity-75"
            />
          )
        ) : isCosLogo ? (
          <p
            className="z-10 m-0 mt-[10px] flex h-[11px] w-[36px] items-center justify-center bg-white text-[7px] text-dark-blue-900"
            style={{
              boxShadow: '0px 0px 46px #00000029',
            }}
          >
            Logo
          </p>
        ) : (
          <SchedulrOpsPrimarylogoCollapsed />
        )}
      </div>
      <span
        className={clsx(
          'block truncate text-center text-[7px] text-dark-blue-900',
          {
            'mt-8': !open,
          },
        )}
      >
        {open
          ? 'Construction Ops Software Co - All Rights Reserved ©'
          : 'COS-©'}
      </span>
    </div>
  );
};

export default SidebarFooter;
