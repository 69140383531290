import React from 'react';
import { ArrowFillUp } from '../../assets/svgs';
import clsx from 'clsx';
import { ArrowBtnProps } from './arrow-interface';

// * : 10px spacing to icon left side when use
const ChevronArrowBtn: React.FC<ArrowBtnProps> = ({
  open,
  disable,
  className,
  onClick,
}) => {
  return (
    <span className="inline-flex" onClick={onClick}>
      <ArrowFillUp
        className={clsx(
          'base-animation text-dark-blue-900 transition-transform  hover:text-artist-blue-900',
          {
            'rotate-0 cursor-pointer': !open,
            'rotate-180 cursor-pointer': open,
            'pointer-events-none opacity-25': disable,
          },
          className,
        )}
      />
    </span>
  );
};

export default ChevronArrowBtn;
