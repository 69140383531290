import { Avatar, AvatarGroup, Box, List, ListItem, alpha } from '@mui/material';
import Drawer from './ui/Drawer';
import images from '../../../assets/images';
import BuyNowDropDown, { BuyNowProps } from './ui/BuyNowDropDown';
import styled from '@emotion/styled';
import {
  Collapsed,
  Company,
  Dashboard,
  Facebook,
  Insta,
  Linkedin,
  Projects,
  Resources,
  Schedules,
  SchedulrOpsPrimarylogoCollapsed,
  Twitter,
  UsersGroup,
} from '../../../assets/svgs';
import settings from '../../../assets/svgs/settings.svg';
import { memo } from 'react';
import clsx from 'clsx';
import { cn, setLocalStorageItem } from '../../../libs/utils';
import { NavLink } from 'react-router-dom';
import { localStorageKey } from '../../../libs/constant';
import SidebarFooter, { SidebarFooterProps } from './ui/SidebarFooter';
import LeftPanel from './ui/LeftPanel';

interface LeftSideComponent {
  className?: string;
}

interface IconComponent {
  isActive?: boolean;
}

export const PerformanceChip = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 72,
  minHeight: 20,
  padding: '3px 6px',
  letterSpacing: '-0.18px',
  fontSize: '10px',
  lineHeight: '14px',
  border: '1px solid #FFFFFF',
  borderRadius: '99px',
  fontWeight: 600,
}));

export interface SidebarNavigationList {
  label: string;
  link: string;
  icon: ({ isActive }: IconComponent) => JSX.Element;
  isDisable?: boolean;
  leftSideComponent?: ({ className }: LeftSideComponent) => JSX.Element;
  badgeValue?: number;
}

const sidebarNavigationList: SidebarNavigationList[] = [
  {
    label: 'My Company',
    link: '/my_company',
    icon: () => <Company />,
    isDisable: false,
    leftSideComponent: ({ className }) => (
      <div
        className={clsx(
          'flex size-7 items-center justify-center rounded-full border border-solid border-error-900',
          className,
        )}
      >
        <span className="text-10/22 text-error-900">20%</span>
      </div>
    ),
  },
  {
    label: 'Users',
    link: '/users',
    icon: () => <UsersGroup />,
    isDisable: false,
    badgeValue: 1,
  },
  {
    label: 'Settings',
    link: '/settings',
    icon: ({ isActive }) => (
      <img
        src={settings}
        style={{ width: 16, height: 16 }}
        className={cn('invert-70', {
          '!invert': isActive,
        })}
      />
    ),
    isDisable: false,
    // badgeValue: 1,
  },
  {
    label: 'Dashboard',
    link: '/dashboard',
    icon: () => <Dashboard />,
    isDisable: true,
    leftSideComponent: () => <PerformanceChip>Performance</PerformanceChip>,
    // badgeValue: 1,
  },
  {
    label: 'Projects',
    link: '/projects',
    icon: () => <Projects />,
    isDisable: true,
    // badgeValue: 1,
  },
  {
    label: 'Resources',
    link: '/resources',
    icon: () => <Resources />,
    isDisable: true,
    // badgeValue: 1,
  },
  {
    label: 'Schedules',
    link: '/schedules',
    icon: () => <Schedules />,
    isDisable: true,
    // badgeValue: 1,
  },
];

const socialLink = [Facebook, Twitter, Insta, Linkedin];

const openIconSx = {
  '& span': {
    transition: 'opacity 0.3s ease-out',
    '&:nth-of-type(1)': {
      opacity: 0.2,
    },
    '&:nth-of-type(2)': {
      opacity: 0.4,
    },
    '&:nth-of-type(3)': {
      opacity: 0.6,
    },
    '&:nth-of-type(4)': {
      opacity: 0.8,
    },
    '&:nth-of-type(5)': {
      opacity: 1,
    },
  },

  '&:hover': {
    '& span': {
      '&:nth-of-type(1)': {
        opacity: 1,
      },
      '&:nth-of-type(2)': {
        opacity: 0.8,
      },
      '&:nth-of-type(3)': {
        opacity: 0.6,
      },
      '&:nth-of-type(4)': {
        opacity: 0.4,
      },
      '&:nth-of-type(5)': {
        opacity: 0.2,
      },
    },
  },
};

interface Props extends BuyNowProps, SidebarFooterProps {
  open: boolean;
  toggleSideBar: () => void;
  list?: SidebarNavigationList[];
}

const Sidebar: React.FC<Props> = ({
  open,
  toggleSideBar,
  list,
  isCosLogo,
  buyNowCheckList,
}) => {
  const sidebarList = list ?? sidebarNavigationList;

  return (
    <Drawer variant="permanent" open={open}>
      <LeftPanel
        sidebarList={sidebarList}
        toggleSideBar={toggleSideBar}
        open={open}
        buyNowCheckList={buyNowCheckList}
      />

      {/* Footer */}
      <div
        className={clsx('z-10 h-[33px] bg-artist-blue-900 pl-[14px]', {
          '!px-[4px]': !open,
        })}
      >
        <AvatarGroup spacing={open ? -6 : 15.5} className="h-full justify-end">
          {socialLink.map((Logo, index) => (
            <Avatar
              key={`${index}-social-logo`}
              sx={{
                width: 'auto',
                height: 'auto',
                cursor: 'pointer',
                zIndex: index,
              }}
            >
              <Logo style={{ width: 22, height: 22 }} />
            </Avatar>
          ))}
        </AvatarGroup>
      </div>

      <SidebarFooter open={open} isCosLogo={isCosLogo} />
    </Drawer>
  );
};

export default memo(Sidebar);
