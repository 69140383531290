import Footer from '../layout/auth/components/Footer';
import SidebarFooter from '../layout/masterlayout/components/ui/SidebarFooter';
import StandardLayout from './StandardLayout';

const StandardPageFooter = () => {
  return (
    <StandardLayout>
      <div className="mb-2">
        <p className="text-center">Sign In/ Sign Up</p>
        <Footer className="top-0" />
      </div>

      {/* <p className="text-center">Left Sidebar Footer</p>
      <div className="mb-2 flex justify-center">
        <div className="relative h-[200px] w-[200px] bg-dark-blue-900">
          <SidebarFooter
            open={true}
            isCosLogo={true}
            classWrapper="absolute bottom-0"
          />
        </div>
      </div> */}
    </StandardLayout>
  );
};

export default StandardPageFooter;
