import StandardLayout from './StandardLayout';
import TimeInput from '../components/time/CosTimeInput';
import { CosTimePicker } from '../components/time';

const StandardTimeInput = () => {
  return (
    <StandardLayout>
      <div className="mt-2">
        <p className="mb-1">12 Hr format</p>
        <TimeInput
          onChange={value => console.log('value', value)}
          // defaultValue={'12:25 AM'}
        />
      </div>

      <div className="mb-4 mt-2">
        <p className="mb-1">24 Hr format</p>
        <TimeInput
          onChange={value => console.log('value2', value)}
          // defaultValue={'12:25 AM'}
          is24HrFormate={true}
        />
      </div>

      <p className="mt-10">
        With default time if user want to edit submitted form
      </p>
      <div>
        <p className="mb-1">12 Hr format</p>
        <TimeInput
          onChange={value => console.log('value', value)}
          defaultValue={'12:25 AM'}
        />
      </div>

      <div className="mb-4 mt-2">
        <p className="mb-1">24 Hr format</p>
        <TimeInput
          onChange={value => console.log('value2', value)}
          defaultValue={'12:25'}
          is24HrFormate={true}
        />
      </div>
    </StandardLayout>
  );
};

export default StandardTimeInput;
