import React, { useState } from 'react';
import { Field, FieldProps } from 'formik';
import { CosInput } from './ui';
import { CosInputProps } from './ui/CosInput';
import clsx from 'clsx';
import { CosFormErrorAlert } from '..';
import { CosAlertProps } from '../alert/CosFormErrorAlert';
interface FormInputProps extends CosInputProps {
  name: string;
  autoComplete?: string;
  hideDefaultError?: boolean;
  alertProps?: Omit<CosAlertProps, 'children'>;
  withTitleCase?: boolean;
}

const FormInput: React.FC<FormInputProps> = ({
  name,
  autoComplete,
  className,
  inputWrapperClass,
  wrapperClassName,
  hideDefaultError,
  onBlur,
  alertProps,
  withTitleCase,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Field name={name}>
      {({
        field: { onBlur: formikOnBlur, value, ...restField },
        meta,
      }: FieldProps) => (
        <div className={clsx('mb-4', className)}>
          <CosInput
            autoComplete={autoComplete || 'off'}
            className={clsx('!mb-0', inputWrapperClass)}
            wrapperClassName={wrapperClassName}
            onFocus={() => setIsFocused(true)}
            onBlur={event => {
              formikOnBlur(event);
              setIsFocused(false);
              onBlur && onBlur(event);
            }}
            value={withTitleCase ? value.toTitleCase() : value}
            {...restField}
            name={name}
            error={!!(meta.touched && meta.error)}
            {...rest}
          />
          {!hideDefaultError &&
            meta.touched &&
            meta.error &&
            meta.error !== ' ' &&
            !isFocused && (
              <CosFormErrorAlert
                severity="error"
                className="mt-2"
                icon={true}
                {...alertProps}
              >
                {meta.error}
              </CosFormErrorAlert>
            )}
        </div>
      )}
    </Field>
  );
};

export default FormInput;
