import { Box, Collapse, List, ListItem, Slide, alpha } from '@mui/material';
import { ExpandArrow } from '../../../../assets/svgs';
import { CosCheckbox } from '../../../../components/formikForms/ui';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  cn,
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../../../libs/utils';
import { localStorageKey } from '../../../../libs/constant';
import { log } from 'console';
const checkList = [
  {
    label: 'Complete your Company Profile',
  },
  {
    label: 'Import from our Libraries',
  },
  {
    label: 'Build your Team',
  },
  {
    label: 'Establish your communications flow',
  },
  {
    label: "Define your company's default settings",
  },
  {
    label: 'TBD',
  },
  {
    label: 'TBD',
  },
];
export interface BuyNowProps {
  buyNowCheckList?: any;
  isShow?: boolean;
}
const BuyNowDropDown: React.FC<BuyNowProps> = ({ buyNowCheckList, isShow }) => {
  const [isOpen, setIsOpen] = useState(true);
  const containerRef: any = useRef(null);
  const buyCheckList = buyNowCheckList ?? checkList;
  useLayoutEffect(() => {
    const value = getLocalStorageItem(localStorageKey.IS_SHOW_BUY_NOW) ?? true;
    setIsOpen(!!value);
  }, []);

  const toggle = () => {
    setLocalStorageItem(localStorageKey.IS_SHOW_BUY_NOW, !isOpen);
    setIsOpen(preValue => !preValue);
  };

  return (
    <div className="">
      <>
        {isShow && (
          <span
            role="button"
            onClick={toggle}
            className={cn(
              'base-animation absolute bottom-[7px] right-[15px] inline-flex h-3 w-[26px] cursor-pointer items-center justify-center rounded-t-[5px] bg-white-whale-500 transition-all',
              {
                'bottom-[208px]': isOpen,
                // 'w-[0px]': !isShow,
              },
            )}
            style={{ borderBottom: `1px solid ${alpha('#001F38', 0.13)}` }}
            ref={containerRef}
          >
            <ExpandArrow
              width={12}
              height={7}
              className={clsx(
                'base-animation zoom-icon cursor-pointer text-dark-blue-900 transition-transform',
                {
                  'rotate-180': !isOpen,
                },
              )}
            />
          </span>
        )}
        <div
          className={cn(
            'base-animation pointer-events-none absolute bottom-[-5px] z-[1px] ml-[9px] mr-[6px] h-0 w-[185px] overflow-hidden rounded-t-[5px] bg-white-whale-500 pb-[5px] pl-[5px] pt-[7px] transition-[height]',
            {
              'pointer-events-auto !bottom-0 h-[208px]': isOpen,
              '!bottom-0 w-[0px] !p-0 transition-all': !isShow,
            },
          )}
        >
          <div className="relative">
            <section className={cn('mr-[2px] h-[150px] overflow-auto')}>
              <List className="space-y-[10px] p-0">
                {buyCheckList.map((list: any, index: any) => (
                  <ListItem
                    key={`${index}-${list.label}`}
                    disablePadding
                    sx={{
                      color: '#001F38',
                      whiteSpace: 'normal',
                      alignItems: 'flex-start',
                      gap: '10px',
                      minHeight: '15px',
                      paddingRight: '19px',
                    }}
                  >
                    <CosCheckbox />
                    <span className="text-11/13">{list.label}</span>
                  </ListItem>
                ))}
              </List>
            </section>
            <section className="mt-2 flex flex-col items-center  gap-[5px]">
              <p className="m-0 cursor-pointer font-controller text-14/16 font-semibold text-buddha-love-800 hover:text-artist-blue-900">
                Buy Now
              </p>
              <p className="m-0 cursor-pointer font-controller text-14/16 font-semibold text-buddha-love-800 hover:text-artist-blue-900">
                Not Ready to Buy
              </p>
            </section>
          </div>
        </div>
      </>
    </div>
  );
};

export default BuyNowDropDown;
