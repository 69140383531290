/**
 * @description use for when modal have two action btn (mostly close and apply)
 */
import React from 'react';
import CosButton from '../CosButton';
import { Box, Divider, alpha, styled } from '@mui/material';
import { cn } from '../../libs/utils';
import { MiddleButtonConfig } from '../../libs/interface';

export const ModalActionClass =
  'flex h-[42px] border-0 border-solid border-t-[0.5px] !border-artist-blue-900/50 p-0 bg-white !bg-opacity-50 overflow-x-hidden w-full justify-between';

interface Props {
  cancelText?: string;
  closeText?: string;
  applyText?: string;
  showCancel?: boolean;
  disableApply?: boolean;
  applyWrapperClass?: string;
  onClose?: () => void;
  onCancel?: () => void;
  onApply?: () => void;
  middleButtons?: MiddleButtonConfig[];
}
const BpMiddleButton = styled(Box)(({}) => ({
  position: 'relative',
  width: 'calc(100% - 240px)',
}));
const ModalActionButtons: React.FC<Props> = props => {
  const {
    cancelText = 'Cancel',
    applyText = 'Apply & Close',
    closeText = 'Close',
    middleButtons = [],
    applyWrapperClass,
    onApply,
    onCancel,
    onClose,
    showCancel,
    disableApply,
  } = props;
  return (
    <>
      <CosButton
        size="medium"
        type="button"
        variant="text"
        onClick={() => (showCancel ? onCancel?.() : onClose?.())}
        className={
          middleButtons.length > 0
            ? 'min-h-auto m-0 w-[120px]'
            : closeText == 'Close Preview'
              ? 'min-h-auto w-[141px]'
              : 'min-h-auto !ml-[6px]'
        }
        color={showCancel ? 'secondary' : 'info'}
      >
        {showCancel ? cancelText : closeText}
      </CosButton>
      <Divider
        className="m-0 bg-artist-blue-900 opacity-50"
        flexItem
        orientation="vertical"
      />
      {middleButtons.length > 0 ? (
        middleButtons.map((button, index) => (
          <React.Fragment key={index}>
            <BpMiddleButton
              key={index}
              sx={{
                width: index === 0 ? `calc(100% - 240px)` : '175px',
              }}
            >
              <div
                className="absolute -bottom-3 right-0"
                style={{
                  width:
                    middleButtons.length > 1 && index === 0 ? '94px' : '120px',
                }}
              >
                <CosButton
                  size="medium"
                  type="button"
                  variant="text"
                  onClick={button.onAction}
                  className="min-h-auto m-0 "
                  disabled={disableApply}
                >
                  {button.text}
                </CosButton>
              </div>
            </BpMiddleButton>
            <Divider
              className="m-0 bg-artist-blue-900 opacity-50"
              flexItem
              orientation="vertical"
            />
          </React.Fragment>
        ))
      ) : (
        <>
          <BpMiddleButton>
            <div className="absolute -bottom-3 right-0 w-[120px]"></div>
          </BpMiddleButton>
          <Divider
            className="m-0 bg-artist-blue-900 opacity-50"
            flexItem
            orientation="vertical"
          />
        </>
      )}
      <CosButton
        size="medium"
        type="submit"
        variant="text"
        className={cn(applyWrapperClass, 'min-h-auto m-0 w-[120px]')}
        onClick={onApply}
        disabled={disableApply}
      >
        {applyText}
      </CosButton>
    </>
  );
};

export default ModalActionButtons;
