import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useLayoutEffect,
} from 'react';
import TimeClock, { ViewType } from './TimeClock';
import { Box, Divider, styled } from '@mui/material';
import { KEYBOARD_KEY, constant } from '../../../libs/constant';
import CosButton from '../../CosButton';
import { cn } from '../../../libs/utils';
import { MeridiemType } from '../MeridiemInput';
import CosFocusTrap from '../../CosFocusTrap';
import { getTimeDetails, TimeInputChangeRecord } from '../helper';
import { validateNumberValue } from '../../../libs/helper';

const MeridiemWrapper = styled(Box)({
  width: '24px',
  height: '24px',
  border: '1px solid #FFB92C',
  color: '#FFB92C',
  borderRadius: '99px',
  fontSize: '12px',
  cursor: 'pointer',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 3px',
  lineHeight: '17px',
  outline: 0,
  '&.active-meridiem': {
    backgroundColor: '#FFB92C',
    borderWidth: 0,
    color: '#ffffff',
  },
  '&[aria-label="inActive"]:hover, &[aria-label="inActive"]:focus': {
    backgroundColor: '#07B9EC',
    borderWidth: 0,
    color: '#ffffff',
  },
});

const ShowTimeWrapper = styled(Box)({
  height: 33,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#FFB92C',
  color: '#ffffff',
  fontSize: 15,
  fontWeight: 600,
});

const ShowTimeInput = styled('input')({
  all: 'unset',
  minWidth: 17,
  display: 'inline-flex',
  justifyContent: 'end',
  alignItems: 'center',
});

interface Props {
  onClose?: () => void;
  onApply?: (data: TimeInputChangeRecord) => void;
  defaultValue?: string;
  is24Hr?: boolean;
}

const CosClockPicker = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { defaultValue, onClose, onApply, is24Hr } = props;
  const [hours, setHours] = useState(is24Hr ? '00' : '12');
  const [min, setMin] = useState('00');
  const [activeView, setActiveView] = useState<ViewType | null>(null);
  const [activeMeridiem, setActiveMeridiem] = useState<MeridiemType>(
    is24Hr ? '' : 'AM',
  );
  const [isInitialState, setIsInitialState] = useState(true);

  const amRef = useRef<HTMLDivElement>(null);
  const pmRef = useRef<HTMLDivElement>(null);

  const updateColor = (event: any) => {
    const elem = event.target;
    elem.setAttribute('aria-label', 'inActive');
  };

  const handelFocus = () => {
    const amEle = amRef.current;
    const pmEle = pmRef.current;
    if (activeMeridiem === 'AM') {
      amEle?.setAttribute('aria-label', 'inActive');
    } else {
      pmEle?.setAttribute('aria-label', 'inActive');
    }
  };

  const setDefaultTime = () => {
    if (defaultValue) {
      const { hour12, hour24, meridiem, minute } = getTimeDetails(defaultValue);
      is24Hr ? setHours(hour24) : setHours(hour12);
      setMin(minute);
      !is24Hr && setActiveMeridiem(meridiem as MeridiemType);
    }
  };

  const handleClose = () => {
    if (isInitialState) {
      onClose?.();
    } else {
      setDefaultTime();
      setIsInitialState(true);
    }
  };

  const onKeyDown = (event: KeyboardEvent) => {
    const key = event.key;
    switch (key) {
      case KEYBOARD_KEY.esc:
        handleClose();
        break;
      case KEYBOARD_KEY.enter:
        !isInitialState && handleApply();
        break;

      default:
        break;
    }
  };

  const handleApply = () => {
    const time = is24Hr
      ? `${hours}:${min}`
      : `${hours}:${min} ${activeMeridiem}`;
    onApply?.({
      ...getTimeDetails(time),
      isValid: true,
    });
    onClose?.();
  };

  const handleInputChange = (event: any, type: ViewType) => {
    const value = event.target.value;
    if (type === 'hours') {
      const newValue = validateNumberValue({
        value,
        max: 12,
        min: 1,
        repeatedInLoop: true,
      });
      newValue && setHours(() => newValue);
    } else {
      const newValue = validateNumberValue({
        value,
        max: 59,
        min: 0,
        repeatedInLoop: true,
      });
      newValue && setMin(() => newValue);
    }
    setIsInitialState(false);
  };

  useEffect(() => {
    handelFocus();
  }, [amRef, pmRef]);

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [isInitialState, hours, min, activeMeridiem]);

  useLayoutEffect(() => {
    setDefaultTime();
  }, [defaultValue]);

  return (
    <CosFocusTrap>
      <Box
        ref={ref}
        className="h-[196px] w-[291px] bg-white shadow-6b"
        sx={{ borderRadius: constant.inputSmallRadius + 'px' }}
      >
        <ShowTimeWrapper>
          <div className="flex h-full w-[57px] items-center justify-center p-[10px]">
            <ShowTimeInput
              tabIndex={-1}
              value={hours}
              className={cn({
                'opacity-75': activeView === 'min',
              })}
              dir="rtl"
              onChange={event => handleInputChange(event, 'hours')}
              onFocus={() => setActiveView('hours')}
              onBlur={() => setActiveView(null)}
              min={0}
              type="number"
            />

            <span>:</span>
            <ShowTimeInput
              tabIndex={-1}
              value={min}
              className={cn({
                'opacity-75': activeView === 'hours',
              })}
              onChange={event => handleInputChange(event, 'min')}
              onFocus={() => setActiveView('min')}
              onBlur={() => setActiveView(null)}
              // min={0}
              type="number"
            />
            {!is24Hr && (
              <span className="self-end text-[10px]/[9px] font-normal">
                &nbsp;{activeMeridiem}
              </span>
            )}
          </div>
        </ShowTimeWrapper>
        <div className="flex justify-between px-[10px] py-[11px]">
          <TimeClock
            view="hours"
            is24Hr={is24Hr}
            onChange={value => {
              setHours(value);
              setIsInitialState(false);
            }}
            onFocus={() => setActiveView('hours')}
            onBlur={() => setActiveView(null)}
            value={hours}
            autoFocus={true}
            active={activeView === 'hours'}
          />
          {!is24Hr && (
            <Box className="flex flex-col items-center justify-between">
              <MeridiemWrapper
                ref={amRef}
                tabIndex={0}
                className={cn({
                  'active-meridiem pointer-events-none':
                    activeMeridiem === 'AM',
                })}
                onClick={event => {
                  setActiveMeridiem('AM');
                  setIsInitialState(false);
                }}
                aria-label={activeMeridiem === 'PM' ? 'inActive' : 'active'}
                onBlur={updateColor}
              >
                AM
              </MeridiemWrapper>
              <MeridiemWrapper
                ref={pmRef}
                tabIndex={0}
                className={cn({
                  'active-meridiem pointer-events-none':
                    activeMeridiem === 'PM',
                })}
                onClick={event => {
                  setActiveMeridiem('PM');
                  setIsInitialState(false);
                }}
                aria-label={activeMeridiem === 'AM' ? 'inActive' : 'active'}
                onBlur={updateColor}
              >
                PM
              </MeridiemWrapper>
            </Box>
          )}
          <TimeClock
            view="min"
            onChange={value => {
              setMin(value);
              setIsInitialState(false);
            }}
            onFocus={() => setActiveView('min')}
            onBlur={() => setActiveView(null)}
            value={min}
            active={activeView === 'min'}
          />
        </div>
        <Box className="flex h-[28px] border-0 border-t-[0.5px] border-solid border-artist-blue-900/50">
          <CosButton
            variant="text"
            size="small"
            color={isInitialState ? 'info' : 'secondary'}
            className="min-h-full w-1/2 text-sm/[normal]"
            tabIndex={0}
            onClick={handleClose}
          >
            {isInitialState ? 'Close' : 'Cancel'}
          </CosButton>

          <Divider
            className="opacity-1 bg-artist-blue-900/50"
            flexItem
            orientation="vertical"
          />
          <CosButton
            variant="text"
            size="small"
            className="min-h-full w-1/2 text-sm"
            tabIndex={0}
            disabled={isInitialState}
            onClick={handleApply}
          >
            Apply
          </CosButton>
        </Box>
      </Box>
    </CosFocusTrap>
  );
});

export default CosClockPicker;
