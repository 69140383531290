import { KEYBOARD_KEY } from '../../../../libs/constant';
import { moveTabToNextField } from '../../../../libs/helper';

export const handleMouseLeave = () => {
  const listItems = document.querySelectorAll('.MuiAutocomplete-option');
  listItems.forEach(item => {
    if (item?.getAttribute('aria-selected') === 'true') {
      item.classList.add('color-animation');
      item.classList.add('Mui-focused');
    } else {
      item.classList.remove('color-animation');
      item.classList.remove('Mui-focused');
    }
  });
};

export const handleMouseEnter = () => {
  const listItems = document.querySelectorAll('.MuiAutocomplete-option');
  listItems.forEach(item => {
    item.classList.remove('color-animation');
    item.classList.remove('Mui-focused');
  });
};

/**
 * Manages keyboard navigation and button states within an autocomplete or dropdown component.
 * Handles arrow key navigation, focus visibility, and button hover states.
 * @param event - The keyboard event triggering the function, typically from arrow key presses.
 * @param node - The HTMLElement containing the autocomplete or dropdown options.
 */
export const manageHighlightItem = (
  event: KeyboardEvent,
  node: HTMLElement,
  isMultiSelect: boolean,
) => {
  const currentTarget = event.target as Element;
  const isInsideAddNewComponent: boolean =
    !!currentTarget?.closest('#add-new-wrapper');

  // Extracting the pressed key from the event
  const key = event.key;

  // Checking if the down or up arrow key was pressed
  const isDownKey = key === KEYBOARD_KEY.down;
  const isUpKey = key === KEYBOARD_KEY.up;
  const isEnterKey = key === KEYBOARD_KEY.enter;

  if (isInsideAddNewComponent) return false;

  // Selecting all option elements within the provided node
  const allOptions = node.querySelectorAll('.MuiAutocomplete-option');

  // Selecting the apply button as HTMLButtonElement type
  const applyBtn = node.querySelector('#cos-apply') as HTMLButtonElement;
  const isApplyBtnDisable = applyBtn?.disabled;

  // Tracking the index of the currently focused option
  let activeIndex = -1;
  const totalLength = isApplyBtnDisable
    ? allOptions.length - 1
    : allOptions.length;

  // Iterating through all options to remove focus classes and find the active index
  allOptions.forEach((item: any, index) => {
    if (item.classList.contains('Mui-focusVisible')) {
      activeIndex = index;
    }
    item.classList.remove('Mui-focused', 'Mui-focusVisible');
  });

  let newIndex = activeIndex;

  if (isDownKey) {
    newIndex = (activeIndex + 1) % totalLength;
  } else if (isUpKey) {
    newIndex = (activeIndex - 1 + totalLength) % totalLength;
  } else if (isEnterKey) {
    event.preventDefault();
    const activeItem = allOptions[activeIndex];
    (activeItem as HTMLButtonElement)?.click();
    setTimeout(() => {
      if (
        !isMultiSelect &&
        activeItem?.getAttribute('id') !== 'addNew' &&
        activeItem
      ) {
        moveTabToNextField();
      }
    }, 10);
  }

  // Applying the focus classes to the new index
  allOptions[newIndex]?.classList.add('Mui-focused', 'Mui-focusVisible');

  // Scroll the new highlighted item into view if it's not visible
  const highlightedItem = allOptions[newIndex];
  highlightedItem?.scrollIntoView({
    block: 'nearest',
    inline: 'nearest',
    behavior: 'smooth',
  });
  // if (allOptions) {
  //   // Iterating through all options to remove focus classes and find the active index
  //   allOptions.forEach((item: any, index) => {
  //     item.classList.remove('Mui-focused');
  //     if (item.classList.contains('Mui-focusVisible')) {
  //       activeIndex = index;
  //     }
  //   });

  //   // Calculating the new index based on arrow key direction
  //   let newIndex;
  //   if (isDownKey) {
  //     newIndex = activeIndex === -1 ? -1 : activeIndex + 1;
  //   } else {
  //     newIndex = activeIndex === -1 ? -1 : activeIndex - 1;
  //   }

  //   const totalLength = isApplyBtnDisable
  //     ? allOptions.length - 1
  //     : allOptions.length;

  //   if (newIndex === -1) {
  //     allOptions[0].classList.add('Mui-focused', 'Mui-focusVisible');
  //     if (isDownKey) {
  //     } else {
  //       allOptions[1].classList.remove('Mui-focusVisible');
  //     }
  //   } else if (newIndex < totalLength) {
  //     const test = isDownKey ? newIndex - 1 : newIndex + 1;
  //     allOptions[test].classList.remove('Mui-focusVisible');
  //     allOptions[newIndex].classList.add('Mui-focused', 'Mui-focusVisible');
  //   } else if (newIndex === totalLength) {
  //     allOptions[newIndex].classList.remove('Mui-focusVisible');
  //     allOptions[0].classList.add('Mui-focused', 'Mui-focusVisible');
  //   }
  // }
};

// /**
//  * Adds or removes 'small-text-btn-hover' and 'Mui-focusVisible' classes to/from a button element.
//  * @param btn - The HTMLButtonElement to modify.
//  * @param type - Specifies whether to 'add' or 'remove' classes.
//  */
// const handleBtnFocus = (btn: HTMLButtonElement, type: 'add' | 'remove') => {
//   if (type === 'add') {
//     btn.classList.add('small-text-btn-hover', 'Mui-focusVisible');
//   } else {
//     btn.classList.remove('small-text-btn-hover');
//     btn.classList.remove('Mui-focusVisible');
//   }
// };
