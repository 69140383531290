import React from 'react';
import Sidebar, {
  PerformanceChip,
} from '../layout/masterlayout/components/Sidebar';
import StandardLayout from './StandardLayout';
import AppBar from '../layout/masterlayout/components/ui/AppBar';
import { Toolbar } from '@mui/material';
import { Box } from '@mui/material';
import { Company, Dashboard, UsersGroup } from '../assets/svgs';
import settings from '../assets/svgs/settings.svg';
import clsx from 'clsx';
import { cn, getLocalStorageItem } from '../libs/utils';
import AppBarLeftSideBarHeader from '../layout/masterlayout/components/ui/AppBarLeftSideBarHeader';
import { localStorageKey } from '../libs/constant';

const checkList = [
  {
    label: 'TBD',
  },
  {
    label: 'TBD',
  },
  {
    label: 'TBD',
  },
  {
    label: 'TBD',
  },
];

interface LeftSideComponent {
  className?: string;
}

interface IconComponent {
  isActive?: boolean;
}
export interface SidebarNavigationList {
  label: string;
  link: string;
  icon: ({ isActive }: IconComponent) => JSX.Element;
  isDisable?: boolean;
  leftSideComponent?: ({ className }: LeftSideComponent) => JSX.Element;
  badgeValue?: number;
  children?: any;
}

const sidebarList: SidebarNavigationList[] = [
  {
    label: 'TBD',
    link: 'tab1',
    icon: () => <Company />,
    isDisable: false,
    leftSideComponent: ({ className }) => (
      <div
        className={clsx(
          'flex size-7 items-center justify-center rounded-full border border-solid border-error-900',
          className,
        )}
      >
        <span className="text-10/22 text-error-900">20%</span>
      </div>
    ),
  },
  {
    label: 'TBD',
    link: 'tab2',
    icon: () => <UsersGroup />,
    isDisable: false,
    badgeValue: 1,
    children: [
      {
        label: 'SUB TBD1',
        link: 'sub-tab1',
        isDisable: false,
        children: [
          {
            label: 'TBD1',
            link: 'tab1',
            isDisable: false,
          },
          {
            label: 'TBD2',
            link: 'tab2',
            isDisable: false,
          },
        ],
      },
      {
        label: 'SUB TBD2',
        link: 'sub-tab2',
        isDisable: false,
      },
    ],
  },
  {
    label: 'TBD',
    link: 'tab3',
    icon: ({ isActive }) => (
      <img
        src={settings}
        style={{ width: 16, height: 16 }}
        className={cn('invert-70', {
          '!invert': isActive,
        })}
      />
    ),
    isDisable: false,
  },
  {
    label: 'TBD',
    link: 'tab4',
    icon: () => <Dashboard />,
    isDisable: true,
    leftSideComponent: () => <PerformanceChip>Performance</PerformanceChip>,
  },
];

const StandardLeftPanel = () => {
  const [open, setOpen] = React.useState(true);

  const toggleSideBar = () => {
    setOpen(preValue => !preValue);
  };
  const userDetails: any = getLocalStorageItem(localStorageKey.USER_DATA);
  return (
    <StandardLayout>
      <AppBar
        open={true}
        className="standard-header"
        sx={{
          position: 'relative',
          marginLeft: 0,
        }}
      >
        <Toolbar className="p-0">
          <AppBarLeftSideBarHeader className="bg-white">
            <p className="p-2 text-center text-18/21 text-dark-blue-900">
              {userDetails?.companyName || '%COMPANY NAME%'}
            </p>
          </AppBarLeftSideBarHeader>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: 'flex',
          height: 'calc(100vh - 100px)',
          '& .MuiDrawer-root': {
            height: 'auto',
          },
          '& .MuiPaper-root.MuiDrawer-paper': {
            position: 'unset',
            left: '0px !important',
            height: '100%',
            marginTop: '0',
          },
        }}
      >
        <Sidebar
          open={open}
          list={sidebarList}
          isCosLogo={true}
          toggleSideBar={toggleSideBar}
          buyNowCheckList={checkList}
        />
      </Box>
    </StandardLayout>
  );
};

export default StandardLeftPanel;
