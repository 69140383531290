import * as React from 'react';
import { styled } from '@mui/system';
import { alpha } from '@mui/material';
import useNumberHook from './useNumberHook';
import { CosNUmberProps } from './number';
import IncrementDecrementBtn from './IncrementDecrementBtn';

const CosSecondaryNumberField = React.forwardRef(function CustomNumberInput(
  props: CosNUmberProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const {
    min = 0,
    max = 100000,
    defaultValue = 0,
    fieldType = 'arrow',
    onChange,
  } = props;
  const {
    onDecrement,
    onIncrement,
    value,
    handleChange,
    onKeyDown,
    handleMouseDown,
    handleMouseUp,
    disableDecrement,
    disableIncrement,
    numberInputRef,
    numberFieldWidth,
  } = useNumberHook({
    min,
    max,
    fieldType,
    defaultValue,
    onChange,
  });
  return (
    <StyledInputRoot ref={ref} sx={{ width: numberFieldWidth }}>
      <StyledInput
        type="number"
        {...props}
        value={value}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        ref={numberInputRef}
      />
      <IncrementDecrementBtn
        onDecrement={onDecrement}
        onIncrement={onIncrement}
        handleMouseDown={handleMouseDown}
        handleMouseUp={handleMouseUp}
        isDisableDecrement={disableDecrement}
        isDisableIncrement={disableIncrement}
      />
    </StyledInputRoot>
  );
});

export default CosSecondaryNumberField;

const StyledInputRoot = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  height: '19px',
  boxShadow: '0px 0px 3px #A8B1B8',
  borderRadius: 3,
  minWidth: '75px',
  maxWidth: '75px',
  paddingRight: '5px',
}));

const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  fontSize: '12px',
  fontWeight: '600',
  background: '#ffffff',
  textAlign: 'center',
  outline: 0,
  color: '#001F38',
  border: 'none',
  height: '100%',

  '&:hover': {
    color: alpha('#001F38', 0.75),
  },

  '&:focus': {
    color: alpha('#001F38', 1),
  },
}));
