import React, { useEffect, useRef, useState } from 'react';
import { TImeFieldWrapper } from './TImeFieldWrapper';
import ScheduleInput from './ScheduleInput';
import {
  convert12hrTo24hr,
  moveTabToNextField,
  moveTabToPrevField,
} from '../../libs/helper';
import MeridiemInput, { MeridiemType } from './MeridiemInput';
import { KEYBOARD_KEY } from '../../libs/constant';
import { Clock } from '../../assets/svgs';
import CosClockPicker from './picker/CosClockPicker';
import { cosPadStart, time12hRegex } from '../../libs/utils';
import {
  getTimeDetails,
  set12hrFormate,
  TimeInputChangeRecord,
} from './helper';

interface Props {
  onChange?: (data: TimeInputChangeRecord) => void;
  defaultValue?: string;
  is24HrFormate?: boolean;
  className?: string;
}

const CosTimeInput: React.FC<Props> = ({
  onChange,
  is24HrFormate,
  defaultValue,
  className,
}) => {
  if (defaultValue && !is24HrFormate && !time12hRegex(defaultValue)) {
    throw new Error(
      'Invalid 12h time format. Time should be in HH:MM AM/PM format.',
    );
  }

  const [hours, setHours] = useState('');
  const [mins, setMins] = useState('');
  const [showClockPicker, setClockPicker] = useState(false);
  const [meridiem, setMeridiem] = useState<MeridiemType>('');
  const ref = useRef<HTMLDivElement>(null);

  const handleMinKeyDown = (event: any) => {
    const key = event.key;
    const isFirst = event.target.getAttribute('id') === 'mins-0';

    switch (key) {
      case KEYBOARD_KEY.backspace:
        !event.target.value &&
          isFirst &&
          setTimeout(() => {
            moveTabToPrevField();
          });
        break;

      default:
        break;
    }
  };

  const isValidTime = (hr: string, min: string, meridiem: MeridiemType) => {
    const isValidHrMin =
      !!(hr.toString().length === 2) && !!(min.toString().length === 2);
    if (is24HrFormate) {
      return isValidHrMin;
    } else {
      return isValidHrMin && !!meridiem.toString();
    }
  };

  const handelChange = (data: string, type: 'hr' | 'min', event: any) => {
    if (type === 'hr') {
      const setHour = [1, 2, 3, 4, 5];
      if (
        meridiem == '' &&
        parseInt(data) != 0 &&
        event?.key !== KEYBOARD_KEY.backspace
      ) {
        if (setHour.includes(parseInt(data))) {
          setMeridiem('PM');
        } else {
          setMeridiem('AM');
        }
      }
      onChange?.({
        time12: `${cosPadStart(data)}:${cosPadStart(mins)} ${meridiem}`,
        hour12: data,
        minute: mins,
        meridiem: meridiem,
        is24h: !!is24HrFormate,
        hour24: convert12hrTo24hr(set12hrFormate(data, mins, meridiem)).hours,
        time24: `${convert12hrTo24hr(set12hrFormate(data, mins, meridiem)).hours}:${convert12hrTo24hr(set12hrFormate(data, mins, meridiem)).min}`,
        isValid: isValidTime(data, mins, meridiem),
      });
    } else {
      onChange?.({
        time12: `${cosPadStart(hours)}:${cosPadStart(data)} ${meridiem}`,
        hour12: hours,
        minute: data,
        meridiem: meridiem,
        is24h: !!is24HrFormate,
        hour24: convert12hrTo24hr(set12hrFormate(hours, data, meridiem)).hours,
        time24: `${convert12hrTo24hr(set12hrFormate(hours, data, meridiem)).hours}:${convert12hrTo24hr(set12hrFormate(hours, data, meridiem)).min}`,
        isValid: isValidTime(hours, data, meridiem),
      });
    }
  };

  const toggleClockPicker = () => setClockPicker(() => !showClockPicker);
  const closeClockPicker = () => {
    setClockPicker(false);
  };

  //* Split default into hours, min and meridiem
  useEffect(() => {
    const splitter: string[] | undefined = defaultValue?.split(' ');
    if (splitter?.length) {
      const [time, meridiem]: string[] = splitter;
      const [hours, mins] = time.split(':');
      setMeridiem(meridiem as MeridiemType);
      setHours(hours);
      setMins(mins);
    }
  }, [defaultValue]);

  const handleHourChange = (hour: string, event: any) => {
    setHours(hour);
    handelChange(hour, 'hr', event);
  };

  const handleMinChange = (min: string, event: any) => {
    setMins(min);
    handelChange(min, 'min', event);
  };

  return (
    <>
      <TImeFieldWrapper ref={ref} className={className}>
        <ScheduleInput
          max={is24HrFormate ? 23 : 12}
          min={is24HrFormate ? 0 : 1}
          id="hours"
          value={hours}
          onChange={handleHourChange}
          onComplete={() => moveTabToNextField(ref.current as HTMLElement)}
        />
        <span className="w-3 text-center font-semibold">:</span>
        <ScheduleInput
          max={59}
          id="mins"
          value={mins}
          onChange={handleMinChange}
          onComplete={() =>
            !is24HrFormate && moveTabToNextField(ref.current as HTMLElement)
          }
          onEmpty={() => moveTabToPrevField(ref.current as HTMLElement)}
          onKeyDown={handleMinKeyDown}
        />

        {!is24HrFormate && (
          <MeridiemInput
            meridiem={meridiem}
            setMeridiem={setMeridiem}
            onFocus={() => {
              meridiem === '' && hours.length === 2 && setMeridiem('AM');
            }}
            // onChange={meridiem => {
            //   setMeridiem(meridiem);
            //   onChange?.({
            //     value: `${cosPadStart(hours)}:${cosPadStart(mins)} ${meridiem}`,
            //     hr: hours,
            //     min: mins,
            //     meridiem: meridiem,
            //     is24HrFormate: !!is24HrFormate,
            //     hr_24: convert12hrTo24hr(set12hrFormate(hours, mins, meridiem))
            //       .hours,
            //     min_24: convert12hrTo24hr(set12hrFormate(hours, mins, meridiem))
            //       .min,
            //     value_24: `${convert12hrTo24hr(set12hrFormate(hours, mins, meridiem)).hours}:${convert12hrTo24hr(set12hrFormate(hours, mins, meridiem)).min}`,
            //     isValid: isValidTime(hours, mins, meridiem),
            //   });
            // }}
          />
        )}

        <span className="ml-[13px] inline-flex" onClick={toggleClockPicker}>
          <Clock className="cursor-pointer hover:text-buddha-love-800" />
        </span>

        {showClockPicker && (
          <div
            className={
              className
                ? 'absolute left-0 top-0 z-10'
                : 'absolute left-0 top-0 z-10 mt-[36px]'
            }
          >
            <CosClockPicker
              onClose={closeClockPicker}
              is24Hr={is24HrFormate}
              defaultValue={
                is24HrFormate
                  ? `${hours || '00'}:${mins || '00'}`
                  : `${hours || '12'}:${mins || '00'} ${meridiem || 'AM'}`
              }
              onApply={timeDetails => {
                is24HrFormate
                  ? setHours(timeDetails.hour24)
                  : setHours(timeDetails.hour12);
                setMeridiem(timeDetails.meridiem);
                setMins(timeDetails.minute);
                onChange?.({
                  ...timeDetails,
                });
              }}
            />
          </div>
        )}
      </TImeFieldWrapper>
    </>
  );
};

export default CosTimeInput;
