import React, { useState, useEffect, useRef } from 'react';
import './clockPicker.css';
import { cn, cosPadStart } from '../../../libs/utils';
import { Box, BoxProps } from '@mui/material';
import { KEYBOARD_KEY } from '../../../libs/constant';

export type ViewType = 'hours' | 'min';

interface Props extends Omit<BoxProps, 'onChange'> {
  view?: ViewType;
  is24Hr?: boolean;
  value?: string;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  active?: boolean;
}

const TimeClock: React.FC<Props> = props => {
  const {
    view = 'hours',
    onChange,
    value,
    autoFocus,
    active,
    is24Hr,
    ...rest
  } = props;

  if (view !== 'hours' && !!is24Hr?.toString()) {
    throw new Error(
      'Invalid Props! is24Hr only can used when view type is hours',
    );
  }

  const clockRef = useRef<HTMLDivElement>(null);

  const isHoursView = view === 'hours';
  const [selectedNumber, setSelectedNumber] = useState(
    isHoursView ? '12' : '00',
  );
  const [isDragging, setIsDragging] = useState(false);
  const hoursWheel = [
    '12',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
  ];
  const minWheel = Array.from({ length: 60 }, (_, i) => cosPadStart(i));
  // const hours24Wheel = Array.from({ length: 24 }, (_, i) => cosPadStart(i));
  const hours24Wheel = [
    '00',
    '1',
    '13',
    '2',
    '14',
    '3',
    '15',
    '4',
    '16',
    '5',
    '17',
    '6',
    '18',
    '7',
    '19',
    '8',
    '20',
    '9',
    '21',
    '10',
    '22',
    '11',
    '23',
    '12',
  ];
  const numbers = isHoursView
    ? !!is24Hr
      ? hours24Wheel
      : hoursWheel
    : minWheel;

  const selectByClick = (value: string) => {
    setSelectedNumber(value);
    onChange?.(value);
  };

  const onKeyDown = (event: KeyboardEvent) => {
    const key = event.key;
    const currentIndex = numbers.indexOf(selectedNumber);
    const isLastItem = currentIndex + 1 === numbers.length;
    const isFirstItem = currentIndex === 0;
    let ext = is24Hr ? 2 : 1;
    switch (key) {
      case KEYBOARD_KEY.up:
        if (isLastItem) {
          const firstValue = numbers[0];
          setSelectedNumber(() => firstValue);
          onChange?.(cosPadStart(firstValue));
        } else {
          const nextValue = numbers[currentIndex + ext];

          if (currentIndex === 22) {
            setSelectedNumber(() => '12');
            onChange?.(cosPadStart('00'));
          } else {
            setSelectedNumber(() => nextValue);
            onChange?.(cosPadStart(nextValue));
          }
        }

        break;
      case KEYBOARD_KEY.down:
        console.log('currentIndex', currentIndex);
        if (isFirstItem) {
          setSelectedNumber(() => numbers[numbers.length - ext]);
          onChange?.(numbers[numbers.length - ext]);
        } else {
          setSelectedNumber(() => numbers[currentIndex - ext]);
          onChange?.(numbers[currentIndex - ext]);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    value && setSelectedNumber(value);
  }, [value]);

  useEffect(() => {
    clockRef.current?.addEventListener('keydown', onKeyDown);
    return () => clockRef.current?.removeEventListener('keydown', onKeyDown);
  }, [clockRef, selectedNumber]);

  const handleMouseDown = (event: React.MouseEvent) => {
    setIsDragging(true);
    handleMouseMove(event);
  };

  const handleMouseMove = (event: MouseEvent | React.MouseEvent) => {
    if (!isDragging) return;

    const rect = clockRef.current!.getBoundingClientRect();

    const dialRadius = rect.width / 2;
    const x = event.clientX - rect.left - dialRadius;
    const y = event.clientY - rect.top - dialRadius;

    let radian = Math.atan2(y, x);
    if (radian < 0) {
      radian += 2 * Math.PI;
    }

    const unit = isHoursView
      ? is24Hr
        ? Math.PI / 12
        : Math.PI / 6
      : Math.PI / 30;
    const value = isHoursView
      ? is24Hr
        ? (Math.round(radian / unit) + 6) % 24 || 0
        : (Math.round(radian / unit) + 3) % 12 || 12
      : (Math.round(radian / unit) + 15) % 60 || 0;
    const paddedValue = cosPadStart(value);

    setSelectedNumber(paddedValue);
    onChange?.(paddedValue);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, clockRef]);

  const deg = view === 'hours' ? (is24Hr ? 15 : 30) : 6;

  return (
    <Box
      className={cn('picker-dial', {
        'active-dial': active,
      })}
      tabIndex={0}
      ref={clockRef}
      onMouseDown={handleMouseDown}
      {...rest}
    >
      {numbers.map((value, index) => {
        const style = {
          '--i': index,
          '--deg': `${deg}deg`,
          '--nagDeg': `-${deg}deg`,
        } as React.CSSProperties;
        const isActive = +selectedNumber === +value;
        const hideExtraMin = view === 'min' && index % 5 !== 0;
        const hideExtraHr = view === 'hours' && is24Hr && index % 2 !== 0;

        return (
          <div
            key={`${index}`}
            className={cn('dial-sector', {
              'min-dial-sector': view === 'min',
              'hide-min-dial-sector': hideExtraMin,
              // 'hide-hour-dial-sector': hideExtraHr,
              'hr-24-dial-sector': hideExtraHr,
            })}
            style={style}
            onClick={() => selectByClick(cosPadStart(value))}
          >
            <span
              className={cn('sector-val', {
                'active-number': isActive,
                'sector-val-hover': !isDragging,
                'min-active-number': isActive && hideExtraMin,
                'min-sector-val': view === 'min',
                invisible: hideExtraMin,
              })}
            >
              {view === 'min' ? value : +value}
            </span>

            {isActive && (
              <div className="sector-line-wrapper">
                <div className="sector-line"></div>
              </div>
            )}
          </div>
        );
      })}
    </Box>
  );
};

export default TimeClock;
