import React, { useState } from 'react';
import { CosAutoComplete, CosInput } from '../components/formikForms/ui';
import StandardLayout from './StandardLayout';
import { CosOptions } from '../libs/interface';
import { CosTimeInput } from '../components/time';
import { TimeOptions, weekOptions } from '../modules/schedule/ScheduleOption';
import { Form, Formik } from 'formik';
import { FormAutoComplete } from '../components/formikForms';
import { CosButton } from '../components';
import * as Yup from 'yup';

const optionWithMoreItem: CosOptions[] = [
  {
    label: 'Business Checking',
    value: 'Business Checking',
  },
  {
    label: 'Business Savings',
    value: 'Business Savings',
  },
  {
    label: 'Link',
    value: 'Link',
  },
  {
    label: 'Departments',
    value: 'Departments',
  },
  {
    label: 'Resource',
    value: 'Resource',
  },
  {
    label: 'Work',
    value: 'Work',
  },
  {
    label: 'Schedules',
    value: 'Schedules',
  },
  {
    label: 'Positions',
    value: 'Positions',
  },
];

const option: CosOptions[] = [
  {
    label: 'Business Checking',
    value: 'Business Checking',
  },
  {
    label: 'Business Savings',
    value: 'Business Savings',
  },
  {
    label: 'Link',
    value: 'Link',
  },
  {
    label: 'TBD-1',
    value: 'TBD-1',
  },
  {
    label: 'TBD-2',
    value: 'TBD-2',
  },
  {
    label: 'TBD',
    value: 'TBD-3',
  },
];

const StandardDropdownPage = () => {
  const labelProps = {
    className: 'text-white',
  };

  const [showPreview, setShowPreview] = useState(false);

  return (
    <StandardLayout>
      <div className="bg-white-whale-500 p-2">
        <div className="space-x-4">
          <p className="mt-0">Dark</p>
          <div className="h-[250px] bg-dark-blue-900 p-2">
            <p className="m-0 my-3 text-white">In Popup</p>
            <div className="flex  gap-5">
              <CosAutoComplete
                addNewComponent={({
                  activateAddButton,
                  deactivateAddButton,
                }) => (
                  <CosTimeInput
                    className="w-[270px] !justify-start pl-[10px]"
                    onChange={({ isValid }) =>
                      isValid ? activateAddButton() : deactivateAddButton()
                    }
                  />
                )}
                options={TimeOptions}
                placeholder="Select Start Time"
                size="small"
                label="Time Day Starts"
                labelProps={labelProps}
                withElevation={false}
                isPopupInput={true}
              />
              <CosAutoComplete
                options={option}
                placeholder="Select Account Type"
                size="small"
                // className="w-smallInput"
                label="Account Type"
                labelProps={labelProps}
                withElevation={false}
                isPopupInput={true}
              />

              <CosAutoComplete
                multiple
                options={weekOptions}
                size="small"
                label="Week Day"
                placeholder="Check All that Apply"
                labelProps={labelProps}
                withElevation={false}
                isPopupInput={true}
                // open
              />
            </div>

            <p className="m-0 my-3 text-white">In Page</p>
            <div className="flex  gap-5">
              <CosAutoComplete
                addNewComponent={({
                  activateAddButton,
                  deactivateAddButton,
                }) => (
                  <CosTimeInput
                    className="w-[270px] !justify-start pl-[10px]"
                    onChange={({ isValid }) =>
                      isValid ? activateAddButton() : deactivateAddButton()
                    }
                  />
                )}
                options={TimeOptions}
                placeholder="Select Start Time"
                size="small"
                label="Time Day Starts"
                labelProps={labelProps}
                arrowType="expand"
              />
              <CosAutoComplete
                options={option}
                placeholder="Select Account Type"
                size="small"
                // className="w-smallInput"
                label="Account Type"
                labelProps={labelProps}
                withElevation={false}
                arrowType="expand"
              />
            </div>
          </div>
        </div>

        <div className="space-x-4">
          <p>Light</p>
          <div className="h-[250px] bg-white p-2">
            <p className="m-0 my-3">In Popup</p>
            <div className="flex  gap-5">
              <CosAutoComplete
                addNewComponent={({
                  activateAddButton,
                  deactivateAddButton,
                }) => (
                  <CosTimeInput
                    className="w-[270px] !justify-start pl-[10px]"
                    onChange={({ isValid }) =>
                      isValid ? activateAddButton() : deactivateAddButton()
                    }
                  />
                )}
                options={TimeOptions}
                placeholder="Select Start Time"
                size="small"
                label="Time Day Starts"
                isPopupInput={true}
              />
              <CosAutoComplete
                options={option}
                placeholder="Select Account Type"
                size="small"
                // className="w-smallInput"
                label="Account Type"
                isPopupInput={true}
              />
            </div>
            <p className="m-0 my-3">In page</p>
            <div className="flex gap-5">
              <CosAutoComplete
                addNewComponent={({
                  activateAddButton,
                  deactivateAddButton,
                }) => (
                  <CosTimeInput
                    className="w-[270px] !justify-start pl-[10px]"
                    onChange={({ isValid }) =>
                      isValid ? activateAddButton() : deactivateAddButton()
                    }
                  />
                )}
                options={TimeOptions}
                placeholder="Select Start Time"
                size="small"
                label="Time Day Starts"
                arrowType="expand"
              />
              <CosAutoComplete
                options={option}
                placeholder="Select Account Type"
                size="small"
                // className="w-smallInput"
                label="Account Type"
                arrowType="expand"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="hidden">
        <Formik
          initialValues={{
            multiSelect: [
              // {
              //   label: 'Sunday',
              //   value: 'Sunday',
              //   sortId: 1,
              // },
              // 'Sunday',
            ],
            singleSelect: '',
          }}
          validationSchema={Yup.object().shape({
            multiSelect: Yup.array().required(),
            singleSelect: Yup.object().required(),
          })}
          onSubmit={value => console.log('value', value)}
        >
          {({ values, resetForm }) => {
            console.log('values-->1', values);
            return (
              <Form>
                <div className="min-h-[128px]">
                  {showPreview ? (
                    <></>
                  ) : (
                    <>
                      <FormAutoComplete
                        name="multiSelect"
                        multiple
                        options={weekOptions}
                        label="Multi Select"
                        placeholder="Select item"
                      />
                      <FormAutoComplete
                        name="singleSelect"
                        options={weekOptions}
                        label="Single Select"
                        placeholder="Select item"
                        className="mt-3"
                      />
                    </>
                  )}
                </div>
                <CosButton
                  onClick={() => setShowPreview(!showPreview)}
                  className="mt-4"
                >
                  Show Preview
                </CosButton>
                <CosButton
                  type="button"
                  onClick={() => resetForm()}
                  className="mx-5 mt-4"
                >
                  Reset
                </CosButton>
              </Form>
            );
          }}
        </Formik>
      </div>
    </StandardLayout>
  );
};

export default StandardDropdownPage;
