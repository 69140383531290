import { Box, tooltipClasses } from '@mui/material';
import React from 'react';
import CosTooltip from './CosTooltip';
import { FloatingAddButtonHover, FloatingAddButtonIcon } from '../assets/svgs';

const FloatingAddButton = () => {
  return (
    <CosTooltip
      title="Add %Dynamic%"
      placement="left"
      textDecoration="underline"
      tooltipsx={{
        fontSize: '12px !important',
        minWidth: '88px !important',
      }}
      yOffset={-5}
      onHoverIcon={<FloatingAddButtonHover />}
    >
      <FloatingAddButtonIcon />
    </CosTooltip>
  );
};

export default FloatingAddButton;
