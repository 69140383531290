import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { ModalHeading } from '../../../libs/constant';
import { CosDataView, ModalActionButtons } from '../../../components/dialogBox';
import useCreateSchedule from '../hooks/useCreateSchedule';
import moment from 'moment';
import PreivewScheduleForm from './PreivewScheduleForm';
import CreateEditScheduleForm from './CreateEditScheduleForm';
import { TempScheduleData } from '../ScheduleOption';
import { MiddleButtonConfig } from '../../../libs/interface';
import DeleteSchedule from './DeleteSchedule';

interface Props {
  onClose?: () => void;
  isOpen: boolean;
}
const ViewSchedule: React.FC<Props> = ({ isOpen, onClose }) => {
  const [isEdit, setEdit] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { initialValues, validationSchema } = useCreateSchedule();

  const handlePagination = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const updateDynamicScheduleName = (values: any, setFieldValue: any) => {
    let data: any[] = [];
    if (Array.isArray(values.workingDay)) {
      data.push(values.workingDay.map((item: any) => item.value.slice(0, 3)));
    }
    if (
      values.workingDay.length > 0 &&
      values.startTime.value &&
      values.workHours.value
    ) {
      let scheduleName = '';
      const startTime = moment(values.startTime.value, 'hh:mm A');
      const workHours = parseInt(values.workHours.value.split(' ')[0]);
      const endTime = startTime
        .clone()
        .add(workHours, 'hours')
        .format('hh:mm A');

      scheduleName =
        `${values.workingDay.length} Day Workweek, ` +
        `${data[0][0]} - ${data[0][data[0].length - 1]}, ` +
        `${values.startTime.value} to ${endTime}`;

      setFieldValue('noOfDays', `${values.workingDay.length}`);
      setFieldValue('scheduleName', scheduleName);
    }
  };
  const handleEditForm = () => {
    setEdit(true);
  };

  const handleEditClose = (resetForm: () => void) => {
    resetForm();
    setEdit(false);
  };

  const handleDeleteForm = () => {
    setDelete(true);
  };

  const handleDeleteClose = () => {
    setDelete(false);
  };

  const handleSubmit = () => {
    onClose?.();
    // Handle form submission logic here
  };

  const handleClose = () => {
    onClose?.();
  };

  const middleButtons: MiddleButtonConfig[] = [
    { text: 'Save', onAction: handleClose },
    { text: 'Save & Next', onAction: handleClose, disabled: false },
  ];

  const singleMiddleButtons: MiddleButtonConfig[] = [
    { text: 'Edit', onAction: handleEditForm },
  ];
  return (
    <>
      <Formik
        initialValues={TempScheduleData[currentPage - 1] || initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {formik => {
          const { values, dirty, isValid, setValues, resetForm } = formik;
          return (
            <Form>
              <CosDataView
                open={isOpen}
                heading={ModalHeading.viewSchedule}
                isPagination={true}
                disableApply={dirty}
                currentPage={currentPage}
                totalPage={TempScheduleData.length}
                handlePagination={handlePagination}
                dialogAction={
                  isEdit ? (
                    <ModalActionButtons
                      onClose={() => handleEditClose(resetForm)}
                      applyText={'Save & Close'}
                      middleButtons={middleButtons}
                      disableApply={!(dirty && isValid)}
                      onApply={() => handleSubmit()}
                    />
                  ) : (
                    <ModalActionButtons
                      onApply={handleDeleteForm}
                      applyWrapperClass={'text-error-900'}
                      onClose={handleClose}
                      applyText={'Delete'}
                      middleButtons={singleMiddleButtons}
                    />
                  )
                }
              >
                {isEdit ? (
                  <CreateEditScheduleForm
                    formikConfig={formik}
                    updateDynamicScheduleName={updateDynamicScheduleName}
                  />
                ) : isDelete ? (
                  <DeleteSchedule
                    open={isDelete}
                    onClose={() => setDelete(false)}
                  />
                ) : (
                  <PreivewScheduleForm previewData={values} />
                )}
              </CosDataView>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ViewSchedule;
