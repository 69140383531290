import Navigator from '../layout/masterlayout/components/ui/Navigator';
import StandardLayout from './StandardLayout';
import AppBar from '../layout/masterlayout/components/ui/AppBar';
import { Toolbar } from '@mui/material';
import { MyCompany } from '../pages/myCompany';
import { RouteObject } from 'react-router';

const companyRouterHandler: RouteObject = {
  path: '/menus',
  element: <MyCompany />,
  children: [
    {
      path: '',
      element: <></>,
    },
    {
      path: 'profile',
      element: <></>,
    },
    {
      path: 'database',
      element: <></>,
      children: [
        {
          path: 'work_schedules',
        },
        {
          path: 'holidays',
        },
      ],
    },
    {
      path: 'internal_resource',
      element: <></>,
    },
    {
      path: 'default_setting',
      element: <></>,
    },
  ],
};

const StandardTopHeader = () => {
  return (
    <StandardLayout>
      <AppBar
        open={true}
        className="standard-header"
        sx={{
          position: 'relative',
          marginLeft: 0,
        }}
      >
        <Toolbar className="p-0">
          <Navigator
            className="flex-grow"
            navigatorList={companyRouterHandler}
            isSearchbar={true}
            sx={{
              '& li': {
                width: 'auto',
              },
            }}
          />
        </Toolbar>
      </AppBar>
    </StandardLayout>
  );
};

export default StandardTopHeader;
