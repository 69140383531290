import React, {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useState,
} from 'react';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import ChevronRight from '@mui/icons-material/ChevronRight';
import {
  usePopupState,
  bindHover,
  bindFocus,
  bindMenu,
  PopupState,
} from 'material-ui-popup-state/hooks';
import NaVigatorTextItem from './NaVigatorTextItem';
import { Box, ListItem, MenuProps, alpha } from '@mui/material';
import clsx from 'clsx';
import { constant } from '../../../../libs/constant';
import {
  FilledStar,
  OutlineStar,
  StarOutlineIcon,
} from '../../../../assets/svgs';
import { CosTooltip } from '../../../../components';
import { RouteObject, useLocation } from 'react-router';
import { cn, convertRouterToTitle } from '../../../../libs/utils';
import { NavLink } from 'react-router-dom';

interface CascadingContextType {
  parentPopupState: any;
  rootPopupState: any;
}

export type CascadingHoverListProps = RouteObject;

interface CascadingHoverMenusProps {
  list: any;
  onClick?: () => void;
  onChildItemClick?: (childItem: CascadingHoverListProps) => void;
  isLeftMenu?: boolean;
  open?: boolean;
}

const CascadingContext = createContext<CascadingContextType>({
  parentPopupState: null,
  rootPopupState: null,
});

const CascadingMenuItem: React.FC<MenuItemProps> = ({ onClick, ...props }) => {
  const { rootPopupState } = useContext(CascadingContext);
  if (!rootPopupState) throw new Error('must be used inside a CascadingMenu');
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      rootPopupState.close(event);
      if (onClick) onClick(event);
    },
    [rootPopupState, onClick],
  );

  return <MenuItem {...props} onClick={handleClick} />;
};

const CascadingSubmenu: React.FC<
  Omit<MenuProps & { popupId: string; title: string }, 'open'>
> = ({ title, popupId, ...props }) => {
  const { parentPopupState } = useContext(CascadingContext);
  const popupState = usePopupState({
    popupId,
    variant: 'popover',
    parentPopupState,
  });
  return (
    <>
      {/* <MenuItem > */}
      <span
        className="w-full !p-0"
        {...bindHover(popupState)}
        {...bindFocus(popupState)}
      >
        {title}
      </span>
      {/* <ChevronRight /> */}
      {/* </MenuItem> */}
      <CascadingMenu
        {...props}
        classes={{ ...props.classes }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        popupState={popupState}
      />
    </>
  );
};

const CascadingMenu: React.FC<
  Omit<MenuProps & { popupState: PopupState }, 'open'>
> = ({ popupState, sx, ...props }) => {
  const { rootPopupState } = useContext(CascadingContext);
  const context = useMemo(
    () => ({
      rootPopupState: rootPopupState || popupState,
      parentPopupState: popupState,
    }),
    [rootPopupState, popupState],
  );

  return (
    <CascadingContext.Provider value={context}>
      <HoverMenu
        className="cos-dark-scrollbar"
        sx={{
          //* paper-Wrapper
          '& .MuiPaper-root': {
            boxShadow: `0 0 12px ${alpha('#07B9EC', 0.5)}`,
            borderRadius: 0,
            minWidth: '130px',
            marginLeft: constant.navBarItemBorderWiderSpace + 'px',
            backgroundColor: '#001F38',
          },

          //* list-Wrapper (ul)
          '& .MuiList-root': {
            gap: '0px',
            display: 'flex',
            padding: 0,
            flexDirection: 'column',
            maxHeight: '232px',
            overflow: 'auto',
          },

          // *list (li)
          '& .MuiMenuItem-root': {
            color: alpha('#ffffff', 0.75),
            fontSize: '12px',
            padding: '5.5px 11px 5.5px 10px',
            justifyContent: 'space-between',
            gap: '6px',
            width: 'auto',
            '&:hover': {
              color: '#07B9EC',
              opacity: 1,
            },
          },
          ...sx,
        }}
        {...props}
        {...bindMenu(popupState)}
        // open={true} // ! TODO : uncomment this if for update UI
      />
    </CascadingContext.Provider>
  );
};

const CascadingHoverMenus: React.FC<CascadingHoverMenusProps> = ({
  list,
  onClick,
  onChildItemClick,
  isLeftMenu = false,
  open = true,
}) => {
  const popupState = usePopupState({
    popupId: 'demoMenu',
    variant: 'popover',
  });
  let location = useLocation();
  const mainPath = location.pathname.split('/').filter(Boolean);

  return (
    <>
      {isLeftMenu ? (
        <>
          <NavLink
            to={list.link}
            className={cn('all-unset no-underline', {
              'pointer-events-none': list.isDisable,
            })}
            key={`${list.label}-sidebar-list`}
          >
            {({ isActive }) => {
              return (
                <ListItem
                  disablePadding
                  disabled={list.isDisable}
                  sx={{
                    height: 37,
                    fontSize: '12px',
                    lineHeight: '18px',
                    color: isActive ? '#ffffff' : alpha('#ffffff', 0.7),

                    cursor: 'pointer',
                    pointerEvents: isActive ? 'none' : 'auto',
                    justifyContent: 'space-between',
                    backgroundColor: isActive ? '#07B9EC' : 'transparent',
                    display: 'block',
                    '&.Mui-disabled': {
                      opacity: 0.25,
                      pointerEvents: 'none',
                    },
                    '&:hover': {
                      background: isActive ? '#07B9EC' : alpha('#07B9EC', 0.5),
                      color: isActive ? '#ffffff' : '#61BAD7',
                    },
                  }}
                  {...(list?.children && bindHover(popupState))}
                  {...(list?.children && bindFocus(popupState))}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      height: '100%',
                      alignItems: 'center',
                      borderBottom: `1px solid ${alpha('#A8B1B8', list?.isDisable ? 1 : 0.15)}`,
                      marginLeft: open ? '15px' : '8px',
                      marginRight: open ? '0px' : '9px',
                      paddingRight: open ? '5px' : 0,
                      paddingLeft: open ? 0 : '7px',
                      // transition: 'padding 0.2s ease',
                      gap: '6px',
                    }}
                  >
                    <span
                      className={clsx('size-4', {
                        'm-auto': !open,
                      })}
                    >
                      {!!list?.icon && list.icon({ isActive })}
                    </span>
                    {/* {open && ( */}
                    <div
                      className={clsx(
                        'flex w-full items-center justify-between gap-[6px]',
                        {
                          'pointer-events-none -z-10 opacity-0': !open,
                        },
                      )}
                    >
                      <span>{list.label}</span>
                      {list?.badgeValue && (
                        <span
                          className={clsx(
                            'flex size-[18px] items-center justify-center rounded-full bg-artist-blue-900 font-semibold text-white opacity-100',
                            {
                              'bg-white !text-artist-blue-900': isActive,
                            },
                          )}
                        >
                          {list?.badgeValue}
                        </span>
                      )}
                      {!!list?.leftSideComponent && list.leftSideComponent({})}
                    </div>
                    {/* )} */}
                  </Box>
                </ListItem>
              );
            }}
          </NavLink>
          {!!list?.children?.length && (
            <CascadingMenu
              popupState={popupState}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              sx={{
                width: 'auto',
                '& .MuiList-padding': {
                  // marginY: '6px',
                  marginRight: !!(list?.children?.length > 8) ? '2px' : '0px',
                },
                '& .MuiPaper-root ': {
                  margin: '0 !important',
                },
                '.MuiList-root': {
                  minHeight: !open ? '111px' : '74px',
                },
                '.MuiList-root a': {
                  height: '37px !important',
                  alignItems: 'center',
                  display: 'flex',
                  '& li': {
                    height: '37px',
                  },
                },
                '.MuiList-root a:first-of-type': {
                  background: !open ? 'rgba(7, 185, 236, 0.5) !important' : '',
                  color: !open ? '#61BAD7 !important' : '',
                },
              }}
            >
              {!open
                ? !list.children.find(
                    (item: any) => item.label === list.label,
                  ) &&
                  list.children.unshift({
                    label: list.label,
                    link: list.link,
                    isDisable: false,
                    parent: true,
                  })
                : list.children.find(
                    (item: any) => item.label === list.label,
                  ) && list.children.shift()}
              {list.children.map((childrenList: any, index: any) => {
                return (
                  childrenList.link && (
                    <NavLink
                      to={`${list.link}/${childrenList.link}`}
                      style={{ all: 'unset', paddingRight: '0px !important' }}
                      key={`${index}-${childrenList.link}-sublist`}
                    >
                      {({ isActive }) => (
                        <CascadingMenuItem
                          onClick={() =>
                            onChildItemClick
                              ? onChildItemClick(childrenList)
                              : {}
                          }
                          sx={[
                            !!isActive && {
                              backgroundColor: '#07B9EC !important',
                              color: '#ffffff !important',
                              paddingY: '0 !important',
                              paddingRight: '0px !important',
                              minHeight: '29px !important',
                              maxHeight: '29px !important',
                              pointerEvents: 'auto',
                              // !!isActive && !childrenList?.children?.length
                              //   ? 'none'
                              //   : 'auto',
                            },
                          ]}
                          className="!pr-0"
                        >
                          {childrenList.children ? (
                            open && !childrenList.parent ? (
                              <CascadingSubmenu
                                popupId={`${index}-${childrenList.link}-sublist`}
                                title={childrenList.label}
                                sx={{
                                  '.MuiPaper-root': {
                                    top: '174px !important',
                                    marginLeft: '0px !important',
                                  },
                                  '.MuiList-root a': {
                                    height: '37px !important',
                                    alignItems: 'center',
                                    display: 'flex',
                                    '& li': {
                                      height: '37px',
                                    },
                                  },
                                  '.MuiList-root': {
                                    minHeight: '74px',
                                  },
                                }}
                              >
                                {childrenList?.children?.map(
                                  (subChildrenList: any, index: any) => {
                                    return (
                                      <NavLink
                                        to={`${list.link}/${childrenList.link}/${subChildrenList.link}`}
                                        style={{
                                          all: 'unset',
                                          paddingRight: '0px !important',
                                        }}
                                        key={`${index}-${childrenList.link}-${subChildrenList.link}`}
                                      >
                                        {/* {({ isActive }) => ( */}
                                        <CascadingMenuItem>
                                          {subChildrenList.label}
                                        </CascadingMenuItem>
                                        {/* )} */}
                                      </NavLink>
                                    );
                                  },
                                )}
                              </CascadingSubmenu>
                            ) : (
                              <CascadingSubmenu
                                popupId={`${index}-${childrenList.link}-sublist`}
                                title={childrenList.label}
                                sx={{
                                  '.MuiPaper-root': {
                                    top: '211px !important',
                                    left: '380px !important',
                                    marginLeft: '0px !important',
                                  },
                                  '.MuiList-root a': {
                                    height: '37px !important',
                                    alignItems: 'center',
                                    display: 'flex',
                                    '& li': {
                                      height: '37px',
                                    },
                                  },
                                }}
                              >
                                {childrenList?.children?.map(
                                  (subChildrenList: any, index: any) => {
                                    return (
                                      <NavLink
                                        to={`${list.link}/${childrenList.link}/${subChildrenList.link}`}
                                        style={{
                                          all: 'unset',
                                          paddingRight: '0px !important',
                                        }}
                                        key={`${index}-${childrenList.link}-${subChildrenList.link}`}
                                      >
                                        {/* {({ isActive }) => ( */}
                                        <CascadingMenuItem>
                                          {subChildrenList.label}
                                        </CascadingMenuItem>
                                        {/* )} */}
                                      </NavLink>
                                    );
                                  },
                                )}
                              </CascadingSubmenu>
                            )
                          ) : (
                            <span>{childrenList.label}</span>
                          )}
                        </CascadingMenuItem>
                      )}
                    </NavLink>
                  )
                );
              })}
            </CascadingMenu>
          )}
        </>
      ) : (
        <>
          <NavLink to={list.path as string} style={{ all: 'unset' }}>
            {({ isActive }) => (
              <NaVigatorTextItem
                {...(list?.children && bindHover(popupState))}
                {...(list?.children && bindFocus(popupState))}
                className={clsx({
                  '!text-artist-blue-900': popupState.isOpen,
                })}
                onClick={onClick}
                active={!popupState?.isOpen && isActive}
                sx={{
                  cursor: 'pointer',
                  pointerEvents:
                    isActive && !list?.children?.length ? 'none' : 'auto',
                }}
              >
                {list?.children?.length && mainPath.length >= 4
                  ? `${convertRouterToTitle(mainPath[2] as string)}-${convertRouterToTitle(mainPath[3] as string)}`
                  : convertRouterToTitle(list.path as string)}
              </NaVigatorTextItem>
            )}
          </NavLink>

          {!!list?.children?.length && (
            <CascadingMenu
              popupState={popupState}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              sx={{
                '& .MuiList-padding': {
                  marginY: '6px',
                  marginRight: !!(list?.children?.length > 8) ? '2px' : '0px',
                },
              }}
            >
              {list.children.map((childrenList: any, index: any) => {
                return (
                  childrenList.path && (
                    <NavLink
                      to={`${list.path}/${childrenList.path}`}
                      style={{ all: 'unset' }}
                      key={`${index}-${childrenList.path}-sublist`}
                    >
                      {({ isActive }) => (
                        <CascadingMenuItem
                          onClick={() =>
                            onChildItemClick
                              ? onChildItemClick(childrenList)
                              : {}
                          }
                          sx={[
                            !!isActive && {
                              backgroundColor: '#07B9EC !important',
                              color: '#ffffff !important',
                              paddingY: '0 !important',
                              minHeight: '29px !important',
                              maxHeight: '29px !important',
                              pointerEvents: 'auto',
                              // !!isActive && !childrenList?.children?.length
                              //   ? 'none'
                              //   : 'auto',
                            },
                          ]}
                        >
                          <span>
                            {convertRouterToTitle(childrenList.path as string)}
                          </span>
                          {!!isActive && (
                            <CosTooltip
                              title="Add To Favorites"
                              placement="top"
                              onHoverIcon={<FilledStar />}
                            >
                              <OutlineStar />
                            </CosTooltip>
                          )}
                        </CascadingMenuItem>
                      )}
                    </NavLink>
                  )
                );
              })}
              {/* // ! MANAGE SUB-CHILDREN WHENEVER REQUIRED */}
              {/* <CascadingSubmenu
                popupId="moreChoicesCascadingMenu"
                title="More Choices"
              >
                <CascadingMenuItem>Cheesecake</CascadingMenuItem>
                <CascadingMenuItem>Cheesedeath</CascadingMenuItem>
                <CascadingSubmenu
                  popupId="evenMoreChoicesCascadingMenu"
                  title="Even More Choices"
                >
                  <CascadingMenuItem>Cake (the band)</CascadingMenuItem>
                  <CascadingMenuItem>Death Metal</CascadingMenuItem>
                </CascadingSubmenu>
                <CascadingSubmenu
                  popupId="moreBenignChoices"
                  title="More Benign Choices"
                >
                  <CascadingMenuItem>Salad</CascadingMenuItem>
                  <CascadingMenuItem>Lobotomy</CascadingMenuItem>
                </CascadingSubmenu>
              </CascadingSubmenu>*/}
            </CascadingMenu>
          )}
        </>
      )}
    </>
  );
};

export default CascadingHoverMenus;
