import { useEffect, useRef, useState } from 'react';
import { TImeFieldWrapper } from './TImeFieldWrapper';
import ScheduleInput from './ScheduleInput';
import { moveTabToNextField, moveTabToPrevField } from '../../libs/helper';

interface changeArg {
  value: string;
  isValid: boolean;
}

interface Props {
  className?: string;
  onChange?: ({ value, isValid }: changeArg) => void;
}
const AddHoursField: React.FC<Props> = ({ className, onChange }) => {
  const [hours, setHours] = useState('');
  const [mins, setMins] = useState('');

  const ref = useRef<HTMLDivElement>(null);

  const checkValidation = (hr: string, min: string) => {
    return hr?.length === 2 && min?.length === 2;
  };

  const handleChange = (value: string, type: 'hr' | 'min') => {
    if (type === 'hr') {
      setHours(value);
      const isValid = checkValidation(value, mins);
      onChange?.({ value: `${value}:${mins}`, isValid });
    } else {
      setMins(value);
      const isValid = checkValidation(hours, value);
      onChange?.({ value: `${hours}:${value}`, isValid });
    }
  };

  return (
    <TImeFieldWrapper ref={ref} className={className}>
      <ScheduleInput
        value={hours}
        onChange={(hour: string) => handleChange(hour, 'hr')}
        onComplete={() => moveTabToNextField(ref.current as HTMLElement)}
        id="hours"
        max={23}
      />
      <span className="pl-[3px] pr-[5px] font-semibold">Hr(s)</span>
      <ScheduleInput
        value={mins}
        onChange={(mins: string) => handleChange(mins, 'min')}
        onEmpty={() => moveTabToPrevField(ref.current as HTMLElement)}
        id="min"
        max={59}
      />
      <span className="pl-[3px] font-semibold">Min(s)</span>
    </TImeFieldWrapper>
  );
};

export default AddHoursField;
