import React, { useEffect, useRef, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, ChipTypeMap, ClickAwayListener, Paper } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CosLabel from '../CosLabel';
import { ArrowDropDownIcon, ExpandArrow } from '../../../../assets/svgs';
import { cn } from '../../../../libs/utils';
import clsx from 'clsx';
import { CosAutoCompleteProps, ExtraProps } from './cosAutoComplete.interface';
import CosAutoCompleteInput from './CosAutoCompleteInput';
import useCosAutoComplete from './useCosAutoComplete';
import CosAutoCompleteDropdown from './CosAutoCompleteDropdown';
import { ListBoxProps } from './CosAutoCompleteItms';
import CosCheckbox from '../CosCheckbox';
import { KEYBOARD_KEY } from '../../../../libs/constant';
import { manageHighlightItem } from './util';

const zIndex = 1299; // mui theme.zIndex.drawer + 1,

const CosAutoComplete = <
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>({
  inputSx,
  isRequired,
  label,
  labelProps,
  tooltipTitle,
  className,
  textFieldProps,
  name = '',
  placeholder,
  onChange,
  sx,
  addNewComponent,
  arrowType = 'chevron',
  withElevation = true,
  fullWidth,
  multiple,
  options,
  isPopupInput = false,
  isThreeChar = false,
  isOptionEqualToValue,
  ...rest
}: CosAutoCompleteProps<
  Value,
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
> &
  ExtraProps) => {
  const {
    handleChange,
    handleFilter,
    isOpen,
    value,
    handleOpen,
    handleClose,
    handleToggleSelectAll,
    isSelectAll,
    handleApply,
    isInitialState,
    handleCancel,
    inputRef,
    handleMultiSelect,
    cloneValue,
    setIsOpen,
  } = useCosAutoComplete({
    onChange,
    options,
    name,
    multiple: !!multiple,
    defaultValue: rest?.value,
  });

  const listBoxRef: any = useRef();
  // const [mofidyOptions, setMofidyOptions] = useState(options);

  const onKeyDown = (event: KeyboardEvent) => {
    const key = event.key;
    if (isOpen) {
      switch (key) {
        case KEYBOARD_KEY.tab:
          if (isInitialState) {
            handleCancel();
            setIsOpen(false);
          } else {
            event.preventDefault();
          }
          break;
        case KEYBOARD_KEY.down:
        case KEYBOARD_KEY.up:
        case KEYBOARD_KEY.enter:
          manageHighlightItem(event, listBoxRef?.current, !!multiple);
          break;
        default:
          break;
      }
    }
  };

  // const onAnyClick = (event: any) => {
  //   // Check if inputRef and inputRef.current exist and contain the event target
  //   if (!inputRef?.current?.contains(event.target)) {
  //     const test = document.getElementsByClassName('MuiAutocomplete-popper')[0];

  //     // Log the test variable and check if it is defined
  //     console.log('test', test);
  //     console.log('current', event.target);

  //     if (test) {
  //       console.log('isContain', test.contains(event.target));

  //       // Proceed with the check only if test is defined
  //       if (!test.contains(event.target)) {
  //         // handleClose(event);
  //       }
  //     } else {
  //       console.log('MuiAutocomplete-popper element not found');
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (isOpen) {
  //     window.addEventListener('click', onAnyClick);
  //     window.addEventListener('keydown', onKeyDown);
  //   } else {
  //     window.removeEventListener('keydown', onKeyDown);
  //   }
  //   // Clean up event listeners
  //   return () => {
  //     window.removeEventListener('keydown', onKeyDown);
  //     window.removeEventListener('click', onAnyClick);
  //   };
  // }, [isInitialState, listBoxRef, isOpen]);

  useEffect(() => {
    isOpen
      ? window.addEventListener('keydown', onKeyDown)
      : window.removeEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [isInitialState, listBoxRef, isOpen]);

  // useEffect(() => {
  //   if (multiple) {
  //     let data = [];
  //     data = options.map((item, index) => ({
  //       ...item,
  //       key: index,
  //     }));
  //     setMofidyOptions(data);
  //   } else {
  //     setMofidyOptions(options);
  //   }
  // }, [options]);

  useEffect(() => {
    // set event-none to modal
    const modalContainer = document.getElementsByClassName(
      'MuiDialog-container',
    )[0];
    if (modalContainer) {
      isInitialState
        ? modalContainer.classList.remove('pointer-events-none')
        : modalContainer.classList.add('pointer-events-none');
    }
  }, [isInitialState]);

  return (
    <>
      {!isInitialState && (
        <Backdrop
          sx={{
            zIndex,
          }}
          open={!isInitialState}
          invisible={true}
          className="cos-auto-complete-backdrop bg-transparent"
          onMouseDown={event => event.preventDefault()}
        ></Backdrop>
      )}

      <Box
        className={clsx(
          'mb-4 inline-block',
          { 'w-full': fullWidth },
          className,
        )}
      >
        {label && (
          <CosLabel
            isRequired={isRequired}
            {...labelProps}
            className={cn('mb-[5px]', labelProps?.className)}
            tooltipTitle={tooltipTitle}
          >
            {label}
          </CosLabel>
        )}
        <Autocomplete
          open={isOpen}
          // options={mofidyOptions}
          options={options}
          onOpen={handleOpen}
          onClose={event => isInitialState && handleClose(event)}
          disableCloseOnSelect={!!multiple}
          multiple={multiple}
          openOnFocus
          openText={''}
          closeText={''}
          clearIcon={<span className="pointer-events-none hidden" />}
          loadingText={'Loading…'}
          onChange={handleChange}
          filterOptions={handleFilter}
          ListboxProps={ListBoxProps(!!addNewComponent, !!multiple)}
          PaperComponent={({ children, className, ...paperProps }) => {
            return (
              <Paper
                className={clsx(className, 'rounded-t-none')}
                {...paperProps}
              >
                {children}
              </Paper>
            );
          }}
          PopperComponent={({ children, className, sx, ...popperProps }) => (
            <div>
              <ClickAwayListener
                onClickAway={() => isInitialState && handleClose()}
              >
                <CosAutoCompleteDropdown
                  addNewComponent={addNewComponent}
                  className={className}
                  sx={sx}
                  {...popperProps}
                  multiple={!!multiple}
                  onClose={() => {
                    const inputEle = inputRef.current;
                    // inputEle?.blur();
                    handleClose();
                  }}
                  handleApply={handleApply}
                  isInitialState={isInitialState}
                  handleCancel={handleCancel}
                  onSelectAll={handleToggleSelectAll}
                  isSelectAll={isSelectAll}
                  ref={listBoxRef}
                >
                  {children}
                </CosAutoCompleteDropdown>
              </ClickAwayListener>
            </div>
          )}
          sx={{
            // zIndex: isInitialState ? 0 : zIndex,
            ...(withElevation
              ? {
                  borderRadius: `3px`,
                  boxShadow: '0 0 3px #A8B1B8',
                }
              : {}),
            position: 'relative',
            //* When open
            '&.Mui-expanded': {
              '& .MuiInputBase-root': {
                borderBottomLeftRadius: '0',
                borderBottomRightRadius: '0',
              },
            },
            '.COS-mask-placeHolder': {
              color: '#001f38',
              transition: 'opacity 0.3s ease',
            },
            '&:hover .COS-mask-placeHolder, &:focus .COS-mask-placeHolder': {
              opacity: 0.75,
            },
            ...sx,
          }}
          value={value}
          {...rest}
          isOptionEqualToValue={(original: any, value: any) =>
            original?.value === value?.value
          }
          renderInput={(params: any) => {
            return (
              <Box ref={params.InputProps.ref}>
                <CosAutoCompleteInput
                  type="text"
                  name={name}
                  {...params}
                  inputSx={inputSx}
                  {...textFieldProps}
                  sx={{
                    '& .MuiInputBase-root': {
                      flexWrap: 'nowrap',
                    },
                    '& .MuiAutocomplete-input': {
                      minWidth: multiple ? '1px !important' : '30px',
                      lineHeight: 2,
                      fontWeight: isPopupInput ? '600' : '400',
                    },
                  }}
                  inputRef={inputRef}
                />
                {placeholder &&
                  (Array.isArray(value) ? !value?.length : !value) &&
                  !params?.inputProps?.value && (
                    <p className="COS-mask-placeHolder pointer-events-none absolute left-[9px] top-1/2 m-0 -translate-y-1/2 text-14/18">
                      {placeholder}
                    </p>
                  )}
              </Box>
            );
          }}
          popupIcon={
            <>
              {arrowType === 'chevron' ? (
                <ArrowDropDownIcon />
              ) : (
                <span className="cos-expand w-[24px]" tabIndex={-1}>
                  <ExpandArrow
                    style={{ width: 12, height: 12, color: '#001F38' }}
                  />
                </span>
              )}
            </>
          }
          renderTags={value => {
            return (
              <p
                className={`COS-MultiValue m-0 truncate text-14/18 ${isPopupInput ? 'font-semibold' : 'font-normal'}`}
              >
                {value
                  ?.sort((a: any, b: any) => a?.sortId - b?.sortId)
                  ?.map((item: any) =>
                    isThreeChar ? item?.label.slice(0, 3) : item?.label,
                  )
                  .join(', ')}
              </p>
            );
          }}
          renderOption={(props, option: any, { selected }) => {
            const {
              onClick,
              onMouseMove,
              onTouchStart,
              role,
              key,
              ...rest
            }: any = props;
            const index = Array.isArray(cloneValue)
              ? cloneValue.findIndex((item: any) => item.value === option.value)
              : -1;
            const isSelected = index !== -1;
            return (
              <li
                onMouseEnter={(event: any) => {
                  event.target.classList.remove('Mui-focused');
                  event.target.classList.add('Mui-focused');
                }}
                onMouseLeave={(event: any) => {
                  event.target.classList.remove('Mui-focused');
                }}
                onClick={event =>
                  multiple ? handleMultiSelect(option) : onClick?.(event)
                }
                aria-selected={multiple ? isSelected : selected}
                data-option-index={rest['data-option-index']}
                className={cn(
                  { 'Mui-focused': (isSelected && multiple) || selected },
                  props.className,
                )}
                onMouseMove={onMouseMove}
                onTouchStart={onTouchStart}
                key={key}
              >
                {multiple && (
                  <CosCheckbox
                    style={{ marginRight: 5 }}
                    checked={isSelected}
                  />
                )}
                {option.label}
              </li>
            );
          }}
        />
      </Box>
    </>
  );
};

export default CosAutoComplete;
