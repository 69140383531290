import React, { useState } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import { CosAutoComplete } from './ui';
import { ChipTypeMap } from '@mui/material';

import clsx from 'clsx';
import { CosFormErrorAlert } from '..';
import { CosAlertProps } from '../alert/CosFormErrorAlert';
import {
  CosAutoCompleteProps,
  ExtraProps,
} from './ui/autoComplete/cosAutoComplete.interface';

export interface Props<
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> extends Omit<
    CosAutoCompleteProps<
      Value,
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent
    >,
    'onChange'
  > {
  name: string;
  hideDefaultError?: boolean;
  alertProps?: Omit<CosAlertProps, 'children'>;
  onChange?: (event: any, value: any) => void; // todo: fix type
}

const FormAutoComplete = <
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>({
  name,
  className,
  hideDefaultError,
  onBlur,
  onFocus,
  onChange,
  alertProps,
  ...rest
}: Props<Value, Multiple, DisableClearable, FreeSolo, ChipComponent> &
  ExtraProps) => {
  const { setFieldValue, handleBlur, setFieldTouched } = useFormikContext();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        return (
          <div>
            <CosAutoComplete
              className={clsx('!mb-0', className)}
              onChange={(event, option: any) => {
                // Array.isArray(option) ? setFieldValue(name, option.map()) :setFieldValue(name, option.value);
                setFieldValue(name, option);
                onChange && onChange(event, option);
              }}
              onFocus={event => {
                setIsFocused(true);
                onFocus && onFocus(event);
              }}
              onBlur={event => {
                // TODO : why drop-option render again when here i update the state
                // handleBlur(event);
                // setFieldTouched(name, true);
                // setIsFocused(false);
                onBlur && onBlur(event);
              }}
              name={name}
              // value={
              //   Object.keys(field?.value)?.length === 0 && !rest.multiple
              //     ? field.value?.value
              //     : field.value
              // }
              value={field.value}
              {...rest}
            />
            {!hideDefaultError &&
              meta.touched &&
              meta.error &&
              meta.error !== ' ' &&
              !isFocused && (
                <CosFormErrorAlert
                  severity="error"
                  className="mt-2"
                  icon={true}
                  {...alertProps}
                >
                  {meta.error}
                </CosFormErrorAlert>
              )}
          </div>
        );
      }}
    </Field>
  );
};

export default FormAutoComplete;
