import { Box, List, ListItem, alpha } from '@mui/material';
import { Collapsed } from '../../../../assets/svgs';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import BuyNowDropDown, { BuyNowProps } from './BuyNowDropDown';
import { localStorageKey } from '../../../../libs/constant';
import { cn, setLocalStorageItem } from '../../../../libs/utils';
import CascadingHoverMenus from './NavigatorDropDownItem';
interface Props extends BuyNowProps {
  sidebarList: any;
  toggleSideBar: () => void;
  open: boolean;
}
const openIconSx = {
  '& span': {
    transition: 'opacity 0.3s ease-out',
    '&:nth-of-type(1)': {
      opacity: 0.2,
    },
    '&:nth-of-type(2)': {
      opacity: 0.4,
    },
    '&:nth-of-type(3)': {
      opacity: 0.6,
    },
    '&:nth-of-type(4)': {
      opacity: 0.8,
    },
    '&:nth-of-type(5)': {
      opacity: 1,
    },
  },

  '&:hover': {
    '& span': {
      '&:nth-of-type(1)': {
        opacity: 1,
      },
      '&:nth-of-type(2)': {
        opacity: 0.8,
      },
      '&:nth-of-type(3)': {
        opacity: 0.6,
      },
      '&:nth-of-type(4)': {
        opacity: 0.4,
      },
      '&:nth-of-type(5)': {
        opacity: 0.2,
      },
    },
  },
};
const renderOption = (subNode: any, isLeftMenu: boolean, open: boolean) => {
  return subNode?.map((list: any, index: any) => (
    <CascadingHoverMenus
      list={list}
      key={`${index}-${list.link}`}
      isLeftMenu={isLeftMenu}
      open={open}
    />
  ));
};
const LeftPanel: React.FC<Props> = ({
  sidebarList,
  toggleSideBar,
  open,
  buyNowCheckList,
}) => {
  return (
    <>
      {/* Header */}
      <Box
        className="mb-[1px] flex h-9 min-h-9 cursor-pointer items-center justify-center gap-[7px] bg-artist-blue-900"
        sx={open ? openIconSx : {}}
        onClick={toggleSideBar}
      >
        {Array.from('12345').map((item, index) => {
          return (
            <Box
              component="span"
              sx={{
                display: 'inline-flex',
                opacity: !open && index !== 2 ? 0 : 1,
                transform:
                  !open && index === 2 ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
              key={`${item}-collapsed-icon`}
            >
              <Collapsed width={28} height={28} />
            </Box>
          );
        })}
      </Box>

      {/* Body */}
      <div className="relative flex-grow">
        {renderOption(sidebarList, true, open)}

        <BuyNowDropDown buyNowCheckList={buyNowCheckList} isShow={open} />
      </div>
    </>
  );
};

export default LeftPanel;
