import { CosAddNew, CosImport, CosSession } from '../components/dialogBox';
import StandardLayout from './StandardLayout';
import { useState } from 'react';
import { BuilderOps } from '../assets/svgs';
import CreateNewSchedule from '../modules/schedule/components/CreateNewSchedule';
import ViewSchedule from '../modules/schedule/components/ViewSchedule';
import { CosButton, FloatingAddButton } from '../components';

const StandardPopup = () => {
  const [importPopup, setImportPopup] = useState(false);
  const [sessionPopup, setSessionPopup] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [isCreate, setCreate] = useState(false);
  const [isView, setView] = useState(false);

  return (
    <StandardLayout>
      <div className="flex pb-3">
        <div className="px-4">
          <p>Import Popup</p>
          <CosButton
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => setImportPopup(true)}
          >
            Complete Import
          </CosButton>
          <CosImport
            open={importPopup}
            heading={'Confirm Import (%Count%)'}
            onClose={() => setImportPopup(false)}
          >
            <>
              <div className="flex">
                <p className="m-0">Confirm you are ready to import data from</p>
                <BuilderOps className="ml-[5px]" />
              </div>
              <p className="m-0">
                library to create your company’s %Name% database
              </p>
            </>
          </CosImport>
        </div>
        <div className="px-4">
          <p>Session Popup</p>
          <CosButton
            size="small"
            variant="outlined"
            onClick={() => setSessionPopup(true)}
          >
            Session
          </CosButton>
          <CosSession
            open={sessionPopup}
            onClose={() => setSessionPopup(false)}
          ></CosSession>
        </div>
      </div>
      <div className="flex pb-3">
        <div className="px-4">
          <p>Create New</p>
          <CosButton
            size="medium"
            variant="contained"
            onClick={() => setCreate(true)}
          >
            Create New
          </CosButton>
          {isCreate && (
            <CreateNewSchedule
              isOpen={isCreate}
              onClose={() => setCreate(false)}
            ></CreateNewSchedule>
          )}
        </div>
        <div className="px-4">
          <p>View</p>
          <CosButton
            size="medium"
            variant="outlined"
            onClick={() => setView(true)}
          >
            View
          </CosButton>
          {isView && (
            <ViewSchedule
              isOpen={isView}
              onClose={() => setView(false)}
            ></ViewSchedule>
          )}
        </div>
      </div>
      <div className="px-4">
        <p className="mb-0">Add New Popup</p>
        <span
          className="cursor-pointer"
          role="button"
          onClick={() => setAddNew(true)}
        >
          <FloatingAddButton />
        </span>
        <CosAddNew open={addNew} onClose={() => setAddNew(false)}></CosAddNew>
      </div>
    </StandardLayout>
  );
};

export default StandardPopup;
